import React, { useContext } from 'react';
import SelectedLayersContext from '../context/SelectedLayersContext'
import AvailableLayersContext from '../context/AvailableLayersContext'
import * as env from '../.env.js'
import * as lib from '../lib/lib.js'

export default function Legends(props){ 
  
  const { availableLayers } = useContext(AvailableLayersContext)
  const { selectedLayers } = useContext(SelectedLayersContext)

  function selectedLayerTitle(layer_name){
    let layers = availableLayers.layers
    let resp = 'Não encontrado'

    for (let i = 0; i < layers.length; i++)
      if (layers[i].nome_camada === layer_name)
        return layers[i].titulo_camada

    return resp
  }

  if (availableLayers.layers == null) // Às vezes o contexto não tinha tempo de ser carregado. Travava no método "selectedLayerTitle"
    return null
  else{

    let slegends = selectedLayers.map( (layer, i ) => {                                      
      return <tr key={i}>
               <td>
                 <table>
                   <tbody>
                     <tr>
                       <td>
                         <font style={{fontSize:10, fontWeight:'bold', color: lib.EMBRAPA_AZUL_COLOR}}> 
                           {selectedLayerTitle(layer)} 
                         </font>
                       </td>
                     </tr>
                     <tr>
                       <td>
                         <img src={`${env.GEOSERVER_WMS}?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/png&LAYER=geotab:${layer}&LEGEND_OPTIONS=dpi:65;`} />
                       </td>
                     </tr>                
                   </tbody>
                 </table>            
               </td>
             </tr>                    
     })
        
     return <table>
              <tbody>
                { slegends.reverse() /* Marcus: seleções mais recentes de camada devem aparecer primeiro! */  } 
              </tbody>
            </table>    

  }

}