import { createContext } from 'react'

export const defaultAvailableLayers = []
                                  
const AvailableLayersContext = createContext( 
  {
    availableLayers:defaultAvailableLayers, 
    setAvailableLayersContext: () => {}
  }
) 

export default AvailableLayersContext