import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figMenuAdm from './img/menuadm.png'
import figEstBase from './img/estat_base.png'
import figEstCamadasPorDatabase from './img/estat_camadas_por_database.png'
import figEstUsuariosPorPerfil from './img/estat_usuarios_por_perfil.png'
import figEstCamadasPorTipo from './img/estat_camadas_por_tipo.png'

const Page = () => {

    return <html>    
               <body>
                   <div style={estilos.tituloPagina}>Estatísticas</div>
               
                   <br/>
                   <p>
                       Para visualizar as estatísticas disponíveis no GeoTC é necessário ter um perfil de gestor.
                       O que está sendo referenciado como "estatísticas" são dados agrupados para criar gráficos com 
                       informações relevantes para os gestores do sistema. 
                       Considere o menu visto na Figura 1.
                   </p>
                   <br/>            

                   <figure>
                       <img style={estilos.imgStyle} src={figMenuAdm} />
                       <figcaption style={estilos.figcaption}>Figura 1 - Menu de Administração</figcaption>
                   </figure>                   
               
                   <br/>
                   <p>
                       Neste menu, clique na opção <i>Estatísticas</i>,
                       e o sistema exibirá uma tela com três abas, conforme visualizado na Figura 2. 
                   </p>
                   <br/>
               
                   <figure>                                              
                       <img style={estilos.imgStyle} src={figEstBase} />
                       <figcaption style={estilos.figcaption}>Figura 2 - Tela com opções</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       O gráfico disponível na aba Databases pode ser visualizada na Figura 3.
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figEstCamadasPorDatabase} />
                       <figcaption style={estilos.figcaption}>Figura 3 - Gráfico de Camadas por Database</figcaption>
                   </figure>    
               
                   <br/>
                   <p>
                       Um gráfico disponível na aba usuários pode ser visualizada na Figura 4.
                   </p>
                   <br/>    
               
                   <figure>
                       <img style={estilos.imgStyle} src={figEstUsuariosPorPerfil} />
                       <figcaption style={estilos.figcaption}>Figura 4 - Gráfico de Usuários por Perfil</figcaption>
                   </figure>        
               
                   <br/>
                   <p>
                       Um gráfico disponível na aba Camadas pode ser visualizada na Figura 5.
                   </p>
                   <br/>    
               
                   <figure>
                       <img style={estilos.imgStyle} src={figEstCamadasPorTipo} />
                       <figcaption style={estilos.figcaption}>Figura 5 - Gráfico de Camadas por Tipo</figcaption>
                   </figure>        

                   <br/>
                   <p>
                       Novas estatísticas serão adicionadas conforme demanda dos gestores.
                   </p>
                   <br/>
                   
               </body>
               
           </html>

}

const HEstatisticas = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HEstatisticas