import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figControles from './img/nav.png'
import figBtExpand from './img/bt_expand.png'
import figBtCollapse from './img/bt_colapse.png'
import figBtClose from './img/bt_fechar.png'
import figBtInfo from './img/bt_info.png'
import figExpandedTree from './img/expansao.png'
import figCollapsedTree from './img/calapsado.png'
import figMultipleSelection from './img/multipla_selecao.png'
import figWithoutSelection from './img/sem_selecao.png'
import figShapeInfo from './img/shape_info.png'

const Page = () => {

    return <html>            	
             <body>
                 <div style={estilos.tituloPagina}>Navegação</div>
                 
                 <br/>
                 <p>
                     Após realizar o login será possível navegar na árvore de shapes para acessar os shapes disponíveis 
                     de acordo com o perfil do usuário logado.
                 </p>
                 <br/>
         
                 <figure>
                     <img style={estilos.imgStyle} src={figControles} />
                     <figcaption style={estilos.figcaption}>Figura 1 - Controles disponíveis para o perfil Público</figcaption>
                 </figure>
         
                 <br/>
                 <p>
                     Os controles de navegação disponíveis publicamente são:
                     <br/>
                     <br/>
                     a) Expandir ( <img src={figBtExpand} /> ): serve para ordenar a expansão de todas 
                     as categorias de todos os databases, disponibilizando, visualmente, todos os shapes em 
                     uma estrutura do tipo árvore, que pode ser rolada verticalmente, conforme visto na Fitura 2;
         
                 </p>
                 <br/>        
         
                 <figure>
                     <img style={estilos.imgStyle} src={figExpandedTree} />                    
                     <figcaption style={estilos.figcaption}>Figura 2 - Árvore expandida </figcaption>
                 </figure>


                 <br/>
                 <p>        
                     b) Colapsar ( <img src={figBtCollapse} /> ): serve para ordenar o colapso de todos os nós 
                     da árvore. Ação antagônica à do botão Expandir, conforme visto na Figura 3;
                 </p>
                 <br/>        
         
         
                 <figure>
                     <img style={estilos.imgStyle} src={figCollapsedTree} />                                         
                     <figcaption style={estilos.figcaption}>Figura 3 - Árvore colapsada </figcaption>
                 </figure>
         
                          
                 <br/>
                 <p> 
                     c) Fechar ( <img src={figBtClose} /> ): serve para remover a seleção de todos os shapes. 
                     Observe a Figura 4.
                 </p>
                 <br/>        
         
         
                 <figure>
                     <img style={estilos.imgStyle} src={figMultipleSelection} />                                            
                     <figcaption style={estilos.figcaption}>Figura 4 - Seleção de múltiplos shapes </figcaption>
                 </figure>
         
         
                 <br/>
                 <p> 
                     Conforme imagem da Figura 4, o usuário pode selecionar vários shapes para serem exibidos simultaneamente. 
                     Independente da quantidade de shapes selecionados, quando o usuário clicar neste botão, o resultado será 
                     a desmarcação de todos os shapes previamente marcados, conforme visto na Figura 5.
                 </p>
                 <br/>           
                 
                 
                 <figure>
                     <img style={estilos.imgStyle} src={figWithoutSelection} />                     
                     <figcaption style={estilos.figcaption}>Figura 5 - Seleções removidas </figcaption>
                 </figure>
         
                         
                 <br/>
                 <p> 
                     d) Informação ( <img src={figBtInfo} /> ): Carrega informações relacionadas a um shape. 
                     Apenas um shape pode estar selecionado. Observe a Figura 6.
                 </p>
                 <br/>        
         
                 <figure>
                     <img style={estilos.imgStyle} src={figShapeInfo} />                                         
                     <figcaption style={estilos.figcaption}>Figura 6 - Informações acerca de um shape </figcaption>
                 </figure>        
         
         
                 <br/>
                 <p> 
                     A Figura 6 mostra o resultado de um clique no botão informação: 
                     exibição de metadados geridos pelo GeoTC e associados ao shape selecionado.
                 </p>
                 <br/>                        
         
             </body>
         </html>
}

const HNavegacao = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HNavegacao