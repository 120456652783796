import React, { useContext } from "react";
import Table from './../components/table'
import * as env from './../.env.js'
import {  CrudTable } from './../lib/lib'

export default function PerfilIndex(props) {
  
  const columns = [
    {
      name: 'Nome',
      selector: 'tx_nome',
      width: '200px',
      sortable: true,
    },
    {
      name: 'Descrição',
      selector: 'tx_descricao',
      width: '350px',
      sortable: true,
    }, 
  ];    

  return <CrudTable title='PERFIL'>
           <tbody>
             <tr align='center' height='40'>             
               <td colSpan='2'>
                 <Table 
                   rest={env.GEOTABREST_CRUD_PERFIL} 
                   table='perfil'
                   pk='id_perfil'
                   columns={columns}                    
                   controlInsert={true} 
                   controlEdit={true} 
                   controlDelete={true} 
                   controlDelete={true} 
                   controlDeleteExceptionValues={[1]}
                   controlDeleteExceptionMessages={['Impossível excluir o perfil de Administrador Geral!']}
                 />
               </td>
             </tr>                                                               
           </tbody>
         </CrudTable>
}