import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figBtAlter from './img/bt_alter.png'
import figMsgAlter1 from './img/msg_alter1.png'
import figAlterPasso1 from './img/alter_shape_passo1.png'
import figAlterPasso0 from './img/alter_shape_passo0.png'
import figAlterPasso2 from './img/alter_shape_passo2.png'
import figAlterPasso3 from './img/alter_shape_passo3.png'

const Page = () => {

    return <html>

               <body>
                   <div style={estilos.tituloPagina}>Alteração de <i>Shape</i></div>                   
                   
                   <br/>
                   <p>
                       A alteração de <i>shapes</i> estará disponível apenas para usuários autorizados.
                       Usuários com esta permissão poderão visualizar o ícone de alteração de <i>shapes</i> ( <img src={figBtAlter} />).
                       Se o usuário tentar clicar nesse ícone com mais de um <i>shape</i> selecionado, o sistema
                       exibirá a mensagem vista na Figura 1.
                   </p>
                   <br/>        
           
                   <figure>
                       <img style={estilos.imgStyle} src={figMsgAlter1} />                       
                       <figcaption style={estilos.figcaption}>Figura 1 - Tentando iniciar o processo de alteração com mais de um <i>shape</i> selecionado</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Considerando que apenas um <i>shape</i> esteja selecionado, ao clicar no botão 
                       ( <img src={figBtAlter} />) o usuário perceberá uma tela similar à que pode ser vista na Figura 2.
                   </p>
                   <br/>               
           
                   <figure>
                       <img style={estilos.imgStyle} src={figAlterPasso1} />
                       <figcaption style={estilos.figcaption}>Figura 2 - Iniciando a alteração do <i>shape</i></figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       O sistema já carrega, do <i>shape</i> selecionado, todas as informações passíveis de mudança.
                       Sobretudo, o sistema só ordenará a alteração dos campos que o usuário modificar!
                       <br/>
                       Nesta tela (vista na Figura 2), se o usuário apagar informações obrigatórias, enquanto ele não preencher novos
                       valores o sistema desabilitará o botão "Alterar Camada", conforme pode ser visualizado na Figura 3.    
                   </p>
                   <br/>           
           
                   <figure>
                       <img style={estilos.imgStyle} src={figAlterPasso0} />                                              
                       <figcaption style={estilos.figcaption}>Figura 3 - Botão "Alterar Camada" desabilitado</figcaption>
                   </figure>        
           
           
                   <br/>
                   <p>
                       Considerando que o usuário insira todos os valores requeridos e clique no botão "Alterar Camada",
                       o sistema exibirá uma mensagem semelhante à que pode ser vista na Figura 4.
                   </p>
                   <br/>                   
           
           
                   <figure>
                       <img style={estilos.imgStyle} src={figAlterPasso2} />                       
                       <figcaption style={estilos.figcaption}>Figura 4 - Mensagem de <i>shape</i> atualizado</figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Por fim, após a alteração, o usuário pode conferir o conteúdo alterado, conforme exemplificado por meio da Figura 5
                   </p>
                   <br/>               
           
           
                   <figure>
                       <img style={estilos.imgStyle} src={figAlterPasso3} />                                                                     
                       <figcaption style={estilos.figcaption}>Figura 4 - Visualizando a alteração do <i>shape</i></figcaption>
                   </figure>
           
               </body>
           </html>
}

const HAlteracaoShape = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HAlteracaoShape