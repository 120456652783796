import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { CrudCombo, CrudTable, CrudBtVerde, CrudLabel, CrudLabelRequired } from '../lib/lib'
import * as  lib from '../lib/lib' // Importando todas as constantes
import ModalContext, {ModalCodeIndex} from '../context/ModalContext'
import Message from './../messages'
import { FaQuestionCircle } from 'react-icons/fa'
import * as modalConstants from '../context/ModalContext'
import * as env from '../.env.js'
import '../lib/react-dual-listbox.css'


export default function CategoriaEdit(props) {
  
  const  { modal, setModal } = useContext(ModalContext)

  const { params } = modal

  const { table, rest, id, acao } = params

  const modalCodeIndex = ModalCodeIndex(table)
 
  // ---------------------------------------------------------------------------

  const [idBaseDeDados, setIdBaseDeDados] = useState(-1);  

  // ---------------------------------------------------------------------------
  
  const [idCategoriaCamadaPai, setIdCategoriaCamadaPai] = useState(-1)

  const [restComboCamadaPai, setRestComboCamadaPai] = useState(null)

  useEffect(() => {    
    setIdCategoriaCamadaPai(-1)
    setRestComboCamadaPai(env.GEOTABREST_CATEGORIA_CAMADA_POR_DATABASE + '/' + idBaseDeDados)
  }, 
  [idBaseDeDados]); 

  const [jsxComboCamadaPai, setJsxComboCamadaPai] = useState(null)

  useEffect(() => {        
    if (idBaseDeDados == -1)
      setJsxComboCamadaPai( <font style={lib.MESSAGE_STYLE} > Primeiro selecione a Base de Dados! </font> )
    else
      setJsxComboCamadaPai( <CrudCombo rest={restComboCamadaPai} pk='id_categoria_camada' desc='desc_camada' item={idCategoriaCamadaPai} setItem={setIdCategoriaCamadaPai} exclude={[id]} /> ) 
  }, 
  [restComboCamadaPai, idCategoriaCamadaPai]); 

  // ---------------------------------------------------------------------------

  const [txDescricao, setTxDescricao] = useState('');  

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela  
  
  // ---------------------------------------------------------------------------

  useEffect(() => {

    if (acao == 'editar') {

      axios.get(rest + '/' + id)
      .then(res => {          
        console.log(res);          
        setIdBaseDeDados(res.data.data.id_base_de_dados)
        setIdCategoriaCamadaPai(res.data.data.id_categoria_camada_pai)
        setTxDescricao(res.data.data.desc_camada) 
      })
      .catch(error => {
        let msg = error.request.response
        console.log(msg)
        alert( Message( msg ) )
      })                       

    }

  }, 
  []);  // Executa apenas uma vez

  // ---------------------------------------------------------------------------  

  const [idBaseDeDadosError, setIdBaseDeDadosError] = useState('');  

  useEffect(() => {

    if (idBaseDeDadosError != '' ) {
      alert('Erro no campo Base de Dados: ' + idBaseDeDadosError)
      setIdBaseDeDadosError('')
    }
    
  }, [idBaseDeDadosError]);  

  // ---------------------------------------------------------------------------

  const [idCategoriaCamadaPaiError, setIdCategoriaCamadaPaiError] = useState('');  

  useEffect(() => {

    if (idCategoriaCamadaPaiError != '' ) {
      alert('Erro no campo Categoria da Camada Pai: ' + idCategoriaCamadaPaiError)
      setIdBaseDeDadosError('')
    }
    
  }, [idCategoriaCamadaPaiError]);  

  // ---------------------------------------------------------------------------  

  const [txDescricaoError, setTxDescricaoError] = useState('');  

  useEffect(() => {

    if (txDescricaoError != '' ) {
      alert("Erro no campo Descricao: " + txDescricaoError)
      setTxDescricaoError('')
    }
    
  }, [txDescricaoError]);


  // ---------------------------------------------------------------------------

  async function btAcaoClick(event) {

    event.preventDefault();    
    
    let txIdPerfilErrorBeforeUpdate = lib.valid( idBaseDeDados, setIdBaseDeDadosError, 'selected' )
    let txIdCategoriaCamadaPaiErrorBeforeUpdate = lib.valid( idCategoriaCamadaPai, setIdCategoriaCamadaPaiError, 'selected' )
    let txDescricaoErrorBeforeUpdate = lib.valid( txDescricao, setTxDescricaoError, 'required' )     
        
    if (
      txIdPerfilErrorBeforeUpdate == ''
      &&
      txIdCategoriaCamadaPaiErrorBeforeUpdate == ''
      &&
      txDescricaoErrorBeforeUpdate == ''
      ){        // TUDO OK PARA AÇÃO

        let categoria = {    // Para edição ainda precisa acrescentar o id
          id_base_de_dados: idBaseDeDados,
          id_categoria_camada_pai: idCategoriaCamadaPai,
          tx_descricao: txDescricao          
        }        

        if (acao == 'novo') {

          axios.post(rest, categoria)
          .then(res => {          
             console.log(res);                       
             alert('Registro inserido!')             
             setModal ( { code: modalCodeIndex } )         
          })
          .catch(error => {
             let msg = error.request.response
             console.log(msg)
             alert( Message( msg ) )
          })                           

        }
        else { // ==> acao == 'editar'

          categoria.id_categoria_camada = id // Acrescentando apenas o id ao objeto já criado
              
          axios.put(rest, categoria)
          .then(res => {          
             console.log(res);                       
             alert('Registro alterado!')             
             setModal ( { code: modalCodeIndex } )         
          })
          .catch(error => {
            let msg = error.request.response
            console.log(msg)
            alert( Message( msg ) )
          })                       
         
        }
      }        
  }


  const debugTable = lib.debug( 
                                  {
                                     debug: false,
                                     acao: acao,
                                     idBaseDeDados: idBaseDeDados,
                                     idCategoriaCamadaPai: idCategoriaCamadaPai,
                                     txDescricao: txDescricao
                                   }
                                )  

  let inputsWidth = {width: '400px'}

  return    <CrudTable title={ acao == 'novo' ? 'INSERINDO CATEGORIA' : 'ALTERANDO CATEGORIA' }  showRequired='true'  modalBtClose={modalConstants.modalCodeCategoriaIndex}    actionOnSubmit={btAcaoClick}    debugTable={debugTable}>

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Base de Dados:'/>
                 </td>
                 <td align='left'>
                   <CrudCombo rest={env.GEOTABREST_CRUD_BASE_DE_DADOS} pk='id_base_de_dados' desc='tx_descricao' item={idBaseDeDados} setItem={setIdBaseDeDados} />
                 </td>
               </tr>

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Categoria Pai:'/>
                 </td>
                 <td align='left'>
                   { jsxComboCamadaPai }
                 </td>
               </tr>               

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Descrição:'/>
                 </td>
                 <td align='left'>
                   <input type='text' autoFocus style={inputsWidth}  value={txDescricao} onChange={ (e) => { setTxDescricao(e.target.value) } }   onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btAcaoClick) }  }   /> 
                 </td>
               </tr>
              
               <tr align='center' height='40'>
                 <td colSpan='2'>
                   <CrudBtVerde onClick={ (event) => {btAcaoClick(event)} } > { acao == 'novo' ? 'Inserir' : 'Alterar' } </CrudBtVerde>
                 </td>
               </tr>      

             </CrudTable>                                                                      
}