import React, { useContext, useState } from "react";
import axios from "axios";
import LoggedUserContext from './context/LoggedUserContext'
import AvailableKindsOfLayersContext from './context/AvailableKindsOfLayersContext'
import * as  lib from './lib/lib'
import { CrudTable, CrudBtVerde, CrudBtAzul, CrudBtVermelho, CrudLabel, CrudLabelRequired } from './lib/lib'
import ModalContext, * as modalConstants from './context/ModalContext'
import * as env from './.env.js'
import Message from './messages'

export default function Login(props) {
  
  const [recovery, setRecovery] = useState(false);  // Transforma essa tela na tela de Recovery
  const [user, setUser] = useState('');  
  const [password, setPassword] = useState('');  
  const { setLoggedUser } = useContext(LoggedUserContext)
  const { setAvailableKindsOfLayers } = useContext(AvailableKindsOfLayersContext)
  const { setModal } = useContext(ModalContext) // Capturando do contexto
  //---------------------------------------------------------------------------------------
  const [idUserToRecover, setIdUserToRecover] = useState(-1);  
  const [passwordRecovery, setPasswordRecovery] = useState('');  
  const [passwordRecoveryConfirm, setPasswordRecoveryConfirm] = useState('');    
  //---------------------------------------------------------------------------------------
  
  function handleSubmitLogin(event) {

    event.preventDefault();

    let id
    let bl_recuperar_senha

    const credenciais = {
      tx_nome:user,
      tx_password:password
    }          

    axios.get(env.GEOTABREST_LOGIN_REC + '/' + user)
    .then(res => {            

      if (!res.data.data){
        alert('Usuário ou senha não conferem!')
      }
      else{

        bl_recuperar_senha = res.data.data.bl_recuperar_senha
        id = res.data.data.id_usuario
  
        if (bl_recuperar_senha) {
          setIdUserToRecover(id)
          setRecovery(true)
        }          
        else { 
  
          // ------------------------------- Trâmite normal de login - INÍCIO -------------------------------
  
          axios.post(env.GEOTABREST_LOGIN, credenciais)
          .then(res => {
      
            const { 
                    login, 
                    id_usuario, 
                    tx_nome, 
                    tx_email, 
                    bl_recuperar_senha,
                    id_perfil, 
                    desc_perfil,
                    dbs_perm_vpv,
                    dbs_perm_vis,
                    dbs_perm_bai,
                    dbs_perm_inc,
                    dbs_perm_alt,
                    dbs_perm_exc,
                    dbs_perm_est
                   } = res.data
      
            if (login == false){
              alert('Usuário ou senha não conferem')            
            }        
            else {
              let url = env.GEOTABREST_CATEGORIASCAMADAS + '/' + id_perfil
      
              axios.get(url)
              .then(res => {            
      
                let newLoggedUser = {
                  id_usuario:   id_usuario,
                  tx_nome:      tx_nome, 
                  tx_email:     tx_email, 
                  bl_recuperar_senha: bl_recuperar_senha,
                  id_perfil:    id_perfil,
                  desc_perfil:  desc_perfil,
                  dbs_perm_vpv: dbs_perm_vpv,
                  dbs_perm_vis: dbs_perm_vis,
                  dbs_perm_bai: dbs_perm_bai,
                  dbs_perm_inc: dbs_perm_inc,
                  dbs_perm_alt: dbs_perm_alt,
                  dbs_perm_exc: dbs_perm_exc,
                  dbs_perm_est: dbs_perm_est
                }
      
                setLoggedUser(newLoggedUser)            
      
                let categorias = res.data.data            

                // IMPORTANTE: ÚNICO PONTO DE SETAGEM DE availableKindOfLayers (usado em todos os TreeViews que não exibem mapas
                // isto é, que exibem apenas databases e categorias)
                setAvailableKindsOfLayers(categorias)            
      
                setModal( { code: modalConstants.modalCodeNone } )
              })    
              .catch(function (error) {
                console.log(error);
                alert('Usuário ou senha não conferem')  
              })                                 
              
            }
          })    
          .catch(function (error) {
            console.log(error);
          })           
  
          // ------------------------------- Trâmite normal de login - TÉRMINO -------------------------------
  
        }
  

      }


    })    
    .catch(function (error) {
      console.log(error);
    })   
    
    // -----------------------------------------------------------------------------

  
  }


  function handleSubmitRecovery(event) {

    if (passwordRecovery == '')
      alert('Senha requerida!')
    else if (passwordRecoveryConfirm == '')  
      alert('Confirmação de Senha requerida!')
    else if ( passwordRecovery != passwordRecoveryConfirm )
      alert('Senha diferente da Confirmação de Senha!')
    else{      
      // sudo curl -X PUT -H "Content-Type: application/json"  -d '{"id_usuario":"73", "tx_password": "nova"}' desenv.cpatc.embrapa.br:8888/usuarioRecovery
      // GEOTABREST_USUARIO_RECOVERY =  GEOTABREST_SERVICE + '/usuarioRecovery'  

      let rest = env.GEOTABREST_USUARIO_RECOVERY

      let usuario = {    // Para edição ainda precisa acrescentar o id
        id_usuario: idUserToRecover,
        tx_password: passwordRecovery
      }              

      axios.put(rest, usuario)
      .then(res => {          
         console.log(res);          

         alert('Senha recuperada!')         
         setRecovery(false)
      })
      .catch(error => {
        let msg = error.request.response
        console.log(msg)
        alert( Message( msg ) )
      })                

    }      
  }

  let width = {width: '250px'}

  const debugTable = lib.debug( 
    {
       debug: false,
       idUserToRecover: idUserToRecover,
       passwordRecovery: passwordRecovery,
       passwordRecoveryConfirm: passwordRecoveryConfirm
     }
  )    
  
  return    <center>            
            {
              recovery ?

              <CrudTable title='RECUPERAÇÃO - INFORME UMA NOVA SENHA' showRequired='true' hideBtClose='true' debugTable={debugTable} >

                <tr>
                 <td align='right'>
                   <CrudLabel label='Usuário:'/>
                 </td>
                 <td align='left'>
                   <p style={{fontSize:'10px', fontWeight:'bold', color:'#385b9b'}} > {user} </p>                   
                 </td>
               </tr>

                <tr>
                  <td align='right'>
                    <CrudLabelRequired label='Senha:'/>
                  </td>
                  <td align='left'>
                    <input type='password' autoFocus style={width}  value={passwordRecovery} onChange={ (e) => { setPasswordRecovery(e.target.value) } }   onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, handleSubmitRecovery)} }   />
                  </td>
                </tr>
      
                <tr>
                  <td align='right'>
                    <CrudLabelRequired label='Confirmação da Senha:'/>
                  </td>
                  <td align='left'>
                    <input type='password' style={width}  value={passwordRecoveryConfirm} onChange={ (e) => { setPasswordRecoveryConfirm(e.target.value) } }   onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, handleSubmitRecovery)} }   />
                  </td>
                </tr>         

                <tr height='40'>
                  <td colSpan='2'>
                    <table width='100%'>
                      <tbody>
                        <tr>
                          <td colspan='2' width='100%' align='center' >
                            <CrudBtVermelho onClick={ (e) => handleSubmitRecovery(e) } > Recuperar </CrudBtVermelho>                            
                          </td>                     
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
  
              </CrudTable>       
              :

              <CrudTable title='BEM-VINDO ao G e o T C' showRequired='true' hideBtClose='true' >

                <tr>
                  <td align='right'>
                    <CrudLabelRequired label='Usuário:'/>
                  </td>
                  <td align='left'>
                    <input type='text' autoFocus style={width}  value={user} onChange={ (e) => { setUser(e.target.value) } }   onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, handleSubmitLogin)} }   />
                  </td>
                </tr>
      
                <tr>
                  <td align='right'>
                    <CrudLabelRequired label='Senha:'/>
                  </td>
                  <td align='left'>
                    <input type='password' style={width}  value={password} onChange={ (e) => {setPassword(e.target.value)}}    onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, handleSubmitLogin)} }   />
                  </td>
                </tr>                
  
                <tr height='40'>
                  <td colSpan='2'>
                    <table width='100%'>
                      <tbody>
                        <tr>
                          <td width='33%' align='center' >
                            <CrudBtVerde title='Clique aqui para entrar no G e o T C!' onClick={ (event) => {handleSubmitLogin(event)} }> Entrar </CrudBtVerde>
                          </td>                     
                          <td width='33%' align='center' >
                            <CrudBtAzul title='Clique aqui para se registrar no G e o T C!' onClick={ () => {setModal( { code: modalConstants.modalCodeUsuarioRegister } )} }> Registrar </CrudBtAzul>
                          </td>             
                           <td width='33%' align='center' >
                            <CrudBtVermelho title='Clique aqui se esqueceu sua senha!' onClick={ () => {setModal( { code: modalConstants.modalCodeUsuarioRecovery } )} }> Recuperar </CrudBtVermelho>
                          </td>                                    
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>                       

              </CrudTable>       
            }     
            </center>
           
}