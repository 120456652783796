import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figTelaBemVindo from './img/tela_bemvindo.png'
import figTelaRegistro from './img/tela_registro.png'
import figRegistroEfetuado from './img/msg_registro_efetuado.png'
import figLoginEfetuado from './img/login_efetuado.png'

const Page = () => {

    return <html>
             <head>
               <link rel='stylesheet' type='text/css' href='./css.css' />
             </head>    

             <body>
                 <div style={estilos.tituloPagina}>
                   Bem-Vindo ao GeoTC
                   <br/>
                   <br/>
                   <font style={{color:'#385b9b'}}>
                     Organização e disponibilização da informação geoespacial <br/> sobre recursos naturais e aspectos socioeconômicos <br/> da área de atuação da Embrapa Tabuleiros Costeiros
                   </font>
                 </div>
                                                 
                 <p style={{ textAlign:'justify' }}>
                     O Portal GeoTC congrega em uma única plataforma de visualização geoespacial toda a informação georreferenciada sobre recursos naturais, 
                     envolvendo solos, relevo, clima, geoambientes, recursos hídricos e fitofisionomias, bem como dados socioeconômicos relacionados às 
                     principais cadeias produtivas agrícolas que ocorrem na área de atuação da Embrapa Tabuleiros Costeiros. Esta região, envolve 570 
                     municípios situados em uma faixa de 100 km de largura média a partir do litoral nordestino para ao interior, estendendo-se do sul da 
                     Bahia à divisa dos estados do Ceará e Piauí, totalizando cerca de 220 mil km2.  Este conhecimento organizado, na forma de informações 
                     com referência espacial, permitem uma visão da condição atual e pregressa da agricultura e da sua inter-relação com os recursos naturais 
                     na região de interesse, sendo ferramenta indispensável para o eficiente planejamento de ações de PD&I de uma unidade ecorregional, bem 
                     como para o desenvolvimento de políticas públicas. Espera­-se que a conjugação das bases de dados e os produtos que possam advir de seu 
                     uso contribuam de forma efetiva para a definição de diretrizes para a identificação de sinais de mudanças relevantes na região e o 
                     fornecimento de informações que apoiem a tomada de decisão, dos setores público e privado, aumentando a sua capacidade de responder às 
                     oportunidades e aos riscos que se apresentem ao setor agrícola.                                                       
                 </p>
                                
               </body>
            </html>       
}

const HBoasVindas = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HBoasVindas