import React, { useState, useEffect } from 'react';
import SelectedAncestorNodeContext, {defaultSelectedAncestorNode} from './SelectedAncestorNodeContext';

export const selectedAncestorNodeKeyForLocalStorage = '@geotab/selectedAncestorNode'

export const useSelectedAncestorNodeWithLocalStorage = localStorageKey => {
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [selectedAncestorNode, setSelectedAncestorNode] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultSelectedAncestorNode )

  useEffect( () => // Salva na localStorage apenas quando selectedAncestorNode for alterado!
              {localStorage.setItem(localStorageKey, JSON.stringify( selectedAncestorNode ) )}, 
              [selectedAncestorNode]
           )

  return [selectedAncestorNode, setSelectedAncestorNode]
}

const SelectedAncestorNodeProvider = ({ children }) => {  
  const [selectedAncestorNode, setSelectedAncestorNode] = useSelectedAncestorNodeWithLocalStorage(selectedAncestorNodeKeyForLocalStorage);

  return (
    <SelectedAncestorNodeContext.Provider value={{ selectedAncestorNode, setSelectedAncestorNode }}>
      {children}
    </SelectedAncestorNodeContext.Provider>
  )
}

export default SelectedAncestorNodeProvider;