import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { CrudCombo, CrudSection, CrudTable, CrudBtVerde, CrudLabel, CrudLabelRequired } from '../lib/lib'
import * as  lib from '../lib/lib' // Importando todas as constantes
import ModalContext, {ModalCodeIndex} from '../context/ModalContext'
import Message from './../messages'
import DualListBox from 'react-dual-listbox';
import * as modalConstants from '../context/ModalContext'
import * as env from '../.env.js'
import '../lib/react-dual-listbox.css'


export default function UsuarioEdit(props) {
  
  const  { modal, setModal } = useContext(ModalContext)

  const { params } = modal

  const { table, rest, id, acao } = params

  const modalCodeIndex = ModalCodeIndex(table)
 
  // ---------------------------------------------------------------------------

  const [idPerfil, setIdPerfil] = useState(-1);  

  // ---------------------------------------------------------------------------

  const [txNome, setTxNome] = useState('');  

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela  
  
  // ---------------------------------------------------------------------------

  const [txPassword, setTxPassword] = useState(''); // Não carregar da base  

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela  
  
  // ---------------------------------------------------------------------------

  const [txConfirmPassword, setTxConfirmPassword] = useState('');

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela  
  
  // ---------------------------------------------------------------------------

  const [txEmail, setTxEmail] = useState('');   

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela  

  // ---------------------------------------------------------------------------


  useEffect(() => {

    if (acao == 'editar') {

      axios.get(rest + '/' + id)
      .then(res => {          
        console.log(res);          
        setIdPerfil(res.data.data.id_perfil)
        setTxNome(res.data.data.tx_nome)
        // setTxPassword('NÃO EXIBIR PARA O USUÁRIO')
        setTxEmail(res.data.data.tx_email)        
      })
      .catch(error => {
        let msg = error.request.response
        console.log(msg)
        alert( Message( msg ) )
      })                       

    }

  }, 
  []);  // Executa apenas uma vez

  // ---------------------------------------------------------------------------  

  const [idPerfilError, setIdPerfilError] = useState('');  

  useEffect(() => {

    if (idPerfilError != '' ) {
      alert("Erro no campo Perfil: " + idPerfilError)
      setIdPerfilError('')
    }
    
  }, [idPerfilError]);  

  // ---------------------------------------------------------------------------

  const [txNomeError, setTxNomeError] = useState('');  

  useEffect(() => {

    if (txNomeError != '' ) {
      alert("Erro no campo Nome: " + txNomeError)
      setTxNomeError('')
    }
    
  }, [txNomeError]);

  // ---------------------------------------------------------------------------

  const [txPasswordError, setTxPasswordError] = useState('');   

  useEffect(() => {

    if (txPasswordError != '' ) {
      alert("Erro no campo Password: " + txPasswordError)
      setTxPasswordError('')
    }
          
  }, [txPasswordError]);

  // ---------------------------------------------------------------------------

  const [txConfirmPasswordError, setTxConfirmPasswordError] = useState('');   

  useEffect(() => {

    if (txConfirmPasswordError != '' ) {
      alert("Erro no campo Confirmação de Senha: " + txConfirmPasswordError)
      setTxConfirmPasswordError('')
    }
          
  }, [txConfirmPasswordError]);

  // ---------------------------------------------------------------------------  

  const [txEmailError, setTxEmailError] = useState('');   

  useEffect(() => {

    if (txEmailError != '' ) {
      alert("Erro no campo E-mail: " + txEmailError)
      setTxEmailError('')
    }
          
  }, [txEmailError]);

  // ---------------------------------------------------------------------------





  // ---------------------------------------------------------------------------

  let url_options_graduacoes = env.GEOTABREST_CRUD_GRADUACAO // Sempre carregar todos em OPTIONS: o que for carregado em SELECTED é que fará OPTIONS apresentar menos elementos!!!
  // if (acao != 'novo')
  //   url_options_graduacoes = env.GEOTABREST_AREAS_DE_ATUACAO_NAO_SELECIONADAS + '/' + id // acao == editar

  const [optionsGraduacoes, setOptionsGraduacoes] = useState([]);    
  
  useEffect(() => {    
    axios.get(url_options_graduacoes)
    .then(res => {
      const jsonGraduacoes = res.data.data
  
      let graduacoes = []
  
      jsonGraduacoes.forEach(graduacaoDaVez => {
        let graduacao = {
                          value: graduacaoDaVez.id_graduacao,
                          label: graduacaoDaVez.tx_descricao
                        }
        graduacoes.push(graduacao)
      });
  
      setOptionsGraduacoes(graduacoes)    
      
    })    
    .catch(function (error) {
      let msg = error.request.response
      console.log(msg)
      alert( Message( msg ) )
    })          
    
  }, []); // Carrega optionsGraduacoes apenas uma vez

  // ---------------------------------------------------------------------------

  const [selectedGraduacoes, setSelectedGraduacoes] = useState([]);

  useEffect(() => {            

    if (acao != 'novo') {  // Carregar apenas quando acao == editar

      let url_selected_graduacoes = env.GEOTABREST_CRUD_AREAS_DE_ATUACAO + '/' + id

      axios.get(url_selected_graduacoes)
      .then(res => {
        const jsonAreasDeAtuacao = res.data.data
    
        let areasDeAtuacao = []
    
        jsonAreasDeAtuacao.forEach(areaDeAtuacao => {
          areasDeAtuacao.push(areaDeAtuacao.id_graduacao) // Para selected, neste componente, basta o value (key)
        });
    
        setSelectedGraduacoes( areasDeAtuacao  )
        
      })    
      .catch(function (error) {
        let msg = error.message
        console.log(msg)
        alert( Message( msg ) )
      })                

    }
              
  }, []); // Carrega selectedAreasDeInteresse apenas uma vez    

  // ---------------------------------------------------------------------------

  let url_options_cnpq = env.GEOTABREST_CRUD_CNPQ_GRANDE_AREA // Sempre carregar todos em OPTIONS: o que for carregado em SELECTED é que fará OPTIONS apresentar menos elementos!!!
  // if (acao != 'novo')
  //   url_options_cnpq = env.GEOTABREST_AREAS_DE_INTERESSE_NAO_SELECIONADAS + '/' + id // acao == editar    

  const [optionsAreasDeInteresse, setOptionsAreasDeInteresse] = useState([]);    
  
  useEffect(() => {    
    axios.get(url_options_cnpq)
    .then(res => {
      const jsonAreasDeInteresse = res.data.data
  
      let areasDeInteresse = []
  
      jsonAreasDeInteresse.forEach(areaDeInteresseDaVez => {
        let areaDeInteresse = {
                          value: areaDeInteresseDaVez.id_cnpq_grande_area,
                          label: areaDeInteresseDaVez.tx_descricao
                        }
        areasDeInteresse.push(areaDeInteresse)
      });
  
      setOptionsAreasDeInteresse(areasDeInteresse)    
      
    })    
    .catch(function (error) {
      let msg = error.request.response
      console.log(msg)
      alert( Message( msg ) )
    })          
    
  }, []); // Carrega optionsAreasDeInteresse apenas uma vez  

  // ---------------------------------------------------------------------------

  const [selectedAreasDeInteresse, setSelectedAreasDeInteresse] = useState([]);

  useEffect(() => {            

    if (acao != 'novo') {  // Carregar apenas quando acao == editar

      let url_selected_cnpq = env.GEOTABREST_CRUD_AREAS_DE_INTERESSE + '/' + id

      axios.get(url_selected_cnpq)
      .then(res => {
        const jsonAreasDeInteresse = res.data.data
    
        let areasDeInteresse = []
    
        jsonAreasDeInteresse.forEach(areaDeInteresseDaVez => {
          areasDeInteresse.push(areaDeInteresseDaVez.id_cnpq_grande_area) // Para selected, neste componente, basta o value (key)
        });
    
        setSelectedAreasDeInteresse( areasDeInteresse  )
        
      })    
      .catch(function (error) {
        let msg = error.message
        console.log(msg)
        alert( Message( msg ) )
      })                

    }
              
  }, []); // Carrega selectedAreasDeInteresse apenas uma vez  

  // ---------------------------------------------------------------------------


  function saveGraduacoes(id_usuario){

    let url_delete = env.GEOTABREST_CRUD_AREAS_DE_ATUACAO + '/' + id_usuario 

    axios.delete(url_delete) // APAGANDO GRADUAÇÕES EXISTENTES
    .then(res => {
      console.log(res.message);


      if (selectedGraduacoes.length >= 1){ // INSERIR GRADUAÇÕES SELECIONADAS
      
        let url_options_graduacoes = env.GEOTABREST_CRUD_AREAS_DE_ATUACAO
    
        let graduacao
        let tupla
        for (let pos = 0; pos < selectedGraduacoes.length; pos++){
          graduacao = selectedGraduacoes[pos]
          tupla = { id_usuario:id_usuario, id_graduacao:graduacao }
    
          axios.post(url_options_graduacoes, tupla) // Cada graduação precisa de um novo INSERT 
          .then(res => {
            console.log(res);     
            setSelectedGraduacoes([])                 
          })
          .catch(error => {
            let msg = error.request.response
            console.log(msg)
            alert( Message( msg ) )
          })                       
        }    
      }      

      
    })
    .catch(error => {
      let msg = error.request.response
      console.log(msg)
      alert( Message( msg ) )
    })       

  }

  function saveAreasDeInteresse(id_usuario){


    let url_delete = env.GEOTABREST_CRUD_AREAS_DE_INTERESSE + '/' + id_usuario 

    axios.delete(url_delete) // APAGANDO ÁREAS DE INTERESSE EXISTENTES
    .then(res => {
      console.log(res.message);

      if (selectedAreasDeInteresse.length >= 1){ // INSERIR ÁREAS DE INTERESSE
            
        let url_areas_de_interesse = env.GEOTABREST_CRUD_AREAS_DE_INTERESSE
  
        let area_de_interesse
        let tupla
        for (let pos = 0; pos < selectedAreasDeInteresse.length; pos++){
          area_de_interesse = selectedAreasDeInteresse[pos]
          tupla = { id_usuario:id_usuario, id_cnpq_grande_area:area_de_interesse }
  
          axios.post(url_areas_de_interesse, tupla) // Cada graduação precisa de um novo INSERT 
          .then(res => {
            console.log(res);                      
            setSelectedAreasDeInteresse([])
          })
          .catch(error => {
            let msg = error.request.response
            console.log(msg)
            alert( Message( msg ) )
          })                       
        }
      }      
      
    })
    .catch(error => {
      let msg = error.request.response
      console.log(msg)
      alert( Message( msg ) )
    })       

  }




  async function btAcaoClick(event) {

    event.preventDefault();    
    
    let txConfirmPasswordErrorBeforeUpdate = ''
    let txIdPerfilErrorBeforeUpdate = lib.valid( idPerfil, setIdPerfilError, 'selected' )     
    let txNomeErrorBeforeUpdate = lib.valid( txNome, setTxNomeError, 'required' )     
    let txPasswordErrorBeforeUpdate = acao == 'novo' ? lib.valid( txPassword, setTxPasswordError, 'required' ) : '' 

    if (  (acao == 'novo')  ||  (txPassword.trim().length > 0)  )  // (txPassword.trim().length > 0 ==> Verificar, caso, quando acao=editar, o usuário preencha a senha
      if (txPassword !== txConfirmPassword) {
        txConfirmPasswordErrorBeforeUpdate = 'Confirmação de Senha difere da Senha!'
        setTxConfirmPasswordError(txConfirmPasswordErrorBeforeUpdate)
      }
      else
        txConfirmPasswordErrorBeforeUpdate = ''    


    let txEmailErrorBeforeUpdate = lib.valid( txEmail, setTxEmailError, 'validEmailRequired' ) 
        
    if (
      txIdPerfilErrorBeforeUpdate == ''
      &&
      txNomeErrorBeforeUpdate == ''
      &&
      txPasswordErrorBeforeUpdate == ''
      &&
      txConfirmPasswordErrorBeforeUpdate == ''
      &&
      txEmailErrorBeforeUpdate == ''      
      ){        // TUDO OK PARA AÇÃO

        let usuario = {    // Para edição ainda precisa acrescentar o id
          id_perfil: idPerfil,
          tx_nome: txNome,
          tx_password: txPassword,
          tx_email: txEmail
        }        

        if (acao == 'novo') {

          axios.post(rest, usuario)
          .then(res => {          
             console.log(res);          

             let id_usuario_from_insert = res.data.id_usuario_from_insert

             saveGraduacoes(id_usuario_from_insert)
             saveAreasDeInteresse(id_usuario_from_insert)
             
             alert('Registro inserido!')
             
             setModal ( { code: modalCodeIndex } )         
          })
          .catch(error => {
             let msg = error.request.response
             console.log(msg)
             alert( Message( msg ) )
          })                           

        }
        else { // ==> acao == 'editar'

          usuario.id_usuario = id // Acrescentando apenas o id ao objeto já criado
              
          axios.put(rest, usuario)
          .then(res => {          
             console.log(res);          
             
             saveGraduacoes(id)
             saveAreasDeInteresse(id)

             alert('Registro alterado!')
             
             setModal ( { code: modalCodeIndex } )         
          })
          .catch(error => {
            let msg = error.request.response
            console.log(msg)
            alert( Message( msg ) )
          })                       
         
        }
      }        
  }


  const debugTable = lib.debug( 
                                  {
                                     debug: true,
                                     acao: acao,
                                     idPerfil: idPerfil,
                                     txNome: txNome,
                                     txNomeError: txNomeError,
                                     txPassword: txPassword,
                                     txPasswordError: txPasswordError,      
                                     txConfirmPassword: txConfirmPassword,
                                     txConfirmPasswordError: txConfirmPasswordError,                                           
                                     txEmail: txEmail,
                                     txEmailError: txEmailError,
                                   }
                                )  

  let inputsWidth = {width: '400px'}

  return    <CrudTable title={ acao == 'novo' ? 'INSERINDO USUÁRIO' : 'ALTERANDO USUÁRIO' }  showRequired='true'  modalBtClose={modalConstants.modalCodeUsuarioIndex}    actionOnSubmit={btAcaoClick}    debugTable={null}>

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Perfil:'/>
                 </td>
                 <td align='left'>
                   <CrudCombo rest={env.GEOTABREST_CRUD_PERFIL} pk='id_perfil' desc='tx_descricao' item={idPerfil} setItem={setIdPerfil} />
                 </td>
               </tr>


               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Nome:'/>
                 </td>
                 <td align='left'>
                   <input type='text' autoFocus style={inputsWidth}  value={txNome} onChange={ (e) => { setTxNome(e.target.value) } }   onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btAcaoClick) }  }   /> 
                 </td>
               </tr>

               {/* { 
                 acao == 'novo' ? 
                 <tr>
                   <td align='right'>
                     <CrudLabelRequired label='Senha:'/>
                   </td>
                   <td align='left'>
                     <input type='password' name='password' style={inputsWidth}  value={txPassword} onChange={ (e) => {setTxPassword(e.target.value)} }   onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btAcaoClick) }  }  />   
                   </td>
                 </tr>                                    
                 : 
                 null
               } 

               { 
                 acao == 'novo' ? 
                 <tr>
                   <td align='right'>
                     <CrudLabelRequired label='Confirmação de Senha:'/>
                   </td>
                   <td align='left'>
                     <input type='password' style={inputsWidth}   value={txConfirmPassword} onChange={ (e) => {setTxConfirmPassword(e.target.value)} }       onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btAcaoClick) }  }  /> 
                   </td>
                 </tr>                              
                 : 
                 null
               }                 */}


 

                 <tr>
                   <td align='right'>
                   { 
                     acao == 'novo' ? 
                     <CrudLabelRequired label='Senha:'/>
                     :
                     <CrudLabel label='Senha:'/>
                   }
                   </td>
                   <td align='left'>
                     <input type='password' name='password' style={inputsWidth}  value={txPassword} onChange={ (e) => {setTxPassword(e.target.value)} }   onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btAcaoClick) }  }  />   
                   </td>
                 </tr>                                    

                 <tr>
                   <td align='right'>
                   { 
                     acao == 'novo' ?                      
                     <CrudLabelRequired label='Confirmação de Senha:'/>
                     :
                     <CrudLabel label='Confirmação de Senha:'/>
                   }
                   </td>
                   <td align='left'>
                     <input type='password' style={inputsWidth}   value={txConfirmPassword} onChange={ (e) => {setTxConfirmPassword(e.target.value)} }       onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btAcaoClick) }  }  /> 
                   </td>
                 </tr>                              



               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='E-Mail:'/>
                 </td>
                 <td align='left'>
                   <input type='text' style={inputsWidth}  value={txEmail} onChange={ (e) => {setTxEmail(e.target.value)} }   onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btAcaoClick) }  }  />   
                 </td>
               </tr>                            

               <tr>
                 <td colSpan='2' style={lib.CRUD_LABEL_AZUL} align='center' >
                   <CrudSection left='Disponível' main='Áreas de Atuação' right='Selecionado'>
                     <DualListBox   
                           alignActions="top"              
                           lang={lib.DualListBoxLanguage}
                           icons={lib.DualListBoxIconsLeftRight}
                           options={optionsGraduacoes} 
                           selected={selectedGraduacoes}
                           onChange={ (selectedValues) => { setSelectedGraduacoes(selectedValues) } }                         
                        />                 
                   </CrudSection>
                 </td>
               </tr>     

               <tr>
                 <td colSpan='2' style={lib.CRUD_LABEL_AZUL} align='center' >
                   <CrudSection left='Disponível' main='Áreas de Interesse' right='Selecionado'>
                     <DualListBox      
                           alignActions="top"              
                           lang={lib.DualListBoxLanguage}
                           icons={lib.DualListBoxIconsLeftRight}
                           options={optionsAreasDeInteresse} 
                           selected={selectedAreasDeInteresse}                                     
                           onChange={ (selectedValues) => { setSelectedAreasDeInteresse(selectedValues) } }                         
                        />                                   
                   </CrudSection>                   
                 </td>
               </tr>                                                
              
               <tr align='center' height='40'>
                 <td colSpan='2'>
                   <CrudBtVerde onClick={ (event) => {btAcaoClick(event)} } > { acao == 'novo' ? 'Inserir' : 'Alterar' } </CrudBtVerde>
                 </td>
               </tr>      

             </CrudTable>                                                                      

}