import React, { useState, useContext, useEffect } from "react";
import AvailableLayersContext from './AvailableLayersContext';
import LoggedUserContext from './LoggedUserContext';
import SelectedLayersContext, {defaultSelectedLayersContext} from "./SelectedLayersContext";

export const selectedLayersKeyForLocalStorage = '@geotab/selectedLayers'

export const useSelectedLayersWithLocalStorage = localStorageKey => {

  const { availableLayers } = useContext(AvailableLayersContext)

  const { loggedUser } = useContext(LoggedUserContext)


  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [selectedLayers, setSelectedLayers] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultSelectedLayersContext )

  useEffect( () => // Salva na localStorage apenas quando selectedLayers for alterado!
              {localStorage.setItem(localStorageKey, JSON.stringify( selectedLayers ) )}, 
              [loggedUser, availableLayers, selectedLayers]
           )

  return [selectedLayers, setSelectedLayers]
}

const SelectedLayersProvider = ({ children }) => {  
  const [selectedLayers, setSelectedLayers] = useSelectedLayersWithLocalStorage(selectedLayersKeyForLocalStorage);

  return (
    <SelectedLayersContext.Provider value={{ selectedLayers, setSelectedLayers }}>
      {children}
    </SelectedLayersContext.Provider>
  )
}

export default SelectedLayersProvider;