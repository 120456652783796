import React, { useState, useEffect } from 'react';
import FlagUpdateTreeContext, {defaultFlagUpdateTree} from './FlagUpdateTreeContext';

export const flagUpdateTreeKeyForLocalStorage = '@geotc/flagUpdateTree'

export const useFlagUpdateTreeWithLocalStorage = localStorageKey => {
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [flagUpdateTree, setFlagUpdateTree] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultFlagUpdateTree )

  useEffect( () => // Salva na localStorage apenas quando flagUpdateTree for alterado!
              {localStorage.setItem(localStorageKey, JSON.stringify( flagUpdateTree ) )}, 
              [flagUpdateTree]
           )

  return [flagUpdateTree, setFlagUpdateTree]
}

const FlagUpdateTreeProvider = ({ children }) => {  
  const [flagUpdateTree, setFlagUpdateTree] = useFlagUpdateTreeWithLocalStorage(flagUpdateTreeKeyForLocalStorage);

  return (
    <FlagUpdateTreeContext.Provider value={{ flagUpdateTree, setFlagUpdateTree }}>
      {children}
    </FlagUpdateTreeContext.Provider>
  )
}

export default FlagUpdateTreeProvider;