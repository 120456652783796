import { createContext } from 'react'

export const defaultSelectedLayersContext = ["GeoTab_Solos_05_12_19_IBGE", "MunicipiosCPATC"]
                                  
const SelectedLayersContext = createContext( 
  {
    selectedLayers:defaultSelectedLayersContext, 
    setSelectedLayersContext: () => {}
  }
                                                 ) 

export default SelectedLayersContext