import React, {useState, useEffect, useContext } from 'react'
import styled from 'styled-components';
import axios from 'axios'
import { FaInfoCircle, FaPlus, FaMinus } from 'react-icons/fa'
import { CrudTable, Required, CrudBtVerde } from './lib/lib'
import * as lib from './lib/lib';
import * as env from './.env.js'
import LoggedUserContext from './context/LoggedUserContext'
import SelectedIdsContext from './context/SelectedIdsContext'
import TreeViewContext from './context/TreeViewContext'
import TreeViewCategory from './components/treeViewCategory'
import ModalContext, { modalCodeNone } from './context/ModalContext'

const getColor = (props) => {
  if (props.isDragAccept) {
      return lib.EMBRAPA_VERDE_COLOR;
  }
  if (props.isDragReject) {
      return lib.EMBRAPA_VERDE_COLOR;
  }
  if (props.isDragActive) {
      return lib.DARK_RED_COLOR;
  }
  return '#eee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 100px;
  padding: 20px;
  border-width: 5px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #ffffff;
  color: #bdbdbd;
  outline: none;f
  transition: border .24s ease-in-out;
`;


function EditMap(props) {  

  function ancestors(id) {

    function node_with_id(id){
      let node = null

      for (let i = 0; i < treeView.length; i++) 
        if (treeView[i].id === id) {
          node = treeView[i]
          break
        }              

      return node
    }

    let answer = []
    let node = node_with_id(id) // Primeiro node, não deve entrar na resposta: apenas os ancestrais
    if (node != null)
      while (node.id_pai != null){      
        node = node_with_id(node.id_pai)
        answer.push(node.id)    
      }
    
    return answer
  }   

  const { loggedUser } = useContext(LoggedUserContext) // Capturando do contexto

  const { treeView } = useContext(TreeViewContext)

  const [layerCategory, setLayerCategory] = useState(-1); // Não selecionado  const [keywordErrors, setKeywordErrors] = useState('');   

  const [idBase, setIdBase] = useState(-1); // Setado em função de layerCategoria
  
  const { selectedIds } = useContext(SelectedIdsContext)

  const [expanded, setExpanded] = useState([])  

  const [loadedLayer, setLoadedLayer] = useState(false); // Não selecionado  

  const { setModal } = useContext(ModalContext)   

  async function loadLayer(){

    let id = selectedIds[0].substr(1, selectedIds[0].length - 1) // Remover o 'm' da frente do id
    let url = env.GEOTABREST_GET_LAYER + '/' +  id

    await axios.get(url)
    .then(res => {

      setTitle(res.data.titulo_camada)

      setLayerType( String(res.data.id_tipo_camada) )

      let keywords_on_database =  res.data.palavras_chave
      let separator = '@||@'
      let keywords = keywords_on_database.split(separator)
      separator = ', '
      keywords = keywords_on_database.trim().split(separator)
      setKeywords( keywords )

      setLayerCategory( res.data.id_categoria_camada )

      let anc = ancestors( `c${res.data.id_categoria_camada}` ) // Tentativa; não funciona

      setExpanded( anc )

      setLoadedLayer(true)
    })
    .catch(error => {
      console.log('url: ' + url + ' |||||  Erro: ' + error.response)
      setLoadedLayer(false)
    })    

  }

  useEffect(() => {

    loadLayer()

  }, []); // Executa apenas na inicialização do componente  
   

  const [error, setError] = useState([]);
 
  const [title, setTitle] = useState('');   

  const [keyword, setKeyword] = useState('');   
  
  const [keywords, setKeywords] = useState([]);   
  
  const [layerType, setLayerType] = useState(''); 

  const debugTable = lib.debug( 
    {
       debug: false,
       idBase: idBase,
       keyword: keyword,
       keywords: keywords,
       layerType: layerType,
       selectedIds_0_Context: selectedIds[0],
       title: title,
       layerCategory: layerCategory,
     }
  )         
  
  function VerticalSpaceForTable(colSpan, height){
    return  <tr><td colSpan={colSpan} height={height}></td></tr>
  }


  function addKeyWord(){
    if (keyword === '')
      alert('Insira uma palavra-chave!')
    else
    {
      setKeywords( [...keywords, keyword ] )
      setKeyword('')      
    }
  }


  function handleKeyWordEnter(event) {
    if (event.charCode === 13)
      addKeyWord()
  }


  function DrawBasicInfo(){
    
    return <table style={lib.DASHED_TABLE}>

             <tbody>
               <tr>
                 <td colSpan='3' style={lib.CRUD_TITLE_MENOR}>
                   <center>Passo 1: Informações básicas</center>
                 </td>               
               </tr>

               {VerticalSpaceForTable(3,15)}
  
               <tr>

                 <td>
                   <div>
                     <label style={lib.CRUD_TEXT}>
                       <b> <Required/> Tipo:</b>
                     </label>
                   </div>  
                 </td>


                 <td>
                   <div>
                     <label style={lib.CRUD_TEXT}>
                       <input type="radio" value="1" 
                          checked={layerType === "1"} 
                          onChange={ (e) => setLayerType(e.currentTarget.value) } />
                       Pública
                     </label>
                   </div>  
                 </td>

                 <td>
                   <div>
                     <label style={lib.CRUD_TEXT}>
                       <input type="radio" value="2" 
                          checked={layerType === "2"} 
                          onChange={ (e) => setLayerType(e.currentTarget.value) } />
                       Privada
                     </label>
                   </div>                 
                 </td>

               </tr>


               <tr>

                 <td colSpan='3'>

                   <table >
                    <tbody>         
                      <tr height='20'>
                        <td>
                          <b><Required/> Título:</b>
                        </td>
                        <td>
                          <input style={{...lib.CRUD_TEXT, width: '150px'}} type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                 </td>

               </tr>


               <tr>

                 <td colSpan='3'>

                   <table>
                     <tbody>                                            
          
                       <tr height='20'>

                         <td>
                           <b><Required/> Palavra-chave:</b>
                         </td>

                         <td>        
                           <table>
                             <tbody>
                               <tr>
                                 <td style={{verticalAlign:'center'}} > 
                                   <input style={{...lib.CRUD_TEXT, width: '70px'}} type="text" value={keyword}  onKeyPress={ e => handleKeyWordEnter(e) }  onKeyUp={e => lib.validKeyUpAlert(e, keyword, setKeyword, 'onlyLetters')} onChange={e => setKeyword(e.target.value)} />
                                 </td>
                                 <td style={{verticalAlign:'center'}} > 
                                   <FaPlus onClick={ () => { addKeyWord() } } color={lib.EMBRAPA_VERDE_COLOR} />
                                 </td>
                                 <td style={{verticalAlign:'center'}} > 
                                   {KeywordsGrid()}
                                 </td>                               
                               </tr>
                             </tbody>
                           </table>                                      
                         </td>

                       </tr>

                     </tbody>
                   </table>

                 </td>

               </tr>

             </tbody>

           </table>
  }  


  function KeywordsGrid(){

    if (keywords.length === 0)
      return null      

    const kws = keywords.map((keyword_par, i) =>        
         <tr key={i}>  
           <td style={{fontSize:10, color: lib.EMBRAPA_AZUL_COLOR, verticalAlign:'center'}}> 
             {keyword_par} 
           </td> 
           <td style={{verticalAlign:'center'}} > 
             <FaMinus  onClick={ () => { setKeywords( keywords.filter( kw => kw !== keyword_par ) ); setKeyword('') } }  color={lib.VERMELHO_ERRO_COLOR} /> 
           </td> 
         </tr>        
    )

    return <table style={lib.MAP_TABLE_DASHED} >
             <tbody>
               {kws}
             </tbody>
           </table>
  }

  function DrawLayerCategory(){
    return <table style={lib.DASHED_TABLE}>
             <tbody>
               <tr>
                 <td style={lib.CRUD_TITLE_MENOR}>
                   <center> Passo 2: Categoria da Camada </center>
                 </td>               
               </tr>

               {VerticalSpaceForTable(3,15)}
  
               <tr>
                 <td>
                   <table>
                     <tbody>
                       <tr>
                        <td width='20'>
                          <Required/>                      
                        </td>
                        <td>
                          <TreeViewCategory expanded={expanded} layerCategory={layerCategory} setLayerCategory={setLayerCategory}  setIdBase={setIdBase} />
                        </td>
                       </tr>
                     </tbody>
                   </table>
                 </td>
               </tr>
             </tbody>
           </table>
  }    

  const ESPACO = 7

  function invalidScreen(){
    return  (
            layerType === '') 
            ||
            (title === '') 
            ||
            (keywords.length === 0)  
            ||
            (layerCategory === -1)  
  }



  async function handleSubmit() {       

    async function UpdateLayerOnGeoTC(){

      let id_camada = selectedIds[0]
      id_camada = id_camada.substring(1,id_camada.length)

      let palavras_chave = ''
      for (let pos = 0; pos < keywords.length; pos ++)
        palavras_chave += keywords[pos] + '@||@' 

      const url = env.GEOTABREST_UPDATE_LAYER_ON_GEOTC        

      palavras_chave = palavras_chave.substr(0, palavras_chave.length - 4) // Removendo separador do final
      
      const layer = {
        id_camada: id_camada,
        id_tipo_camada: layerType,
        tx_titulo: title,
        tx_pc: palavras_chave,
        id_categoria_camada: layerCategory
      }

      await axios.post(url, layer)
        .then(res => {
          console.log(res);
          console.log(res.data);                      
          alert('Camada atualizada no GeoTC');                  
        })
        .catch(error => {
          console.log('url: ' + url + ' |||||  Erro: ' + error)
        })                    

    }    

    UpdateLayerOnGeoTC()    
  }
  

  if (!loadedLayer)
    return null
  else
    return <CrudTable title='ALTERAR CAMADA' showRequired='true'  debugTable={debugTable}>
             <tbody>
               <tr>
             
                 <td>
                   <table style={{height:'100%',width:'100%'}} >
                    <tbody>
                     <tr>
                       <td align='center'>
                         {DrawBasicInfo()}
                       </td>
                     </tr>
                     </tbody>
                   </table>
                 </td>
             
             
                 <td>
                   <table style={{height:'100%',width:'100%'}} >              
                     <tbody>
                       <tr>
                         <td align='center'>
                           {DrawLayerCategory()}
                         </td>
                       </tr>
                     </tbody>
                   </table>
                 </td>
             
               </tr>
               
               <tr><td colSpan='3' height={ESPACO}/></tr>          
             
             
               <tr>
                 <td colSpan='3' align='center'>
             
                   <CrudBtVerde                   
                     type="submit"
                     disabled={invalidScreen()} 
                     onClick={ () => handleSubmit() } > 
                         Alterar Camada {invalidScreen()? '(Conclua os passos acima)':''}
                   </CrudBtVerde>
                             
                 </td>                                 
               </tr>
             </tbody>
           </CrudTable>          
}

export default EditMap