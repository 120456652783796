import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { CrudTable, CrudBtVermelho, CrudLabelRequired } from './../lib/lib'
import * as  lib from './../lib/lib' // Importando todas as constantes
import ModalContext, * as modalConstants from './../context/ModalContext'
import * as env from './../.env.js'


export default function UsuarioRecovery(props) {
  
  const { setModal } = useContext(ModalContext) // Capturando do contexto

  // ---------------------------------------------------------------------------

  const [txNome, setTxNome] = useState(''); 

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela 

  // ---------------------------------------------------------------------------

  const [txEmail, setTxEmail] = useState(''); 

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela 

  // ---------------------------------------------------------------------------

  function handleRecovery(event) {
    let ask = {
      tx_nome: txNome,
      tx_email: txEmail
    }
    
    axios.put(env.GEOTABREST_USUARIO_ASK_RECOVERY, ask)
    .then(res => {          
       console.log(res);          

       if (!res.data.data) 
         alert('Dados errados! Se não souber esses dados, envie um e-mail para o suporte do sistema: alex.oliveira@embrapa.br')
       else {
        alert('Usuário em modo de recuperação! Retorne para a tela de boas vindas, digite o nome do usuário e clique no botão "Entrar"!')
        setModal( { code: modalConstants.modalCodeLogin } )
       }

    })
    .catch(error => {
      let msg = error.request.response
      console.log(msg)
      // alert( Message( msg ) )
    })           
  }


  const debugTable = lib.debug( 
    {
       debug: false,
       txNome: txNome,
       txEmail: txEmail
     }
  )   


  return    <CrudTable title='RECUPERAÇÃO DE SENHA' showRequired='true' debugTable={debugTable} >
 
               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Nome de usuário usado no cadastro:'/>
                 </td>
                 <td align='left'>
                   <input type='text'  autoFocus  style={{width:'300px'}}  value={txNome} onChange={ (e) => {setTxNome(e.target.value)} }   onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, handleRecovery)} }   />
                 </td>
               </tr>    

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='E-mail usado no cadastro:'/>
                 </td>
                 <td align='left'>
                   <input type='text' style={{width:'300px'}}  value={txEmail} onChange={ (e) => {setTxEmail(e.target.value)} }   onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, handleRecovery)} }   />
                 </td>
               </tr>    

               <tr align='center' height='40'>
                 <td colSpan='2'>
                   <CrudBtVermelho title='Caso não lembre desses dados, envie um e-mail para o suporte: alex.oliveira@embrapa.br'  onClick={ (event) => {handleRecovery(event)} } > Recuperar </CrudBtVermelho>
                 </td>
               </tr>      

             </CrudTable>                                                                      

}