import React, { useState, useEffect } from "react";
import HeaderContext, {defaultHeader} from "./HeaderContext";

export const headerKeyForLocalStorage = '@geotab/header'

export const useHeaderWithLocalStorage = localStorageKey => {
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [header, setHeader] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultHeader )

  useEffect( () => // Salva na localStorage apenas quando header for alterado!
              {localStorage.setItem(localStorageKey, JSON.stringify( header ) )}, 
              [header]
           )

  return [header, setHeader]
}

const HeaderProvider = ({ children }) => {  
  const [header, setHeader] = useHeaderWithLocalStorage(headerKeyForLocalStorage);

  return (
    <HeaderContext.Provider value={{ header, setHeader }}>
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderProvider;