import React, { useState, useContext, useEffect } from "react";
import LoggedUserContext, {defaultLoggedUser} from "./LoggedUserContext";
export const loggedUserKeyForLocalStorage = '@geotab/loggedUser'

export const useLoggedUserWithLocalStorage = localStorageKey => {
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [loggedUser, setloggedUser] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultLoggedUser )

  useEffect( () => // Salva na localStorage apenas quando loggedUser for alterado!
              {
                localStorage.setItem(localStorageKey, JSON.stringify( loggedUser ) )
              }, 

              [loggedUser]
           )

  return [loggedUser, setloggedUser]
}

const LoggedUserProvider = ({ children }) => {  
  const [loggedUser, setLoggedUser] = useLoggedUserWithLocalStorage(loggedUserKeyForLocalStorage);

  return (
    <LoggedUserContext.Provider value={{ loggedUser, setLoggedUser }}>
      {children}
    </LoggedUserContext.Provider>
  )
}

export default LoggedUserProvider;