import React, { useRef, useContext, useState, useEffect } from "react";
import axios from "axios";
import SelectedIdsContext from './context/SelectedIdsContext'
import { CrudTable } from './lib/lib'
import { FaDatabase } from 'react-icons/fa'
import * as lib from './lib/lib'
import * as modalConstants from './context/ModalContext'
import * as env from './.env.js'
import LoggedUserContext from "./context/LoggedUserContext";

export default function MapDetail(props) {

  const { loggedUser } = useContext(LoggedUserContext) 

  const { selectedIds } = useContext(SelectedIdsContext)

  const [data, setData] = useState([])

  const [loadingData, setLoadingData] = useState(true)
  
  useEffect(() => {    // Carregando dados da camada
    let id = selectedIds[0].substr(1, selectedIds[0].length - 1) // Remover o 'm' da frente do id

    axios.get(env.GEOTABREST_GET_LAYER_FULL + '/' + id )
    .then(res => {        
      setData(res.data.data)          
      setLoadingData(false)
    })    
    .catch(function (error) {
      console.log(error);
    })      
    
  }, []); 

                                 
  const detailTitle = {fontSize:'9px', fontWeight: 'bold', color:lib.EMBRAPA_AZUL_COLOR}
  const detailValue = {fontSize:'9px', color:lib.EMBRAPA_VERDE_COLOR, paddingLeft: 5}
  const obs = {fontSize:'9px', color:lib.VERMELHO_ERRO_COLOR, paddingLeft: 5}

  if (loadingData)                                
    return <p> Carregando... </p>
  else
    return  <CrudTable title='DETALHES DA CAMADA'   width='100%'   modalBtClose={modalConstants.modalCodeNone}>

              <tr><td height='10px' /></tr>

              <tr>
                <td align='center'>
                  <table style={lib.DASHED_TABLE} width='100%' border='0' cellspacing='0' cellpadding='0' >
                    <tr>                    
                      <td colSpan='2' align='center'>
                        <p style={detailTitle} > CAMADA: </p>
                      </td>
                    </tr>                                    
                    <tr><td height='10px'/></tr>
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Database: </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.desc_base_de_dados} </p>
                      </td>
                    </tr>                    
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Categoria: </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.desc_categoria_camada} </p>
                      </td>
                    </tr>                    
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Tipo: </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.desc_tipo_camada} </p>
                      </td>
                    </tr>                                        
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Nome: </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.nome_camada} </p>
                      </td>
                    </tr>
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Título: </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.titulo_camada} </p>
                      </td>
                    </tr>
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Código EPSG: </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.id_epsg} </p>
                      </td>
                    </tr>                    
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Descrição EPSG: </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.desc_epsg} </p>
                      </td>
                    </tr>              
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Centro (Latitude): </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.dp_center_lat} </p>
                      </td>
                    </tr>                                           
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Centro (Longitude): </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.dp_center_lon} </p>
                      </td>
                    </tr>                     
                    <tr>
                      <td align='right'>
                        <p style={detailTitle} > Data e Hora do Cadastro: </p>
                      </td>                      
                      <td align='left'>
                        <p style={detailValue} > {data.dt_datahora_cadastro} </p>
                      </td>
                    </tr>                                                                                                    
                  </table>                        
                </td>                             
              </tr>            
              
              { loggedUser.id_usuario !== 1 ? null : <tr><td height='10px'/> </tr> }

              {                
                loggedUser.id_usuario !== 1 ?
                null
                :                
                <tr>                
                  <td align='center'>                  
                    <table style={lib.DASHED_TABLE} width='100%' border='0' cellspacing='0' cellpadding='0' >
                      <tr>                    
                        <td colSpan='2' align='center'>
                          <p style={detailTitle} > RESPONSÁVEL: </p>
                        </td>
                      </tr>           
                      <tr>                    
                        <td colSpan='2' align='center'>
                          <p style={obs} > (Visível apenas para o Administrador Geral) </p>
                        </td>
                      </tr>                                                          
                      <tr><td height='10px'/></tr>
                      <tr>
                        <td align='right'>
                          <p style={detailTitle} > Nome : </p>
                        </td>                      
                        <td align='left'>
                          <p style={detailValue} > {data.nome_usuario} </p>
                        </td>
                      </tr>
                      <tr>
                        <td align='right'>
                          <p style={detailTitle} > E-mail : </p>
                        </td>                      
                        <td align='left'>
                          <p style={detailValue} > {data.tx_email} </p>
                        </td>
                      </tr>                    
                      <tr>
                        <td align='right'>
                          <p style={detailTitle} > Perfil : </p>
                        </td>                      
                        <td align='left'>
                          <p style={detailValue} > {data.nome_perfil} - {data.desc_perfil} </p>
                        </td>
                      </tr>                                        
                    </table>                        
                  </td>                  
                </tr>                        
              }
            
             <tr> <td height='10px'/> </tr>

           </CrudTable>
}