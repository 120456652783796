import React, { useContext } from "react";
import { CrudTable } from './lib/lib'
import { FaUser, FaDatabase, FaChartPie, FaMap } from 'react-icons/fa'
import { BsBarChartFill } from 'react-icons/bs'
import { RiBarChartHorizontalFill } from 'react-icons/ri'
import * as lib from './lib/lib'
import * as modalConstants from './context/ModalContext'
import ModalContext from './context/ModalContext'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export default function Stats(props) {  

  const { setModal } = useContext(ModalContext)

  const debugTable = lib.debug( 
    {
       debug: false,
     }
  )  

  const labelStatScreenStyle = {fontSize:'9px', color:lib.EMBRAPA_VERDE_COLOR, paddingLeft: 5, cursor:'pointer'}

    return <CrudTable border='1' title='ESTATÍSTICAS' width='100%' modalBtClose={modalConstants.modalCodeNone} debugTable={debugTable} >  

             <tr style={{height:'10px'}}></tr>
         
             <tr width='100%'>
               <td align='center'>
         
                 <Tabs defaultIndex={0} onSelect={index => console.log(index)}>
         
                   <TabList border='1'>
                     <Tab> <FaDatabase size='20' color={ lib.EMBRAPA_AZUL_COLOR }  title='Estatísticas acerca dos databases' />  Databases  </Tab>
                     <Tab> <FaUser     size='20' color={lib.EMBRAPA_VERDE_COLOR}   title='Estatísticas acerca dos usuários'  />  Usuários   </Tab>                
                     <Tab> <FaMap     size='20' color={lib.EMBRAPA_VERDE_COLOR}   title='Estatísticas acerca das camadas'  />    Camadas    </Tab>                
                   </TabList>
         
                   <table><tr style={{height:'10px'}}></tr></table>
         
                   <TabPanel>
                     <a onClick={() =>  setModal( { code: modalConstants.modalCodeStatsCamadasPorBase } ) }>
                       <table border='0' cellspacing='0' cellpadding='0'>
                          <tr>
                            <td> <FaChartPie size='20' color={ lib.EMBRAPA_VERDE_COLOR } /> </td>                    
                            <td> <p style={labelStatScreenStyle}> Camadas por Database </p></td> 
                          </tr>
                        </table>                                           
                      </a>                                             
                   </TabPanel>
         
                   <TabPanel>                           
                     <table style={{width:'100%'}}>
                       <tr>
                         <td style={{alignItems:'left'}}>
                           <a onClick={() =>  setModal( { code: modalConstants.modalCodeStatsUsuariosPorPerfil } ) }>
                             <table border='0' cellspacing='0' cellpadding='0'>
                                <tr>
                                  <td> <RiBarChartHorizontalFill size='20' color={ lib.EMBRAPA_VERDE_COLOR } /> </td>                    
                                  <td> <p style={labelStatScreenStyle}> Usuários por Perfil </p></td> 
                                </tr>
                              </table>                                           
                            </a>                                     
                         </td>
                         <td style={{alignItems:'right'}}>
                           <a onClick={() =>  setModal( { code: modalConstants.modalCodeStatsUsuariosPorAcesso } ) }>
                             <table border='0' cellspacing='0' cellpadding='0'>
                                <tr>
                                  <td> <RiBarChartHorizontalFill size='20' color={ lib.EMBRAPA_VERDE_COLOR } /> </td>                    
                                  <td> <p style={labelStatScreenStyle}> Usuários por Acesso </p></td> 
                                </tr>
                              </table>                                           
                            </a>             
                         </td>
                       </tr>
                     </table>                                                    
                   </TabPanel>   

                   <TabPanel>                           
                     <a onClick={() =>  setModal( { code: modalConstants.modalCodeStatsCamadasPorTipo } ) }>
                       <table border='0' cellspacing='0' cellpadding='0'>
                          <tr>
                            <td> <BsBarChartFill size='20' color={ lib.EMBRAPA_VERDE_COLOR } /> </td>                    
                            <td> <p style={labelStatScreenStyle}> Camadas por Tipo </p></td> 
                          </tr>
                        </table>                                           
                      </a>                                                              
                   </TabPanel>                           
                                        
                 </Tabs>
                 
               </td>
             </tr>                          
         </CrudTable>                                                                      
}