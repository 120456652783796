import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { CrudTable, CrudBtVerde, CrudLabelRequired } from '../lib/lib'
import * as  lib from '../lib/lib' // Importando todas as constantes
import DebugContext from '../context/DebugContext'
import ModalContext, {ModalCodeIndex} from '../context/ModalContext'
import * as modalConstants from '../context/ModalContext'
import * as env from '../.env.js'
import '../lib/react-dual-listbox.css'
import Message from './../messages'


export default function PerfilEdit(props) {
  
  const  { modal, setModal } = useContext(ModalContext)

  const { params } = modal

  const { table, rest, id, acao } = params

  const modalCodeIndex = ModalCodeIndex(table)

  const { debug } = useContext(DebugContext)
  
  // ---------------------------------------------------------------------------

  const [txNome, setTxNome] = useState('');  

  const [txDescricao, setTxDescricao] = useState('');   

  useEffect(() => {

    if (acao == 'editar') {

      axios.get(rest + '/' + id)
      .then(res => {          
        console.log(res);          
        setTxNome(res.data.data.tx_nome)
        setTxDescricao(res.data.data.tx_descricao)
      })
      .catch(function (error) {
        let msg = error.request.response
        console.log(msg)
        alert( Message( msg ) )
      })        
      // .catch(error => {
      //   let msg = 'url: ' + rest + ' |||||  Erro: ' + error.message
      //   console.log(msg)
      // })                       

    }

  }, 
  []);  // Executa apenas uma vez

  // ---------------------------------------------------------------------------

  const [txNomeError, setTxNomeError] = useState('');  

  useEffect(() => {

    if (txNomeError != '' ) {
      alert("Erro no campo Nome: " + txNomeError)
      setTxNomeError('')
    }
    
  }, [txNomeError]);

  // ---------------------------------------------------------------------------

  const [txDescricaoError, setTxDescricaoError] = useState('');   

  useEffect(() => {

    if (txDescricaoError != '' ) {
      alert("Erro no campo Descrição: " + txDescricaoError)
      setTxDescricaoError('')
    }
          
  }, [txDescricaoError]);

  // ---------------------------------------------------------------------------


  // sudo curl -X PUT -H "Content-Type: application/json"  -d '{"id_usuario":"73", "tx_password": "nova"}' desenv.cpatc.embrapa.br:8888/usuarioRecovery

  async function btLoginClick(event) {

    event.preventDefault();    

    let txNomeErrorBeforeUpdate = lib.valid( txNome, setTxNomeError, 'required' )     
    let txDescricaoErrorBeforeUpdate = lib.valid( txDescricao, setTxDescricaoError, 'required' ) 
        
    if (
      txNomeErrorBeforeUpdate == ''
      &&
      txDescricaoErrorBeforeUpdate == ''
      ){        // TUDO OK PARA AÇÃO
        let perfil = {    // Para edição ainda precisa acrescentar o id
          tx_nome: txNome,
          tx_descricao: txDescricao,
        }        

        if (acao == 'novo') {

          axios.post(rest, perfil)
          .then(res => {          
             console.log(res);          
             
             alert('Registro inserido!')
             
             setModal ( { code: modalCodeIndex } )         
          })
          .catch(function (error) {
            let msg = error.request.response
            console.log(msg)
            alert( Message( msg ) )
          })          
          // .catch(error => {
          //    let msg = 'url: ' + rest + ' |||||  Erro: ' + error.message
          //    console.log(msg)
          // })                           

        }
        else { // ==> acao == 'editar'

          perfil.id_perfil = id
              
          axios.put(rest, perfil)
          .then(res => {          
             console.log(res);          
             
             alert('Registro alterado!')
             
             setModal ( { code: modalCodeIndex } )         
          })
          .catch(function (error) {
            let msg = error.request.response
            console.log(msg)
            alert( Message( msg ) )
          })          
          // .catch(error => {
          //    let msg = 'url: ' + rest + ' |||||  Erro: ' + error.message
          //    console.log(msg)
          // })                       
         
        }
      }        
  }


  const debugTable = lib.debug( 
                                  {
                                     debug: false,
                                     txNome: txNome,
                                     txNomeError: txNomeError,
                                     txDescricao: txDescricao,
                                     txDescricaoError: txDescricaoError,                                   
                                   }
                                )  

  let inputsWidth = {width: '400px'}

  return    <CrudTable title={ acao == 'novo' ? 'INSERINDO PERFIL' : 'ALTERANDO PERFIL' }  showRequired='true'  modalBtClose={modalConstants.modalCodePerfilIndex}    actionOnSubmit={btLoginClick}    debugTable={debugTable}>

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Nome:'/>
                 </td>
                 <td align='left'>
                   <input type='text' autoFocus style={inputsWidth}  value={txNome} onChange={ (e) => { setTxNome(e.target.value) } }   onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btLoginClick) }  }   /> 
                 </td>
               </tr>

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Descrição:'/>
                 </td>
                 <td align='left'>
                   <input type='text' style={inputsWidth}  value={txDescricao} onChange={ (e) => {setTxDescricao(e.target.value)} }   onKeyDown={ (e) => { lib.handleKeyDownToSubmitOnEnter(e, btLoginClick) }  }  />   
                 </td>
               </tr>                                          
              
               <tr align='center' height='40'>
                 <td colSpan='2'>
                   <CrudBtVerde onClick={ (event) => {btLoginClick(event)} } > { acao == 'novo' ? 'Inserir' : 'Alterar' } </CrudBtVerde>
                 </td>
               </tr>      

             </CrudTable>                                                                      

}