import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figMenuAdm from './img/menuadm.png'
import figOrdemDatabases from './img/ordem_databases.png'
import figOrdemCategorias from './img/ordem_categorias.png'
import figOrdemMapas from './img/ordem_mapas.png'


const Page = () => {

    return <html>
    
               <body>
                   <div style={estilos.tituloPagina}>Gerenciamento de Ordem</div>
               
                   <br/>
                   <p>
                       Para controlar a ordem de visualização dos conteúdos do GeoTC é necessário ter um perfil de gestor.
                       Considere o menu visto na Figura 1.
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figMenuAdm} />          
                       <figcaption style={estilos.figcaption}>Figura 1 - Menu de Administração</figcaption>
                   </figure>                   
               
                   <br/>
                   <p>
                       Neste menu, clique na opção <i>Ordem</i>,
                       e o sistema exibirá uma tela com três abas. 
                       A primeira delas serve para gerenciar a <b>ordem de apresentação dos Databases</b>,
                       e pode ser vista na Figura 2
                   </p>
                   <br/>
               
                   <figure>                      
                       <img style={estilos.imgStyle} src={figOrdemDatabases} />          
                       <figcaption style={estilos.figcaption}>Figura 2 - Gerenciando a ordem dos Databases</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Na segunda aba é possível gerenciar a <b>ordem das categorias do sistema</b>, conforme
                       exemplificado na Figura 3
                   </p>
                   <br/>
               
                   <figure>                                              
                       <img style={estilos.imgStyle} src={figOrdemCategorias} />          
                       <figcaption style={estilos.figcaption}>Figura 3 - Gerenciando a ordem das Categorias</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Na terceira aba é possível gerenciar a <b>ordem dos <i>shapes</i></b>, conforme
                       exemplificado na Figura 4
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figOrdemMapas} />          
                       <figcaption style={estilos.figcaption}>Figura 4 - Gerenciando a ordem dos <i>shapes</i></figcaption>
                   </figure>    
               
               
                   <br/>
                   <p>
                       A alteração da ordem de todos esses elementos será respeitada nas telas que os exibem.
                   </p>
                   <br/>    
               </body>
               
           </html>
           
}

const HGerenciamentoOrdem = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HGerenciamentoOrdem