import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { CrudSection, CrudTable, CrudBtVerde, CrudLabelRequired } from '../lib/lib'
import * as  lib from '../lib/lib' // Importando todas as constantes
import DebugContext from '../context/DebugContext'
import ModalContext, { modalCodeAvisoPrivacidade, modalCodeTermoUso, modalObjectDefault } from '../context/ModalContext'
import DualListBox from 'react-dual-listbox';
import * as env from '../.env.js'
import '../lib/react-dual-listbox.css'
import Message from './../messages'


export default function UsuarioRegister(props) {
  
  // ---------------------------------------------------------------------------

  const  {setModal } = useContext(ModalContext)

  // ---------------------------------------------------------------------------

  const { debug } = useContext(DebugContext)

  // ---------------------------------------------------------------------------

  const [ concordaAvisoPrivacidade, setConcordaAvisoPrivacidade ] = useState(false)

  const [ concordaTermoUso, setConcordaTermoUso ] = useState(false) 

  // ---------------------------------------------------------------------------

  const [ registered, setRegistered ] = useState(false)
  
  // ---------------------------------------------------------------------------
  
  const [txNome, setTxNome] = useState('');  

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela   

  // ---------------------------------------------------------------------------

  const [txNomeError, setTxNomeError] = useState('');  

  useEffect(() => {

    if (txNomeError != '' ) {
      alert("Erro no campo Usuário: " + txNomeError)
      setTxNomeError('')
    }
    
  }, [txNomeError]);
  
  // ---------------------------------------------------------------------------

  const [txPassword, setTxPassword] = useState('');   

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela   

  // ---------------------------------------------------------------------------

  const [txPasswordError, setTxPasswordError] = useState('');   

  useEffect(() => {

    if (txPasswordError != '' ) {
      alert("Erro no campo Senha: " + txPasswordError)
      setTxPasswordError('')
    }
          
  }, [txPasswordError]);

  // ---------------------------------------------------------------------------

  const [txConfirmPassword, setTxConfirmPassword] = useState('');   

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela   

  // ---------------------------------------------------------------------------

  const [txConfirmPasswordError, setTxConfirmPasswordError] = useState('');   

  useEffect(() => {

    if (txConfirmPasswordError != '' ) {
      alert("Erro no campo Confirmação de Senha: " + txConfirmPasswordError)
      setTxConfirmPasswordError('')
    }
          
  }, [txConfirmPasswordError]);

  // ---------------------------------------------------------------------------

  const [txEmail, setTxEmail] = useState(''); 

  useEffect(() => {}, []);  // Para que essas alterações não renderizem a tela 

  // ---------------------------------------------------------------------------

  const [txEmailError, setTxEmailError] = useState('');      

  useEffect(() => {

    if (txEmailError != '' ) {
      alert("Erro no campo E-mail: " + txEmailError)
      setTxEmailError('')
    }
          
  }, [txEmailError]);  

  // ---------------------------------------------------------------------------

  const [selectedGraduacoes, setSelectedGraduacoes] = useState([]);

  // ---------------------------------------------------------------------------

  const [optionsGraduacoes, setOptionsGraduacoes] = useState([]);    
  
  useEffect(() => {    
    axios.get(env.GEOTABREST_CRUD_GRADUACAO)
    .then(res => {
      const jsonGraduacoes = res.data.data
  
      let graduacoes = []
  
      jsonGraduacoes.forEach(graduacaoDaVez => {
        let graduacao = {
                          value: graduacaoDaVez.id_graduacao,
                          label: graduacaoDaVez.tx_descricao
                        }
        graduacoes.push(graduacao)
      });
  
      setOptionsGraduacoes(graduacoes)    
      
    })    
    .catch(function (error) {
      console.log(error);
    })          
    
  }, []); // Carrega optionsGraduacoes apenas uma vez

  // ---------------------------------------------------------------------------

  const [selectedAreasDeInteresse, setSelectedAreasDeInteresse] = useState([]);

  // ---------------------------------------------------------------------------

  const [optionsAreasDeInteresse, setOptionsAreasDeInteresse] = useState([]);    
  
  useEffect(() => {    
    axios.get(env.GEOTABREST_CRUD_CNPQ_GRANDE_AREA)
    .then(res => {
      const jsonAreasDeInteresse = res.data.data
  
      let areasDeInteresse = []
  
      jsonAreasDeInteresse.forEach(areaDeInteresseDaVez => {
        let areaDeInteresse = {
                          value: areaDeInteresseDaVez.id_cnpq_grande_area,
                          label: areaDeInteresseDaVez.tx_descricao
                        }
        areasDeInteresse.push(areaDeInteresse)
      });
  
      setOptionsAreasDeInteresse(areasDeInteresse)    
      
    })    
    .catch(function (error) {
      console.log(error);
    })          
    
  }, []); // Carrega optionsAreasDeInteresse apenas uma vez

  // ---------------------------------------------------------------------------

    


  async function btSalvarClick(event) {

    event.preventDefault();    

    let txConfirmPasswordErrorBeforeUpdate
    let txNomeErrorBeforeUpdate = lib.valid( txNome, setTxNomeError, 'required' )     
    let txPasswordErrorBeforeUpdate = lib.valid( txPassword, setTxPasswordError, 'passReqMin6' )     
    
    if (txPassword !== txConfirmPassword) {
      txConfirmPasswordErrorBeforeUpdate = 'Confirmação de Senha difere da Senha!'
      setTxConfirmPasswordError(txConfirmPasswordErrorBeforeUpdate)
    }
    else
      txConfirmPasswordErrorBeforeUpdate = ''

    let txEmailErrorBeforeUpdate = lib.valid( txEmail, setTxEmailError, 'validEmailRequired' )
    
    if (
      txNomeErrorBeforeUpdate == ''
      &&
      txPasswordErrorBeforeUpdate == ''
      &&
      txConfirmPasswordErrorBeforeUpdate == ''
      &&      
      txEmailErrorBeforeUpdate == ''      
      ){        // TUDO OK PARA INSERIR
        const usuario = {
                          id_perfil: 2,  // Perfil público
                          tx_nome: txNome,
                          tx_password: txPassword,
                          tx_email: txEmail
                         }

        let url_usuario = env.GEOTABREST_CRUD_USUARIO        

        axios.post(url_usuario, usuario)
        .then(res => {          
          console.log(res);          

          setTxNome('')
          setTxPassword('')
          setTxEmail('')          
          
          let id_usuario_from_insert = res.data.id_usuario_from_insert

          // --------------- INSERINDO GRADUAÇÕES ------------ INÍCIO ----------          

          if (selectedGraduacoes.length >= 1){ // INSERIR GRADUAÇÕES
            
            let url_graduacoes = env.GEOTABREST_CRUD_AREAS_DE_ATUACAO

            let graduacao
            let tupla
            for (let pos = 0; pos < selectedGraduacoes.length; pos++){
              graduacao = selectedGraduacoes[pos]
              tupla = { id_usuario:id_usuario_from_insert, id_graduacao:graduacao }

              axios.post(url_graduacoes, tupla) // Cada graduação precisa de um novo INSERT 
              .then(res => {
                console.log(res);     
                setSelectedGraduacoes([])                 
              })
              .catch(error => {
                let msg = 'url: ' + url_graduacoes + ' |||||  Erro: ' + error
                console.log(msg)
                // alert(msg)
              })                       
            }

          }
          // --------------- INSERINDO GRADUAÇÕES ------------ TÉRMINO ---------



          // --------------- INSERINDO ÁREAS DE INTERESSE ------ INÍCIO --------          

          if (selectedAreasDeInteresse.length >= 1){ // INSERIR ÁREAS DE INTERESSE
            
            let url_areas_de_interesse = env.GEOTABREST_CRUD_AREAS_DE_INTERESSE

            let area_de_interesse
            let tupla
            for (let pos = 0; pos < selectedAreasDeInteresse.length; pos++){
              area_de_interesse = selectedAreasDeInteresse[pos]
              tupla = { id_usuario:id_usuario_from_insert, id_cnpq_grande_area:area_de_interesse }

              axios.post(url_areas_de_interesse, tupla) // Cada graduação precisa de um novo INSERT 
              .then(res => {
                console.log(res);                      
                setSelectedAreasDeInteresse([])
              })
              .catch(error => {
                let msg = 'url: ' + url_areas_de_interesse + ' |||||  Erro: ' + error
                console.log(msg)
                // alert(msg)
              })                       
            }

          }
          // --------------- INSERINDO ÁREAS DE INTERESSE ------ TÉRMINO -------                    

          setRegistered(true)
          alert('Registro efetuado!')
        })
        .catch(function (error) {
          let msg = error.request.response
          console.log(msg)
          alert( Message( msg ) )
        })                  

    }
        
  }


  const debugTable = lib.debug( 
                                  {
                                     debug: false,
                                     txNome: txNome,
                                     txNomeError: txNomeError,
                                     txPassword: txPassword,
                                     txPasswordError: txPasswordError,
                                     txEmail: txEmail,
                                     txEmailError: txEmailError,                                     
                                     selectedGraduacoes: selectedGraduacoes,
                                     selectedAreasDeInteresse: selectedAreasDeInteresse,
                                     registered:registered
                                   }
                                )  

  let width = {width: '400px'}

  function renderRedirect() { // Quando registrar, redirecionar para tela de login
    if (registered) {
      setModal( modalObjectDefault )
      // Router.push('/') // Como não está logado... direciona para o login! // Substituir por algo aqui
    }
    else
      return null        
  }  

  return    <CrudTable title='REGISTRO' showRequired='true'  debugTable={debugTable}>
               {renderRedirect()}
               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Usuário:'/>
                 </td>
                 <td align='left'>
                   <input type='text' autoFocus style={width}  value={txNome} onChange={ (e) => { setTxNome(e.target.value) } }       onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, btSalvarClick)} }   />  
                 </td>
               </tr>

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Senha:'/>
                 </td>
                 <td align='left'>
                   <input type='password' style={width}  value={txPassword} onChange={ (e) => {setTxPassword(e.target.value)} }      onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, btSalvarClick)} }   />  
                 </td>
               </tr>

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='Confirmação de Senha:'/>
                 </td>
                 <td align='left'>
                   <input type='password' style={width}  value={txConfirmPassword} onChange={ (e) => {setTxConfirmPassword(e.target.value)} }        onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, btSalvarClick)} }   />  
                 </td>
               </tr>               

               <tr>
                 <td align='right'>
                   <CrudLabelRequired label='E-mail:'/>
                 </td>
                 <td align='left'>
                   <input type='text' style={width}  value={txEmail} onChange={ (e) => {setTxEmail(e.target.value)} }     onKeyDown={ (e) => {lib.handleKeyDownToSubmitOnEnter(e, btSalvarClick)} }   />  
                 </td>
               </tr>    

               <tr>
                 <td colSpan='2' style={lib.CRUD_LABEL_AZUL} align='center' >
                   <CrudSection left='Disponível' main='Áreas de Atuação' right='Selecionado'>
                     <DualListBox   
                           alignActions="top"              
                           lang={lib.DualListBoxLanguage}
                           icons={lib.DualListBoxIconsLeftRight}
                           options={optionsGraduacoes} 
                           selected={selectedGraduacoes}
                           onChange={ (selectedValues) => { setSelectedGraduacoes(selectedValues) } }                         
                        />                 
                   </CrudSection>
                 </td>
               </tr>     

               <tr>
                 <td colSpan='2' style={lib.CRUD_LABEL_AZUL} align='center' >
                   <CrudSection left='Disponível' main='Áreas de Interesse' right='Selecionado'>
                     <DualListBox      
                           alignActions="top"              
                           lang={lib.DualListBoxLanguage}
                           icons={lib.DualListBoxIconsLeftRight}
                           options={optionsAreasDeInteresse} 
                           selected={selectedAreasDeInteresse}
                           onChange={ (selectedValues) => { setSelectedAreasDeInteresse(selectedValues) } }                         
                        />                                   
                   </CrudSection>                   
                 </td>
               </tr>                                                

               <tr align='center' height='40'>
                 <td colSpan='2'>
                   <input
                     name="ckAvisoPrivacidade"
                     type="checkbox"
                     checked={concordaAvisoPrivacidade}
                     onChange={e => {setConcordaAvisoPrivacidade(e.target.checked)}} /> 
                     Concordo com o 
                     <a href='' onClick={ () => setModal({ code: modalCodeAvisoPrivacidade, params:null }) } > Aviso de Privacidade </a> 
                     ?
                 </td>                 
               </tr>      
               <tr align='center' height='40'>
                 <td colSpan='2'>
                   <input
                     name="ckTermoUso"
                     type="checkbox"
                     checked={concordaTermoUso}
                     onChange={e => {setConcordaTermoUso(e.target.checked)}} />                     
                     Concordo com o 
                     <a href='' onClick={ () => setModal({ code: modalCodeTermoUso, params:null }) } > Termo de Uso </a> 
                     ?                     
                 </td>
               </tr>      

               <tr align='center' height='40'>
                 <td colSpan='2'>
                   <CrudBtVerde name='btSalvar' onClick={ (event) => {btSalvarClick(event)} }   disabled={!concordaAvisoPrivacidade || !concordaTermoUso}  > Salvar </CrudBtVerde>
                 </td>
               </tr>      

             </CrudTable>                                                                      

}