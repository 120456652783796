import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figBtDownload from './img/bt_download.png'
import figDownloadUmaImagem from './img/msg_download1.png'
import figMsgConfirmDownload from './img/msg_download_confirm.png'
import figDownloadEfetuado from './img/download_efetuado.png'


const Page = () => {

    return <html>
               <body>
                   <div style={estilos.tituloPagina}>Download de Shapes</div>
           
                   <br/>
                   <p>
                       O download de um shape pode ser realizado clicando no botão ( <img src={figBtDownload} />). 
                       Entretanto, se no momento do clique mais de uma imagem estiver selecionada, o sistema exibirá a 
                       mensagem vista na Figura 1.
                   </p>
                   <br/>        
           
                   <figure>
                       <img style={estilos.imgStyle} src={figDownloadUmaImagem} />                      
                       <figcaption style={estilos.figcaption}>Figura 1 - Download de apenas uma imagem</figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Para evitar a situação vista na Figura 1, selecione apenas um shape, e clique no 
                       ícone de Download ( <img src={figBtDownload} />). O sistema exibirá uma
                       mensagem para confirmar o download, conforme Figura 2.
                   </p>
                   <br/>
           
                   <figure>
                       <img style={estilos.imgStyle} src={figMsgConfirmDownload} />                            
                       <figcaption style={estilos.figcaption}>Figura 2 - Confirmação de Download</figcaption>
                   </figure>        
           
           
                   <br/>
                   <p>
                       Se o browser questionar o download, isso acontecerá por meio de um pop-up. 
                       Conceda as permissões e proceda com o download.
                       Seguindo todos os passos citados neste roteiro, será possível visualizar, no próprio browser,
                       que o Download foi concluído, conforme Figura 3.
                   </p>
                   <br/>        
           
           
                   <figure>
                       <img style={estilos.imgStyle} src={figDownloadEfetuado} />                                                   
                       <figcaption style={estilos.figcaption}>Figura 3 - Download efetuado</figcaption>
                   </figure>                
           
                   <br/>
                   <p>
                       Por fim, visite a pasta de downloads do seu sistema operacional
                       para ter acesso ao shape.
                   </p>
                   <br/>        
           
               </body>
           </html>
}

const HDownload = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HDownload