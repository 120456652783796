const constraints = 
{
    'fk__usuario__id_perfil': 'Perfil em uso!', 
    'fk__camada__id_usuario': 'Usuário em uso!',
    'uk__usuario__tx_nome': 'Nome de usuário em uso! Escolha outro!',
    'uk__usuario__tx_email': 'Email em uso! Escolha outro!',
    'uk__perfil__tx_nome': 'Nome de perfil em uso! Escolha outro!',
    'fk__permissao__id_perfil': 'Perfil em uso!',
    
}

const Message = (error) => {

    for ( let constraint in constraints )
      if ( error.indexOf(constraint) !== -1 )
        return constraints[constraint]
    
    return `Erro não mapeado: ${error}!`
}

export default Message;