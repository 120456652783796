import React from 'react'

export const imgStyle = {
    padding: '0px 0px',    
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0px',
    verticalAlign: 'baseline',
    contentAlign:'center',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  }
  
  export const linkStyle = {
    color:'#385b9b', 
    fontWeight:'bold', 
    fontSize:'11px',
    cursor:'pointer'
  }

  export const linkStyleActive = {    
    color:'#385b9b', 
    background:'yellow',
    fontWeight:'bold', 
    fontSize:'11px',
    cursor:'pointer'
  }  
  
  export const tituloPrincipal = {
    fontFamily:'verdana', 
    fontSize:'40px', 
    fontWeight:'bold', 
    color:'#385b9b', 
    width:'100%', 
    textAlign:'center'
  }

  export const observacao = {
    fontFamily:'verdana', 
    fontSize:'10px', 
    fontWeight:'bold', 
    color:'#f00', 
    width:'100%', 
    textAlign:'center'
  }


  export const tituloPagina = {
    fontFamily:'verdana', 
    fontSize:'20px', 
    fontWeight:'bold', 
    color:'#3e8454',
    width:'100%', 
    textAlign:'center',
    padding: '50px'
  }  

 export const figcaption = {    /* Utilizado para os nomes das figuras */
  fontSize: '13px',
  fontWeight: 'bold', 
  textAlign:'center',
}