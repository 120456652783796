import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figTelaBemVindo from './img/tela_bemvindo.png'
import figTelaRegistro from './img/tela_registro.png'
import figRegistroEfetuado from './img/msg_registro_efetuado.png'
import figLoginEfetuado from './img/login_efetuado.png'

const Page = () => {

    return <html>
             <body>
                 <div style={estilos.tituloPagina}>Cadastro para usuários com perfil público</div>
                          
                 <figure>
                     <img style={estilos.imgStyle} src={figTelaBemVindo} />
                     <figcaption style={estilos.figcaption}>Figura 1 - Tela Login/Registro/Recuperação</figcaption>
                 </figure>
         
                 <br/>
                 <p>
                     Na tela vista na Figura 1, clique no botão Registrar.
                 </p>
                 <br/>
         
                 <figure>
                     <img style={estilos.imgStyle} src={figTelaRegistro} />
                     <figcaption style={estilos.figcaption}>Figura 2 - Tela de Registro</figcaption>
                 </figure>        
         
                 <br/>
                 <p>
                     A tela vista na Figura 2 será apresentada. O aceite do <i>Aviso de Privacidade</i> e do <i>Termo de Uso</i> são necessários para o cadastro.
                     Preencha os dados e clique no botão “Salvar”.
                     Se tudo estiver preenchido corretamente, será visualizada a mensagem vista na Figura 3.                        
                 </p>
                 <br/>
         
                 <figure>
                     <img style={estilos.imgStyle} src={figRegistroEfetuado} />
                     <figcaption style={estilos.figcaption}>Figura 3 - Registro efetuado</figcaption>
                 </figure>        
         
                 <br/>
                 <p>
                     Clique no botão de OK, e você será direcionado para a tela vista na Figura 1. Nesse ponto, 
                     basta preencher os valores que acabou de registrar nos campos usuário e senha, e clicar no botão “Entrar”.            
                 </p>
                 <br/>
         
                 <figure>
                     <img style={estilos.imgStyle} src={figLoginEfetuado} />
                     <figcaption style={estilos.figcaption}>Figura 4 - Login efetuado</figcaption>
                 </figure>        
         
         
                 <br/>
                 <p>
                     Após entrar no sistema, será possível visualizar, no canto superior direito da tela, informações similares 
                     às que podem ser vistas na Figura 4: O nome e o perfil do usuário. Também será possível efetuar logoff 
                     clicando no botão “Sair”, presente na referida imagem.                        
                 </p>
                 <br/>
         
               </body>
            </html>       
}

const HCadastroUsuarioPublico = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HCadastroUsuarioPublico