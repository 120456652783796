import React, { useState, useEffect } from "react";
import { CrudTable, CrudCombo, CrudLabelRequired } from '../lib/lib'
import Table from './../components/table'
import * as lib from './../lib/lib'
import * as env from '../.env.js'
import * as modalConstants from '../context/ModalContext'
import { FaDatabase, FaListUl, FaMap } from 'react-icons/fa'
import { ImArrowDown, ImArrowUp } from 'react-icons/im'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './../lib/react-tabs.css';


export default function OrdemIndex(props) {

  const iconUp   = <ImArrowUp   title='Subir'  size={lib.ORDER_ICON_SIZE} color={lib.ORDER_COLOR} />

  const iconDown = <ImArrowDown title='Descer' size={lib.ORDER_ICON_SIZE} color={lib.ORDER_COLOR} />

  const [idBaseDeDados, setIdBaseDeDados] = useState(-1);  

  const [idCategoriaCamadaPai, setIdCategoriaCamadaPai] = useState(-1)

  // ---------------------------------------------------------------------------------------

  const [restComboCamadaPai, setRestComboCamadaPai] = useState(null)

  useEffect(() => {    
    setIdCategoriaCamadaPai(-1)
    setRestComboCamadaPai(env.GEOTABREST_CATEGORIA_CAMADA_POR_DATABASE + '/' + idBaseDeDados)
  }, 
  [idBaseDeDados]);   

  // ---------------------------------------------------------------------------------------

  const [jsxComboCamadaPai, setJsxComboCamadaPai] = useState(null)

  useEffect(() => {        
    if (idBaseDeDados == -1)
      setJsxComboCamadaPai( <font style={lib.MESSAGE_STYLE} > Primeiro selecione a Base de Dados! </font> )
    else
      setJsxComboCamadaPai( <CrudCombo rest={restComboCamadaPai} pk='id_categoria_camada' desc='desc_camada' item={idCategoriaCamadaPai} setItem={setIdCategoriaCamadaPai}  /> ) 
  }, 
  [restComboCamadaPai, idCategoriaCamadaPai]);   

  // ---------------------------------------------------------------------------------------

  const [restTableCategorias, setRestTableCategorias] = useState(null)  

  useEffect(() => {        
    setRestTableCategorias( null )
  }, 
  [idCategoriaCamadaPai]);   

  useEffect(() => {          
    if (restTableCategorias == null)
      setRestTableCategorias( env.GEOTABREST_CATEGORIA_CAMADA_POR_PAI + '/' + idCategoriaCamadaPai )    
  }, 
  [restTableCategorias]);     

  // ---------------------------------------------------------------------------------------
   
  const [categoriaColumns, setCategoriaColumns] = useState(null)    //  '/categoria_camada__order/:id_categoria_camada_pai/:in_order/:in_direction'

  useEffect(() => {        
    setCategoriaColumns( 
                         [
                           {
                             name: '',
                             selector: '',
                             width: '20px',      
                             sortable: false,      
                             customIcon: iconDown,
                             customRESTFields: ['in_order'], // Vetor com campos a serem capturados dinamicamente a cada linha
                             customREST: env.GEOTABREST_CATEGORIA_CAMADA_ORDER + `/${idCategoriaCamadaPai}/in_order/0`,
                             customRESTMethod: 'put'
                           },        
                           {
                             name: '',
                             selector: '',
                             width: '30px',      
                             sortable: false,      
                             customIcon: iconUp,
                             customRESTFields: ['in_order'], // Vetor com campos a serem capturados dinamicamente a cada linha
                             customREST: env.GEOTABREST_CATEGORIA_CAMADA_ORDER + `/${idCategoriaCamadaPai}/in_order/1`,
                             customRESTMethod: 'put'
                           },             
                           {
                             name: 'Descrição',
                             selector: 'desc_camada',
                             width: '200px',
                             sortable: false,
                           }
                         ]                         
                       )
  }, 
  [idCategoriaCamadaPai]);   

  // ---------------------------------------------------------------------------------------  

  const [jsxTableCategoria, setJsxTableCategoria] = useState(null)

  useEffect(() => {        

    if ( restTableCategorias == null ) 
      setJsxTableCategoria(
                             <font style={lib.MESSAGE_STYLE} > Primeiro selecione a Camada Pai! </font>
                          )
    else
      setJsxTableCategoria(
                            <Table 
                              rest={ restTableCategorias } 
                              table='base_de_dados'
                              pk='id_categoria_camada'
                              columns={categoriaColumns}                    
                              controlInsert={false} 
                              controlEdit={false} 
                              controlDelete={false} 
                            />                                                           
                          )
  }, 
  [restTableCategorias]);   
  
  // ---------------------------------------------------------------------------------------


  // GEOTABREST_CAMADA_ORDER
  // '/camada__order/:id_base_de_dados/:id_categoria_camada/:in_order/:in_direction'

  const [restTableMapas, setRestTableMapas] = useState(null)  

  useEffect(() => {        
    setRestTableMapas( null )
  }, 
  [idCategoriaCamadaPai]);   

  useEffect(() => {          
    if (restTableMapas == null)
      setRestTableMapas( env.GEOTABREST_CRUD_CAMADA + '/' + idBaseDeDados + '/' + idCategoriaCamadaPai )    // // export const GEOTABREST_CRUD_CAMADA  =  GEOTABREST_SERVICE + '/camada' //  '/camada/:id_base_de_dados/:id_categoria_camada'
  }, 
  [restTableMapas]);     

  // PARA CONTINUAR....

  // ---------------------------------------------------------------------------------------  

  const [mapaColumns, setMapaColumns] = useState(null)    //  '/camada__order/:id_base_de_dados/:id_categoria_camada/:in_order/:in_direction'

  useEffect(() => {        
    setMapaColumns( 
                         [
                           {
                             name: '',
                             selector: '',
                             width: '20px',      
                             sortable: false,      
                             customIcon: iconDown,
                             customRESTFields: ['in_order'], // Vetor com campos a serem capturados dinamicamente a cada linha
                             customREST: env.GEOTABREST_CAMADA_ORDER + `/${idBaseDeDados}/${idCategoriaCamadaPai}/in_order/0`,
                             customRESTMethod: 'put'
                           },        
                           {
                             name: '',
                             selector: '',
                             width: '30px',      
                             sortable: false,      
                             customIcon: iconUp,
                             customRESTFields: ['in_order'], // Vetor com campos a serem capturados dinamicamente a cada linha
                             customREST: env.GEOTABREST_CAMADA_ORDER + `/${idBaseDeDados}/${idCategoriaCamadaPai}/in_order/1`,
                             customRESTMethod: 'put'
                           },             
                           {
                             name: 'Descrição',
                             selector: 'tx_titulo',
                             width: '200px',
                             sortable: false,
                           }
                         ]                         
                       )
  }, 
  [idCategoriaCamadaPai]);     


  // ---------------------------------------------------------------------------------------  

  const [jsxTableMapa, setJsxTableMapa] = useState(null)

  useEffect(() => {        

    if ( restTableMapas == null ) 
      setJsxTableMapa(
                             <font style={lib.MESSAGE_STYLE} > Primeiro selecione a Camada Pai! </font>
                          )
    else
      setJsxTableMapa(
                            <Table 
                              rest={ restTableMapas } 
                              table='camada'
                              pk='id_camada'
                              columns={mapaColumns}                    
                              controlInsert={false} 
                              controlEdit={false} 
                              controlDelete={false} 
                            />                                                           
                          )
  }, 
  [restTableMapas]);   

  
  // ---------------------------------------------------------------------------------------

                                
  const database_columns = [
    {
      name: '',
      selector: '',
      width: '20px',      
      sortable: false,      
      customIcon: iconDown,
      customRESTFields: ['in_order'], // Vetor com campos a serem capturados dinamicamente a cada linha
      customREST: env.GEOTABREST_TABLE__ORDER + '/base_de_dados/in_order/0',  // /table__order/:table/:in_order/:in_direction
      customRESTMethod: 'put'
    },        
    {
      name: '',
      selector: '',
      width: '30px',      
      sortable: false,      
      customIcon: iconUp,
      customRESTFields: ['in_order'], // Vetor com campos a serem capturados dinamicamente a cada linha
      customREST: env.GEOTABREST_TABLE__ORDER + '/base_de_dados/in_order/1',  // /table__order/:table/:in_order/:in_direction
      customRESTMethod: 'put'
    },             
    {
      name: 'Descrição',
      selector: 'tx_descricao',
      width: '200px',
      sortable: false,
    }
  ];                                    


  const debugTable = lib.debug( 
    {
       debug: false,
       idBaseDeDados: idBaseDeDados,
       idCategoriaCamadaPai: idCategoriaCamadaPai
     }
  )     
  
  return    <CrudTable border='1' title='ORDEM' width='100%' modalBtClose={modalConstants.modalCodeNone}  showRequired='true'  debugTable={debugTable}>

               <tr style={{height:'10px'}}></tr>

               <tr width='100%'>
                 <td align='center'>

                   <Tabs defaultIndex={1} onSelect={index => console.log(index)}>

                     <TabList border='1'>
                       <Tab> <FaDatabase size='20' color={ lib.EMBRAPA_AZUL_COLOR }  title='Altere a ordem dos Databases do GeoTC'   />  Databases  </Tab>
                       <Tab> <FaListUl   size='20' color='orange'                    title='Altere a ordem das Categorias do GeoTC'  />  Categorias </Tab>
                       <Tab> <FaMap      size='20' color={ lib.EMBRAPA_VERDE_COLOR } title='Altere a ordem dos Mapas do GeoTC'       />  Mapas      </Tab>
                     </TabList>

                     <table><tr style={{height:'10px'}}></tr></table>

                     <TabPanel>
                       <Table 
                         rest={env.GEOTABREST_CRUD_BASE_DE_DADOS} 
                         table='base_de_dados'
                         pk='id_base_de_dados'
                         columns={database_columns}                    
                         controlInsert={false} 
                         controlEdit={false} 
                         controlDelete={false} 
                       />     
                     </TabPanel>

                     <TabPanel>                         
                       <table>
  
                         <tr>
                           <td align='right'>
                             <CrudLabelRequired label='Base de Dados:'/>
                           </td>
                           <td align='left'>
                             <CrudCombo rest={env.GEOTABREST_CRUD_BASE_DE_DADOS} pk='id_base_de_dados' desc='tx_descricao' item={idBaseDeDados} setItem={setIdBaseDeDados} />
                           </td>
                         </tr>
                         
                         <tr>
                           <td align='right'>
                             <CrudLabelRequired label='Categoria Pai:'/>
                           </td>
                           <td align='left'>
                             { jsxComboCamadaPai }
                           </td>
                         </tr>               
                         
                         <table><tr style={{height:'20px'}}></tr></table>

                         <tr>
                           <td colspan='2'>
                             { jsxTableCategoria }                             
                           </td>
                         </tr>
                                                  
                       </table>
                     </TabPanel>

                     <TabPanel>
                       <table>
    
                        <tr>
                          <td align='right'>
                            <CrudLabelRequired label='Base de Dados:'/>
                          </td>
                          <td align='left'>
                            <CrudCombo rest={env.GEOTABREST_CRUD_BASE_DE_DADOS} pk='id_base_de_dados' desc='tx_descricao' item={idBaseDeDados} setItem={setIdBaseDeDados} />
                          </td>
                        </tr>
                        
                        <tr>
                          <td align='right'>
                            <CrudLabelRequired label='Categoria:'/>
                          </td>
                          <td align='left'>
                            { jsxComboCamadaPai }
                          </td>
                        </tr>               

                        <table><tr style={{height:'20px'}}></tr></table>
                        
                        <tr>
                          <td colspan='2'>
                            {jsxTableMapa}                            
                          </td>
                        </tr>
                                                 
                      </table>
                     </TabPanel>
                                          
                   </Tabs>
                   
                 </td>
               </tr>                          
           </CrudTable>                                                                      
}