import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figMenuAdm from './img/menuadm.png'
import figCRUDPerfil from './img/crud_perfil.png'
import figCRUDPerfilInsert from './img/crud_perfil_insert.png'
import figCRUDPerfilUpdate from './img/crud_perfil_update.png'
import figCRUDBtAdd from './img/bt_crud_add.png'
import figCRUDBtUpdate from './img/bt_crud_upd.png'
import figCRUDBtDelete from './img/bt_crud_del.png'
import figMsgExcluir from './img/msg_excluir_registro.png'


const Page = () => {

    return <html>
               <body>
                   <div style={estilos.tituloPagina}>Gerenciamento de Perfil</div>
               
                   <br/>
                   <p>
                       Para controlar os perfis dos usuários é necessário ter um perfil de gestor.
                       Considere o menu visto na Figura 1.
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figMenuAdm} />
                       <figcaption style={estilos.figcaption}>Figura 1 - Menu de Administração</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Neste menu, clique na opção <i>Perfil</i>,
                       e o sistema exibirá a tela vista na Figura 2
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figCRUDPerfil} />
                       <figcaption style={estilos.figcaption}>Figura 2 - Perfil</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Na tela em questão ( Figura 2 ) é possível realizar todas as operações de manutenção de dados:
                       visualização, inserção, atualização e remoção.
                       <br/>
                       Para realizar uma inserção, clique no botão <img src={figCRUDBtAdd} />,
                       e o sistema exibirá a tela vista na Figura 3.
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figCRUDPerfilInsert} />
                       <figcaption style={estilos.figcaption}>Figura 3 - Inserção de Perfil</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Para realizar uma atualização, clique no botão <img src={figCRUDBtUpdate} />,
                       e o sistema exibirá a tela vista na Figura 4.
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figCRUDPerfilUpdate} />
                       <figcaption style={estilos.figcaption}>Figura 4 - Atualização de Perfil</figcaption>
                   </figure>
               
               
                   <br/>
                   <p>
                       Para realizar uma remoção, clique no botão <img src={figCRUDBtDelete} />,
                       e o sistema exibirá a mensagem vista na Figura 5.
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figMsgExcluir} />
                       <figcaption style={estilos.figcaption}>Figura 5 - Confirmação de exclusão</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Se o usuário clicar no botão <i>OK</i> ( da tela de confirmação exibida na Figura 5 ) 
                       e existir algum empedimento para a remoção do registro selecionado, então, o sistema
                       dará uma mensagem objetiva explicando o motivo de não conseguir remover.
                   </p>
                   <br/>
               </body>
               
           </html>           
}

const HGerenciamentoPerfil = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HGerenciamentoPerfil