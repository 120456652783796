import React from 'react';
import DebugProvider from './context/DebugProvider'
import LoggedUserProvider from './context/LoggedUserProvider'
import AvailableKindsOfLayersProvider from './context/AvailableKindsOfLayersProvider'
import AvailableLayersProvider from './context/AvailableLayersProvider'
import HeaderProvider from './context/HeaderProvider'
import ModalProvider from './context/ModalProvider'
import MapSequenceProvider from './context/MapSequenceProvider'
import SelectedLayersProvider from './context/SelectedLayersProvider'
import SelectedIdsProvider from './context/SelectedIdsProvider'
import ExpandedIdsProvider from './context/ExpandedIdsProvider'
import SelectedAncestorNodeProvider from './context/SelectedAncestorNodeProvider'
import TreeViewProvider from './context/TreeViewProvider'
import FlagUpdateTreeProvider from './context/FlagUpdateTreeProvider'
import Home from './home'

function App() {

  return  <DebugProvider>
             <ModalProvider>
               <LoggedUserProvider>
                  <AvailableKindsOfLayersProvider>                     
                    <AvailableLayersProvider>
                      <HeaderProvider>
                        <MapSequenceProvider>                        
                          <FlagUpdateTreeProvider>
                            <SelectedAncestorNodeProvider>
                              <SelectedLayersProvider>
                                <TreeViewProvider>
                                  <SelectedIdsProvider>
                                    <ExpandedIdsProvider>                                                                                      
                 
                                      <Home/>             
                                                 
                                    </ExpandedIdsProvider>
                                  </SelectedIdsProvider>
                                </TreeViewProvider>  
                              </SelectedLayersProvider>
                            </SelectedAncestorNodeProvider>
                          </FlagUpdateTreeProvider>                        
                      </MapSequenceProvider>
                    </HeaderProvider>                         
                  </AvailableLayersProvider>                    
                </AvailableKindsOfLayersProvider>
              </LoggedUserProvider>
            </ModalProvider>                                       
          </DebugProvider>                                              

}

export default App;