import React, { useState, useEffect, useContext } from "react";
import AvailableKindsOfLayersContext, {defaultAvailableKindsOfLayers} from "./AvailableKindsOfLayersContext";
import FlagUpdateTreeContext from './FlagUpdateTreeContext'

export const availableKindsOfLayersKeyForLocalStorage = '@geotab/availableKindsOfLayers'

export const useAvailableKindsOfLayersWithLocalStorage = localStorageKey => {
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [availableKindsOfLayers, setAvailableKindsOfLayers] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultAvailableKindsOfLayers )

  const { flagUpdateTree } = useContext(FlagUpdateTreeContext)  // ====> new

  useEffect( () => // Salva na localStorage apenas quando availableKindsOfLayers for alterado!
              {localStorage.setItem(localStorageKey, JSON.stringify( availableKindsOfLayers ) )}, 
              [flagUpdateTree, availableKindsOfLayers]
           )

  return [availableKindsOfLayers, setAvailableKindsOfLayers]
}

const AvailableKindsOfLayersProvider = ({ children }) => {  
  const [availableKindsOfLayers, setAvailableKindsOfLayers] = useAvailableKindsOfLayersWithLocalStorage(availableKindsOfLayersKeyForLocalStorage);

  return (
    <AvailableKindsOfLayersContext.Provider value={{ availableKindsOfLayers, setAvailableKindsOfLayers }}>
      {children}
    </AvailableKindsOfLayersContext.Provider>
  )
}

export default AvailableKindsOfLayersProvider;