import React, { useRef, useEffect, useContext } from 'react'
import L from 'leaflet'
import SelectedLayersContext from './../context/SelectedLayersContext'
import { getFeatureInfo } from './getFeatureInfo'
import * as env from './../.env.js'
import 'leaflet/dist/leaflet.css'
import './../lib/map.css'// Definindo dimensões do mapa

function MapViewer(props) {

  const center = [-10.91, -37.07] // Centro em Aracaju (perfeito para centralizar área dos Tabuleiros Costeiros)  

  const base_map_classico = L.tileLayer(
                              'http://{s}.tile.OSM.org/{z}/{x}/{y}.png', 
                              { attribution:'&copy; <a href="http://OSM.org/copyright" target="_blanktc"> OpenStreetMap</a> contributors' }
                            )
  const base_map_cinza = L.tileLayer(
                                       'http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png', 
                                       { attribution:'&copy; <a href="http://OSM.org/copyright" target="_blanktc"> OpenStreetMap</a> contributors' }
                                    )                                                           
  const base_map_topografico = L.tileLayer(
                                            'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', 
                                            { attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright" target="_blanktc"> OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)' }
                                          )                                                                 
  var baseMaps = {
   "Clássico": base_map_classico,  // Verificar porque as layers não aparecem nos outros mapas de base
  //  "Cinza": base_map_cinza, 
  //  "Topográfico": base_map_topografico,
  }

  const { selectedLayers } = useContext(SelectedLayersContext)  
    
  const mapRef = useRef(null);  // Referência para o mapa
    
  const lgRef = useRef(null);  // Referencia para o LayerGroup
  

  useEffect(() => {

    mapRef.current = L.map('map', { // Criação do Mapa      
      center: center,      
      zoom:6
    });    

    // NO ÚLTIMO PUSH, PARA ESSE ARQUIVO, A ÚNICA LINHA MODIFICA FOI A LINHA ABAIXO:
    // L.control.layers(baseMaps).addTo(mapRef.current); // Definindo layers de base
    
    // baseMaps["Cinza"].addTo(mapRef.current); // Definindo Base layer default
    // baseMaps["Topográfico"].addTo(mapRef.current); // Definindo Base layer default
    baseMaps["Clássico"].addTo(mapRef.current); // Definindo Base layer default
    
    lgRef.current = L.layerGroup().addTo(mapRef.current) // Definindo Layer Group

    getFeatureInfo(mapRef.current, [], )    // Disponibilizando o getFeatureInfo

  }, []); // Executa apenas uma vez



  useEffect(() => {    
    if (mapRef.current) { // Se o mapa já foi inicializado      

      let layers = selectedLayers.map( (layer) => {                                                
        return L.tileLayer.wms( env.GEOSERVER_WMS, { layers: layer,
                                                     format: 'image/png',
                                                     transparent: true,                                                     
                                                     attribution:'<a href="https://www.embrapa.br/tabuleiros-costeiros" target="_blanktc">Embrapa Tabuleiros Costeiros</a>' 
                                                    })        
                                  })      
      lgRef.current.clearLayers()
      for (let i = 0; i < layers.length; i++)
        lgRef.current.addLayer(layers[i])        

    }

  }, [selectedLayers]);   // Executa sempre que selectedLayers for alterado
  

  return <div id="map"></div>
}

export default MapViewer