import React, { useState, useContext, useEffect } from "react";
import AvailableLayersContext, {defaultAvailableLayers} from "./AvailableLayersContext";
import AvailableKindsOfLayersContext, {defaultAvailableKindsOfLayers} from './AvailableKindsOfLayersContext' // ====> new
import axios from "axios"; // ====> new
import * as env from '../.env.js'

export const availableLayersKeyForLocalStorage = '@geotc/availableLayers'

export const useAvailableLayersWithLocalStorage = localStorageKey => {

  const { availableKindsOfLayers } = useContext(AvailableKindsOfLayersContext)  // ====> new

    // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [availableLayers, setAvailableLayers] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultAvailableLayers )

  // Precisa marcar como async... e colocar o await na frente do axios.get... caso constrário, teremos erros de CORs
  async function UpdateAvailableLayers() {

    // Preparando as categorias -- INÍCIO
    let kinds = ''  
    if (availableKindsOfLayers != defaultAvailableKindsOfLayers){

      Array.from(availableKindsOfLayers, kind => {
        if (kinds == '')
          kinds += String(kind.id_categoria_camada)
        else
          kinds += '@' + String(kind.id_categoria_camada)        
      }); 

    }    
    // Preparando as categorias -- TÉRMINO


    if ( kinds == ''){ // Nada selecionado como availableKindsOfLayers, então: limpar availableLayers

      setAvailableLayers( defaultAvailableLayers )
      localStorage.setItem( localStorageKey, JSON.stringify( defaultAvailableLayers ) )

    }
    else{

      let address = env.GEOTABREST_GET_LAYERS_FROM_KINDS + '/' + kinds

      await axios.get( address )
      .then(res => {
        
        setAvailableLayers( res.data.info[0] )
        localStorage.setItem( localStorageKey, JSON.stringify( res.data.info[0] ) )
        
      })    
      .catch(function (error) {
        console.log(error);                  
      })    

    }

  }


  useEffect( () => // Salva na localStorage apenas quando availableKindsOfLayers for alterado!
              {
                UpdateAvailableLayers(); // Maneira correta de invocar uma função assíncrona dentro do hook useEffect: de outra forma teremos erros de CORs
              }, 
              [availableKindsOfLayers]
           )

  return [availableLayers, setAvailableLayers]

}


const AvailableLayersProvider = ({ children }) => {  
  const [availableLayers, setAvailableLayers] = useAvailableLayersWithLocalStorage(availableLayersKeyForLocalStorage);

  return (
    <AvailableLayersContext.Provider value={{ availableLayers, setAvailableLayers }}>
      {children}
    </AvailableLayersContext.Provider>
  )
}

export default AvailableLayersProvider;