import React, { useState, useEffect } from 'react';
import DebugContext, {defaultDebug} from './DebugContext';

export const debugKeyForLocalStorage = '@geotab/debug'

export const useDebugWithLocalStorage = localStorageKey => {
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [debug, setDebug] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultDebug )

  useEffect( () => // Salva na localStorage apenas quando debug for alterado!
              {localStorage.setItem(localStorageKey, JSON.stringify( debug ) )}, 
              [debug]
           )

  return [debug, setDebug]
}

const DebugProvider = ({ children }) => {  
  const [debug, setDebug] = useDebugWithLocalStorage(debugKeyForLocalStorage);

  return (
    <DebugContext.Provider value={{ debug, setDebug }}>
      {children}
    </DebugContext.Provider>
  )
}

export default DebugProvider;