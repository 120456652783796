import React from 'react';
import * as estilos from './help/estilos'
import * as modalConstants from './context/ModalContext'
import logoGeoTC from './help/img/geotc.png'
import logoEMB from './help/img/emb.png'
import { CrudTable } from './lib/lib'
import * as lib from './lib/lib';

const Equipe = () => {
    
    const titleStyle = {
        color:lib.EMBRAPA_AZUL_COLOR,
        backgroundColor: '#DAE4E5',
        border: '1px solid #ccc',
        borderRadius: '0px 0px 0px 0px'
    }

    const colStyle = {
        backgroundColor: 'white',
        color: 'black',
        backgroundColor: '#FFF',
        border: '1px solid #ccc',
        textAlign: 'center'
    }

    return <CrudTable title={`EQUIPE RESPONSÁVEL PELO GEOTC`} style={{width:'100%'}} modalBtClose={modalConstants.modalCodeNone} >

<tr>
              <td>
                <table style={{width:'100%', height:'1px'}}>
                  <tr>
                    <td style={{textAlign:'left'}}>
                      <img style={{textAlign:'left', alignContent:'left' }} src={logoGeoTC} />
                    </td>                         
                    <td style={{textAlign:'right'}}>
                      <img style={{textAlign:'right', alignContent:'right' }} src={logoEMB} />                              
                    </td>                            
                  </tr>
                </table>                                            
              </td>              
            </tr>    




             <tr>
               <td>

               <div style={{height:'600px', width:'100%', padding:'20px'}}>   

                <html style={{width:'100%', textAlign:'center'}}> 
                             <body>
                                 <div style={estilos.tituloPagina}>Equipe</div>                                                                   
              
                                 <div style={{width:'100%', alignItems:'center'}}> 
              
                                     <table style={{width:'96%'}}>
                                         <thead>
                                             <tr>
                                                 <th style={titleStyle}>
                                                     NOME
                                                 </th>
                                                 <th style={titleStyle}>
                                                     FUNÇÃO
                                                 </th>                                   
                                                 <th style={titleStyle}>
                                                     INSTITUIÇÃO
                                                 </th>                               
                                                 <th style={titleStyle}>
                                                     E-MAIL
                                                 </th>                                    
                                             </tr>
                  
                                         </thead>
                                         <tbody>
                  
                                             <tr>
                                                 <td style={colStyle}>
                                                     Ademar Barros da Silva                                   
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos 
                                                 </td>               
                                                 <td style={colStyle}>                      
                                                     <a href={`mailto:ademar.barros@embrapa.br`}>ademar.barros@embrapa.br</a>
                                                 </td>                
                                             </tr>                

                                             <tr>
                                                 <td style={colStyle}>
                                                    Álex Fernando da Silva Santos
                                                </td>
                                                <td style={colStyle}>
                                                    Bolsista PIBIC
                                                </td>                                   
                                                <td style={colStyle}>
                                                    Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                </td>               
                                                <td style={colStyle}>                        
                                                    <a href={`mailto:alex.santos@gmail.com`}>alex.santos@gmail.com</a>
                                                </td>                
                                            </tr>                                                                                           
                  
                                             <tr>
                                                  <td style={colStyle}>
                                                     Alex Paulo Alves de Oliveira
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade, 
                                                     <br/>
                                                     Desenvolvedor do <i>Software GeoTC</i>
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>                        
                                                     <a href={`mailto:alex.oliveira@embrapa.br`}>alex.oliveira@embrapa.br</a>
                                                 </td>                
                                             </tr>               
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Alexandre Hugo Cezar Barros                                    
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade     
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:alexandre.barros@embrapa.br`}>alexandre.barros@embrapa.br</a>
                                                 </td>                
                                             </tr>               
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Ana Alexandrina Gama da Silva
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaboradora, Responsável por Plano de Ação, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:ana.gama­silva@embrapa.br`}>ana.gama­silva@embrapa.br</a>
                                                 </td>                
                                             </tr>       
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Andre Julio do Amaral
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:andre.amaral@embrapa.br`}>andre.amaral@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                                      
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Andreia Vieira Santos
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaboradora, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>                                       
                                                     <a href={`mailto:andreia.santos@embrapa.br`}>andreia.santos@embrapa.br</a>
                                                 </td>                
                                             </tr>                                      
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                    Antonio Carlos Barreto                                       
                                                 </td>
                                                 <td style={colStyle}>
                                                    Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>                                       
                                                     <a href={`mailto:antonio.barreto@embrapa.br`}>antonio.barreto@embrapa.br</a>
                                                 </td>                
                                             </tr>            
                                    
              
                                             <tr>
                                                 <td style={colStyle}>
                                                    Carlos Alberto da Silva                                       
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros                                       
                                                 </td>               
                                                 <td style={colStyle}>                                       
                                                     <a href={`mailto:carlos­alberto.silva@embrapa.br`}>carlos­alberto.silva@embrapa.br</a>
                                                 </td>                
                                             </tr>         
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Carlos Vinícius Rodrigues
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Plano de Ação, Responsável por Atividade                                       
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros                                       
                                                 </td>               
                                                 <td style={colStyle}>                                       
                                                     <a href={`mailto:vinicius.rodrigues@embrapa.br`}>vinicius.rodrigues@embrapa.br</a>
                                                 </td>                
                                             </tr>         
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Cynthia de Paula Silva Oliveira
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaboradora, Responsável por Atividade                                       
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros                                       
                                                 </td>               
                                                 <td style={colStyle}>                                       
                                                     <a href={`mailto:cynthia.oliveira@embrapa.br`}>cynthia.oliveira@embrapa.br</a>
                                                 </td>                
                                             </tr>                                                                                                                                                                                
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Davi de Oliveira Custódio                                       
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de Pesquisa de <br/> Monitoramento por Satelite
                                                 </td>               
                                                 <td style={colStyle}>                                       
                                                     <a href={`mailto:davi.custodio@embrapa.br`}>davi.custodio@embrapa.br</a>
                                                 </td>                
                                             </tr>                                      
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Davi Ferreira da Silva                                       
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:davi.ferreira@embrapa.br`}>davi.ferreira@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                                      
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Fernando Luis Dultra Cintra
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:fernando.cintra@embrapa.br`}>fernando.cintra@embrapa.br</a>
                                                 </td>                
                                             </tr>                                      
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Flávio Adriano Marques
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:flavio.marques@embrapa.br`}>flavio.marques@embrapa.br</a>                                                                              
                                                 </td>                
                                             </tr>                                      
                            
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Hilton Luis Ferraz da Silveira
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade                                       
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:hilton.ferraz@embrapa.br`}>hilton.ferraz@embrapa.br</a>
                                                 </td>                
                                             </tr>                                      
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Inajá F. de Sousa
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Universidade Federal de Sergipe
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:inajafrancisco@gmail.com`}>inajafrancisco@gmail.com</a>                                       
                                                 </td>                
                                             </tr>                                      
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     João Carlos Santos da Rocha
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Companhia de Desenvolvimento Industrial <br/> e de recursos minerais de Sergipe
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:joao.rocha@semarh.se.gov.br`}>joao.rocha@semarh.se.gov.br</a>
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     José Carlos Pereira dos Santos
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:josecarlos.santos@embrapa.br`}>josecarlos.santos@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     José Coelho de Araújo Filho
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Plano de Ação, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:jose.coelho@embrapa.br`}>jose.coelho@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Júlio Roberto Araújo de Amorim
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos                                       
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:julio.amorim@embrapa.br`}>julio.amorim@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     José Ivaldo Barbosa de Brito
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Universidade Federal de <br/> Campina Grande ­- UFCG
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:ivaldo@dca.ufcg.edu.br`}>ivaldo@dca.ufcg.edu.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Lauro Rodrigues Nogueira Júnior    
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:lauro.nogueira@embrapa.br`}>lauro.nogueira@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Lúcia Raquel Queiroz Pereira da Luz
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaboradora
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:lucia.luz@embrapa.br`}>lucia.luz@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Luciano Alves de Jesus Júnior
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:luciano.alves@embrapa.br`}>luciano.alves@embrapa.br</a>
                                                 </td>                
                                             </tr>                      

                                             <tr>
                                                 <td style={colStyle}>
                                                     Luciano José de Oliveira Accioly
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:luciano.accioly@embrapa.br`}>luciano.accioly@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Manoel Batista de Oliveira Neto
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:manoel.neto@embrapa.br`}>manoel.neto@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Manuel Alberto Gutierrez Cuenca
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade, Responsável por Plano de Ação
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:manuel.cuenca@embrapa.br`}>manuel.cuenca@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Marcelo Ferreira Fernandes    
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:marcelo.fernandes@embrapa.br`}>marcelo.fernandes@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Marcia Helena Galina Dompieri
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaboradora, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:marcia.dompieri@embrapa.br`}>marcia.dompieri@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Márcio Rogers Melo de Almeida                                       
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:rogers.melo@embrapa.br`}>rogers.melo@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Marcos Aurelio Santos da Silva    
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Plano de Ação, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:marcos.santos-­silva@embrapa.br`}>marcos.santos-­silva@embrapa.br</a>
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Marcus Aurelio Soares Cruz
                                                 </td>
                                                 <td style={colStyle}>
                                                     Idealizador e Líder do <i>GeoTC</i>, <br/> Responsável por Plano de Ação, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:marcus.cruz@embrapa.br`}>marcus.cruz@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Nicole Principe Carneiro da Silva
                                                 </td>
                                                 <td style={colStyle}>
                                                     Estagiária
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:nicoleprincipe@gmail.com`}>nicoleprincipe@gmail.com</a>
                                                 </td>                
                                             </tr>                                                        
                                                           
                                             <tr>
                                                 <td style={colStyle}>
                                                     Paulo Cardoso de Lima
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:paulocardoso.lima@embrapa.br`}>paulocardoso.lima@embrapa.br</a>  
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Roberto Alves de Souza
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:roberto.a.souza@embrapa.br`}>roberto.a.souza@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>                           
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Roberto da Boa Viagem Parahyba
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro Nacional de <br/> Pesquisa de Solos
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:roberto.parahyba@embrapa.br`}>roberto.parahyba@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Ronaldo Souza Resende
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:ronaldo.resende@embrapa.br`}>ronaldo.resende@embrapa.br</a>
                                                 </td>                
                                             </tr>
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Ricardo de Aragão
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Universidade Federal da Paraíba ­- Areia
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:ricardoaragao@yahoo.com`}>ricardoaragao@yahoo.com</a>                                       
                                                 </td>                
                                             </tr>
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Samuel Figueiredo de Souza
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:samuel.souza@embrapa.br`}>samuel.souza@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Samuel Silva da Mata
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:samuel.mata@embrapa.br`}>samuel.mata@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Saulo Coelho Nunes
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:saulo.coelho@embrapa.br`}>saulo.coelho@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Sonise dos Santos Medeiros
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:sonise.medeiros@embrapa.br`}>sonise.medeiros@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>
              
              
                                             <tr>
                                                 <td style={colStyle}>
                                                     Walane Maria Pereira de Mello Ivo    
                                                 </td>
                                                 <td style={colStyle}>
                                                     Colaborador, Responsável por Atividade
                                                 </td>                                   
                                                 <td style={colStyle}>
                                                     Centro de Pesquisa Agropecuária <br/> dos Tabuleiros Costeiros
                                                 </td>               
                                                 <td style={colStyle}>
                                                     <a href={`mailto:walane.ivo@embrapa.br`}>walane.ivo@embrapa.br</a>                                       
                                                 </td>                
                                             </tr>
                  
                                         </tbody>
                                     </table>
                                     <br/>
              
                                 </div>
                                    
                             </body>
                         </html>    
              
                  </div>

               </td>
             </tr>
           </CrudTable>
    

    

}

export default Equipe