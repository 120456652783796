import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figBtRemove from './img/bt_remove.png'
import figTentandoRemoverMaisDeUm from './img/msg_remove1.png'
import figConfirmRemove from './img/msg_remove.png'
import figRemoveInfo from './img/msg_remove_info.png'

const Page = () => {

    return <html>
               <body>
                   <div style={estilos.tituloPagina}>Remoção de <i>Shape</i></div>                    
                   
                   <br/>
                   <p>
                       A remoção de <i>shapes</i> estará disponível apenas para usuários autorizados.
                       Usuários com esta permissão poderão visualizar o ícone de remoção de <i>shapes</i> ( <img src={figBtRemove} />).
                       Se o usuário tentar clicar nesse ícone com mais de um <i>shape</i> selecionado, o sistema
                       exibirá a mensagem vista na Figura 1.
                   </p>
                   <br/>        
           
                   <figure>
                       <img style={estilos.imgStyle} src={figTentandoRemoverMaisDeUm} />                       
                       <figcaption style={estilos.figcaption}>Figura 1 - Tentando iniciar o processo de remoção com mais de um <i>shape</i> selecionado</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Considerando que apenas um <i>shape</i> esteja selecionado, ao clicar no botão 
                       ( <img src={figBtRemove} />) o usuário perceberá uma mensagem similar à que pode ser vista na Figura 2.
                   </p>
                   <br/>               
           
                   <figure>
                       <img style={estilos.imgStyle} src={figConfirmRemove} />                                                     
                       <figcaption style={estilos.figcaption}>Figura 2 - Confirmação de remoção de <i>shape</i></figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Se o usuário clicar no botão OK ( visto na Figura 2 ) o <i>shape</i> será removido.
                       E o sistema exibirá uma mensagem semelhante á que pode ser vista na Figura 3        
                   </p>
                   <br/>           
           
                   <figure>
                       <img style={estilos.imgStyle} src={figRemoveInfo} />                                          
                       <figcaption style={estilos.figcaption}>Figura 3 - Botão "Alterar Camada" desabilitado</figcaption>
                   </figure>      
                           
               </body>
           </html>

}

const HRemocaoShape = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HRemocaoShape