/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring';
// import AvailableKindsOfLayersContext from './context/AvailableKindsOfLayersContext'
import AvailableKindsOfLayersContext from '../context/AvailableKindsOfLayersContext'
// import * as cons from './generic/constants';
import * as lib from '../lib/lib';
import { FaPlusSquare, FaMinusSquare, FaMapMarkerAlt } from 'react-icons/fa'

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  label: {
    fontSize:10,
    fontStyle:'bold',
    fontWeight: 'inherit',
    color: lib.EMBRAPA_AZUL_COLOR,    
  },    
  group: {        
    marginLeft: 4,
    paddingLeft: 4,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} TransitionComponent={TransitionComponent} />);


const useStyles = makeStyles({
  root: {    
    flexGrow: 1,
    maxWidth: 400,
  },
});


export default function TreeViewCategory(props) {

  const classes = useStyles();

  const { availableKindsOfLayers } = useContext(AvailableKindsOfLayersContext)

  const [ categorias, setCategorias ] = useState(null)

  const [ internalLayerCategory, setInternalLayerCategory ] = useState(-1)

  useEffect(() => {

    // Return roots
    function roots(data){
      let res = []

      for (let pos = 0; pos < data.length; pos++){
        let node = data[pos]
        if (node.id_categoria_camada_pai === null){
          res.push(node)
        }        
      }

      return res
    }    

    // Return children from parent_node 
    function children(data, parent_node){
      let res = []

      for (let pos = 0; pos < data.length; pos++){
        let node = data[pos]
        if (node.id_categoria_camada_pai == parent_node.id_categoria_camada){
          res.push(node)
        }        
      }

      return res
    }


    function setLayerCategoryAndSetIdBase(id_categoria_camada_pai, id_categoria_camada, id_base_de_dados){

      // Exceção para ComumTAB (mesmo sendo raiz) ser considerado um nó válido para seleção na árvore
      // Entendendo que, PELA REGRA ADOTADA NO SISTEMA, nenhuma raiz da árvore poderia ser selecionada!!!
      // if (id_categoria_camada === 6){
      //   id_categoria_camada_pai = 999
      // }      

      let lc = id_categoria_camada_pai == null ? -1 : id_categoria_camada

      props.setLayerCategory( lc )      
      props.setIdBase( id_categoria_camada_pai == null ? -1 : id_base_de_dados )            
      setInternalLayerCategory( lc )
    }


    function printNode(data, node){     
            
      if (node === null)
        return null
      else {
        let childList = children(data, node)

        if (childList.length === 0)
          return <table key={node.id_categoria_camada}> 
                   <tbody>
                     <tr>                     
                       <td width='1px'>
                         <FaMapMarkerAlt color={ props.layerCategory !== node.id_categoria_camada ? 'white' : lib.EMBRAPA_VERDE_COLOR } /> 
                       </td>
                       <td>
                         <StyledTreeItem 
                                      nodeId={node.id_categoria_camada} 
                                      label={ node.tx_descricao  }  
                                      onClick={ () => { setLayerCategoryAndSetIdBase(node.id_categoria_camada_pai, node.id_categoria_camada, node.id_base_de_dados);  } }
                         />        
                       </td>
                     </tr>
                   </tbody>
                 </table>             
        else
          return <StyledTreeItem  
                                  nodeId={node.id_categoria_camada} 
                                  label={ node.tx_descricao  }  
                                  onClick={ () => { setLayerCategoryAndSetIdBase(node.id_categoria_camada_pai, node.id_categoria_camada, node.id_base_de_dados);  } }
                 >              
                   { childList.map( (child) => printNode(data, child) ) }
                 </StyledTreeItem>  
                 
      }
    }        

    // Print all
    function print(data){
      let res = []
      let rts = roots(data)

      for (let pos = 0; pos < rts.length; pos++){
        let root_node = rts[pos]
        res.push( printNode(data, root_node) )
      }

      return res
    }
      
    
    setCategorias(print(availableKindsOfLayers))        
  }, [internalLayerCategory] );  


  return (

    <TreeView
        className={classes.root}      
        defaultExpanded={['1']}
        defaultCollapseIcon={<FaMinusSquare color={lib.EMBRAPA_AZUL_COLOR} />}
        defaultExpandIcon={<FaPlusSquare color={lib.EMBRAPA_AZUL_COLOR} />}
        defaultEndIcon={<FaPlusSquare color='transparent' />}
      >

      {categorias}
      
    </TreeView>

  );
}