import { createContext } from 'react'

export const defaultSelectedIdsContext = []
                                  
const SelectedIdsContext = createContext( 
  {
    selectedIds:defaultSelectedIdsContext, 
    setSelectedIdsContext: () => {}
  }
                                                 ) 

export default SelectedIdsContext