import React, { useState, useContext, useEffect } from 'react';
import LoggedUserContext from './LoggedUserContext';
import AvailableLayersContext from './AvailableLayersContext';
import TreeViewContext, {defaultTreeView} from './TreeViewContext';
import axios from "axios";
import * as env from '../.env.js'

export const treeViewKeyForLocalStorage = '@geotab/treeView'

export const useTreeViewWithLocalStorage = localStorageKey => {

  const { loggedUser } = useContext(LoggedUserContext)

  const { availableLayers } = useContext(AvailableLayersContext)
 
  const perfil = loggedUser.id_perfil

  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [treeView, setTreeView] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultTreeView )


  async function UpdateTreeView() {

    let address = env.GEOTABREST_TREEVIEWDATA + '/' + perfil

    await axios.get( address )
    .then(res => {
      
      setTreeView( res.data.data )
      localStorage.setItem( localStorageKey, JSON.stringify( res.data.data ) )
      
    })    
    .catch(function (error) {
      console.log(error);                  
    })     

  }


  useEffect( () => // Salva na localStorage apenas quando availableLayers for alterado!
              {
                UpdateTreeView(); // Maneira correta de invocar uma função assíncrona dentro do hook useEffect: de outra forma teremos erros de CORs
              }, 
              [availableLayers]
           )


  return [treeView, setTreeView]
}


const TreeViewProvider = ({ children }) => {  
  const [treeView, setTreeView] = useTreeViewWithLocalStorage(treeViewKeyForLocalStorage);

  return (
    <TreeViewContext.Provider value={{ treeView, setTreeView }}>
      {children}
    </TreeViewContext.Provider>
  )
}

export default TreeViewProvider;