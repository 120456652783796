import React, { useContext } from "react";
import Table from './../components/table'
import * as env from './../.env.js'
import {  CrudTable } from './../lib/lib'

export default function CategoriaIndex(props) {
  
  // SELECT id_categoria_camada, desc_camada, id_categoria_camada_pai, desc_camada_pai, id_base_de_dados, desc_base, in_order FROM gtb.vw_categoria_camada 

  const columns = [
    {
      left: true,
      name: 'Base',
      selector: 'desc_base',
      width: '60px',
      sortable: true,
    },    
    {
      left: true,
      name: 'Categoria Pai',
      selector: 'desc_camada_pai',
      width: '200px',
      sortable: true,
    },    
    {
      left: true,
      name: 'Categoria',
      selector: 'desc_camada',
      width: '200px',
      sortable: true,      
    }
  ];    

  return <CrudTable title='CATEGORIA'>
           <tbody>
             <tr align='center' height='40'>             
               <td colSpan='2'>
                 <Table 
                   rest={env.GEOTABREST_CATEGORIA_CAMADA_SEM_RAIZES} 
                   rest_to_insert={env.GEOTABREST_CRUD_CATEGORIA_CAMADA}
                   rest_to_edit={env.GEOTABREST_CRUD_CATEGORIA_CAMADA}
                   rest_to_delete={env.GEOTABREST_CRUD_CATEGORIA_CAMADA}
                   table='categoria_camada'
                   pk='id_categoria_camada'
                   columns={columns}                    
                   controlInsert={true} 
                   controlEdit={true} 
                   controlDelete={true} 
                 />
               </td>
             </tr>                                                               
           </tbody>
         </CrudTable>
}