import React, { useState, useContext, useEffect } from "react";
import SelectedIdsContext from "./SelectedIdsContext";
import SelectedAncestorNodeContext from "./SelectedAncestorNodeContext";
import TreeViewContext from './TreeViewContext'
import ExpandedIdsContext, {defaultExpandedIds} from "./ExpandedIdsContext";

export const ExpandedIdsKeyForLocalStorage = '@geotc/expandedIds'

export const useExpandedIdsWithLocalStorage = localStorageKey => {

  const { treeView } = useContext(TreeViewContext)

  const { selectedIds } = useContext(SelectedIdsContext)

  const { selectedAncestorNode } = useContext( SelectedAncestorNodeContext )

 
  function ancestors(id) {

    function node_with_id(id){
      let node = null

      for (let i = 0; i < treeView.length; i++) 
        if (treeView[i].id === id) {
          node = treeView[i]
          break
        }              

      return node
    }

    let answer = []
    let node = node_with_id(id) // Primeiro node, não deve entrar na resposta: apenas os ancestrais
    if (node != null)
      while (node.id_pai != null){      
        node = node_with_id(node.id_pai)
        answer.push(node.id)    
      }
    
    return answer
  }  

  function allAncestors(){
    let answer = []
    for (let i = 0; i < selectedIds.length; i++) {
      let my_ancestors = ancestors(selectedIds[i])
      for (let j = 0; j < my_ancestors.length; j++)
        if (answer.indexOf(my_ancestors[j]) == -1)
          answer.push(my_ancestors[j])
    }
          
    return answer
  }


  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [expandedIds, setExpandedIds] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultExpandedIds )

  useEffect( () => // Salva na localStorage apenas quando ExpandedIds for alterado!
              { 
                let newExpandedIds = allAncestors()
                for (let i = 0; i < selectedAncestorNode.length; i++)
                  if (newExpandedIds.indexOf(selectedAncestorNode[i]) == -1)
                    newExpandedIds.push(selectedAncestorNode[i])                

                setExpandedIds( newExpandedIds )
                localStorage.setItem(localStorageKey, JSON.stringify( newExpandedIds ) )
              }, 
              [selectedIds, selectedAncestorNode]
           )

  return [expandedIds, setExpandedIds]
}

const ExpandedIdsProvider = ({ children }) => {  
  const [expandedIds, setExpandedIds] = useExpandedIdsWithLocalStorage(ExpandedIdsKeyForLocalStorage);

  return (
    <ExpandedIdsContext.Provider value={{ expandedIds, setExpandedIds }}>
      {children}
    </ExpandedIdsContext.Provider>
  )
}

export default ExpandedIdsProvider;