import { createContext } from 'react'

export const defaultAvailableKindsOfLayers = {}
                                  
const AvailableKindsOfLayersContext = createContext( 
  {
    availableKindsOfLayers:defaultAvailableKindsOfLayers, 
    setAvailableKindsOfLayersContext: () => {}
  }
) 

export default AvailableKindsOfLayersContext