import React, { useContext } from 'react';
import Wrapper from './wrapper'
import ModalContext from './../context/ModalContext'
import * as modalConstants from './../context/ModalContext'
import * as estilos from './estilos'
import figMenuAdm from './img/menuadm.png'
import figCRUDBtAdd from './img/bt_crud_add.png'
import figCRUDBtUpdate from './img/bt_crud_upd.png'
import figCRUDBtDelete from './img/bt_crud_del.png'
import figCRUDUsuario from './img/crud_usuario.png'
import figCRUDUsuarioInsert from './img/crud_usuario_insert.png'
import figCRUDUsuarioUpdate from './img/crud_usuario_update.png'
import figMsgExcluirRegistro from './img/msg_excluir_registro.png'
import figBtRecuperarUsuario from './img/bt_crud_recuperar_usuario.png'


const Page = () => {

    const { setModal } = useContext(ModalContext)

    return <html>
    
    <body>
        <div style={estilos.tituloPagina}>Gerenciamento de Usuário</div>
    
        <br/>
        <p>
            Para controlar os usuários do sistema é necessário ter um perfil de gestor.
            Considere o menu visto na Figura 1.
        </p>
        <br/>
    
        <figure>
            <img style={estilos.imgStyle} src={figMenuAdm} />
            <figcaption style={estilos.figcaption}>Figura 1 - Menu de Administração</figcaption>
        </figure>
    
        <br/>
        <p>
            Neste menu, clique na opção <i>Usuário</i>,
            e o sistema exibirá a tela vista na Figura 2
        </p>
        <br/>
    
        <figure>
            <img style={estilos.imgStyle} src={figCRUDUsuario} />
            <figcaption style={estilos.figcaption}>Figura 2 - Usuário</figcaption>
        </figure>
    
        <br/>
        <p>        
            Na Figura 2 é possível visualizar um botão diferente dos botões de todas as outras telas de gerenciamento de dados:
             o botão Recuperar ( <img src={figBtRecuperarUsuario} /> ).
            Se o gestor clicar nesse botão, nenhuma mensagem será exibida, entretanto, aquele usuário
            não conseguirá logar novamente no sistema, enquanto não executar todo o procedimento de recuperação de senha,
            descrito <b>à partir da Figura 4</b> da 
            <a hlink='' style={{color:'blue', cursor:'pointer'}} onClick={ () => { setModal( { code: modalConstants.modalCodeHCRecuperacaoSenha } ) } }>  sessão que descreve como recuperar senha</a>. 



        </p>    
    
        <br/>
        <p>
            Ainda na tela em questão ( Figura 2 ) é possível realizar todas as operações de manutenção de dados:
            visualização, inserção, atualização e remoção.
            <br/>
            Para realizar uma inserção, clique no botão <img src={figCRUDBtAdd} />,
            e o sistema exibirá a tela vista na Figura 3.
        </p>
        <br/>
    
        <figure>
            <img style={estilos.imgStyle} src={figCRUDUsuarioInsert} />            
            <figcaption style={estilos.figcaption}>Figura 3 - Inserção de Usuário</figcaption>
        </figure>
    
        <br/>
        <p>
            Para realizar uma atualização, clique no botão <img src={figCRUDBtUpdate} />,
            e o sistema exibirá a tela vista na Figura 4.
        </p>
        <br/>
    
        <figure>
            <img style={estilos.imgStyle} src={figCRUDUsuarioUpdate} />            
            <figcaption style={estilos.figcaption}>Figura 4 - Atualização de Usuário</figcaption>
        </figure>
    
    
        <br/>
        <p>
            Para realizar uma remoção, clique no botão <img src={figCRUDBtDelete} />,
            e o sistema exibirá a mensagem vista na Figura 5.
        </p>
        <br/>
    
        <figure>
            <img src={figMsgExcluirRegistro} />
            <figcaption style={estilos.figcaption}>Figura 5 - Confirmação de exclusão</figcaption>
        </figure>
    
        <br/>
        <p>
            Se o usuário clicar no botão <i>OK</i> ( da tela de confirmação exibida na Figura 5 ) 
            e existir algum empedimento para a remoção do registro selecionado, então, o sistema
            dará uma mensagem objetiva explicando o motivo de não conseguir remover.
        </p>
        <br/>
    </body>
    
    </html>
}

const HGerenciamentoUsuario = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HGerenciamentoUsuario