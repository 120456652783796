import React, { useState, useContext } from 'react';
import axios from 'axios'
import { CrudBtVermelho, CrudBtVermelhoPequeno } from './../lib/lib'
import * as lib from './../lib/lib';
import * as env from './../.env';
import * as modalConstants from './../context/ModalContext'
import SelectedAncestorNodeContext from "./../context/SelectedAncestorNodeContext";
import SelectedIdsContext from './../context/SelectedIdsContext'
import FlagUpdateTreeContext from './../context/FlagUpdateTreeContext'
import TreeViewContext from './../context/TreeViewContext'
import ModalContext, { modalObjectDefault } from './../context/ModalContext'
import LoggedUserContext, {defaultLoggedUser} from './../context/LoggedUserContext'
import { availableKindsOfLayersKeyForLocalStorage } from './../context/AvailableKindsOfLayersProvider'
import SelectedLayersContext, {defaultSelectedLayersContext} from './../context/SelectedLayersContext'
import AvailableLayersContext from './../context/AvailableLayersContext'
import { headerKeyForLocalStorage } from './../context/HeaderProvider'
import { Dropdown } from 'semantic-ui-react'
import { IoMdCloseCircle } from 'react-icons/io'
import { ImArrowDown, ImStatsBars2 } from 'react-icons/im'
import { FaMap, FaUsers, FaPen, FaPlusCircle, FaMinusCircle, FaInfo, FaPlus, FaMinus, FaUser, FaCog, FaListUl } from 'react-icons/fa'
import { MdSecurity, MdHelp } from 'react-icons/md'
import { RiArrowUpDownFill, RiGroup2Fill } from 'react-icons/ri'
import {treeViewKeyForLocalStorage} from  './../context/TreeViewProvider'
import { useMediaQuery } from 'react-responsive'
import { result } from 'validate.js';


const bs = lib.BUTTON_SIZE  // Button Size

export default function Nav(props) {

  const { selectedIds } = useContext(SelectedIdsContext)

  const { availableLayers } = useContext(AvailableLayersContext) // Capturando do contexto  

  const { setSelectedLayers } = useContext(SelectedLayersContext) // Capturando do contexto

  const { loggedUser, setLoggedUser } = useContext(LoggedUserContext) // Capturando do contexto  

  const { setModal } = useContext(ModalContext) // Capturando do contexto

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const navStyleTopBar = { // IMPORTANTE: height ( no objeto literal abaixo ) é a altura da barra de navegação (com logotipos e botões)
    margin: 0,
    backgroundColor: '#fff',
    verticalAlign: 'middle',
    height: '60px', // old ==> height: '70px',   
    borderCollapse: 'collapse',    
    // backgroundImage:`url(${backImg})`
  };

  const navStyleBottomBar = { // IMPORTANTE: height ( no objeto literal abaixo ) é a altura da barra de navegação (com logotipos e botões)
    margin: 0,
    backgroundColor: '#f8f9fa',    
    backgroundColor: '#F2F8FD', // Boa
    backgroundColor: '#F1F2F2',
    verticalAlign: 'middle',
    height: '30px', // old ==> height: '59px',   
    borderCollapse: 'collapse',
  };


  const ulStyle = {
    margin: '0px',
    padding: '2px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    verticalAlign: 'middle'
  };  

  const liStyle = {
    padding: '0px 1px',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    verticalAlign: 'baseline',
  };    

  const imgStyle = {
    padding: '0px 0px',    
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0px',
    verticalAlign: 'baseline',
  };      


  function doLogoff() {
    setLoggedUser( defaultLoggedUser )
    localStorage.removeItem(availableKindsOfLayersKeyForLocalStorage)
    setSelectedLayers( defaultSelectedLayersContext )
    localStorage.removeItem(headerKeyForLocalStorage)
  }    

  // ------------------------------------------------------------------------------------------------------------------



  function ButtonBar(props) { 
    
    const { setModal } = useContext(ModalContext) // Capturando do contexto
  
    const { loggedUser } = useContext(LoggedUserContext) 
  
    const [ isRemovingLayer, setIsRemovingLayer ] = useState(false);   
  
    const { selectedLayers, setSelectedLayers } = useContext(SelectedLayersContext) 
  
    const { setSelectedAncestorNode } = useContext( SelectedAncestorNodeContext )
    
    const { flagUpdateTree, setFlagUpdateTree } = useContext(FlagUpdateTreeContext)
  
    const { treeView, setTreeView } = useContext(TreeViewContext)
    
    async function updateTreeView(){ // IMPORTANTE: A TreeView Principal da aplicação (da tela inicial) é montada aqui!!
  
      const perfil = loggedUser.id_perfil
  
      const address = env.GEOTABREST_TREEVIEWDATA + '/' + perfil
  
      await axios.get( address )
      .then(res => {
                
        localStorage.setItem( treeViewKeyForLocalStorage, JSON.stringify( res.data.data ) )
        setTreeView( res.data.data )
  
      })    
      .catch(function (error) {
        console.log(error);                  
      })             
    }    
  

    //  ------------------------------------------ BUTTONS - INÍCIO ------------------------------------------

    // Pela lógica atual, apenas um usuário com perfil de "Administrador Geral" conseguirá visualizar esse controle
    function drawButtonConfig(){ 
      
      // É livre a adição de campos nos objetos do vetor abaixo, de acordo com a necesidade: modal não existia, eu acrescentei
      const menuOptions = [
        {
          key: 'cat',
          text: 'Categorias',
          title: 'CATEGORIAS DAS CAMADAS',
          image: <FaListUl size='20' style={{ marginRight: 7 }} color='orange'/>,
          modal: modalConstants.modalCodeCategoriaIndex
        },      
        {
          key: 'perf',
          text: 'Perfil',
          title: 'PERFIL PARA USUÁRIO DO GEOTC',
          image: <FaUsers size='20' style={{ marginRight: 7 }} color={lib.EMBRAPA_AZUL_COLOR}/>,
          modal: modalConstants.modalCodePerfilIndex
        }, 
        {
          key: 'user',
          text: 'Usuário',
          title: 'USUÁRIO DO GEOTC',
          image: <FaUser size='20' style={{ marginRight: 7 }} color={lib.EMBRAPA_VERDE_COLOR}/>,
          modal: modalConstants.modalCodeUsuarioIndex
        },       
        {
          key: 'perm',
          text: 'Permissões',
          title: 'PERMISSÕES DO SISTEMA',
          image: <MdSecurity size='20' style={{ marginRight: 7 }}  color={lib.BOTAO_FECHAR_COLOR}/> ,
          modal: modalConstants.modalCodePermissaoIndex
        },       
        {
          key: 'ord',
          text: 'Ordem',
          title: 'ORDEM DE APRESENTAÇÃO DE DATABASES, CAMADAS E MAPAS',
          image: <RiArrowUpDownFill size='20' style={{ marginRight: 7 }}  color={lib.EMBRAPA_VERDE_COLOR}/> ,
          modal: modalConstants.modalCodeOrdemIndex
        },       
        {
          key: 'stats',
          text: 'Estatísticas',
          title: 'ESTATÍSTICAS DO GEOTC',
          image: <ImStatsBars2 size='20' style={{ marginRight: 7 }}  color='#9A76B3'/> ,
          modal: modalConstants.modalCodeStats
        }      
      ]
  
      

      
      
      const DropdownConfig = () => (
        <Dropdown
          // text=''        
          icon={<FaCog size='20' title='ADMINISTRAÇÃO DO GEOTC' color={lib.EMBRAPA_AZUL_COLOR}/>}
          // floating
          // labeled
          // button
          className='icon'
        >
          <Dropdown.Menu>
            <Dropdown.Header content='ADMINISTRAÇÃO' />
            {menuOptions.map((option, i) => (
  
              <Dropdown.Item key={i}  {...option}  onClick={ () => setModal( { code: option.modal } ) } />
  
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )      
      
      if (!loggedUser) 
        return null
      else 
        if (loggedUser.id_perfil !== 1)
          return null
        else
          return <DropdownConfig/>
    }        


    // Pela lógica atual, basta estar logado para visualizar esse controle
    function drawButtonExpand(){ 
      function expandTree(){
        let bases_e_camadas = []
        for (let i = 0; i < treeView.length; i++)
          if ( (treeView[i].tipo == 'base') || (treeView[i].tipo == 'camada') )
            bases_e_camadas.push(treeView[i].id)
  
        setSelectedAncestorNode( bases_e_camadas ) // Abre todos os nós do tipo base ou camada
        updateTreeView()
      }    
  
      if (!loggedUser) 
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else               
          return <a onClick={() => expandTree() }>
                   <FaPlusCircle  size='15' color={lib.EMBRAPA_AZUL_COLOR} title='EXPANDIR' />               
                 </a>         
    }  
  
  
    // Pela lógica atual, basta estar logado para visualizar esse controle
    function drawButtonCollapse(){ 
      
      function collapseTree(){      
        setSelectedLayers([])
        updateTreeView()      
        setSelectedAncestorNode([])        
      }
      
      if (!loggedUser) 
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else               
          return <a onClick={() => collapseTree() }>
                   <FaMinusCircle  size='15' color={lib.EMBRAPA_AZUL_COLOR} title='COLAPSAR' />   
                 </a>         
    }        


  

    // Pela lógica atual, basta estar logado para visualizar esse controle
    function drawButtonClose(){ 
      function closeMaps(){
  
        if (selectedLayers.length === 0)
          alert('Nenhuma camada selecionada!')
        else{
          setSelectedLayers([])
          updateTreeView()
        }
      }    
      
      if (!loggedUser)   
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else               
          return <a onClick={() => closeMaps() }>
                   <table>
                       <tr>
                         <td>
                           <FaMap  size='17' color='#ccc' title='FECHAR' />     
                         </td>                    
                         <td>
                           <IoMdCloseCircle size='12' color='#ccc' title='FECHAR'/>
                         </td> 
                       </tr>
                     </table>                                           
                 </a>         
    }      
  
  
    // Pela lógica atual, basta estar logado para visualizar esse controle
    function drawButtonInfo(){ 
      
      function viewLayerDetails(){    
        if (selectedLayers.length < 1)
          alert('Nenhuma camada selecionada!')
        else if (selectedLayers.length > 1)
          alert('Para visualizar os detalhes, selecione apenas uma camada!')
        else       
          setModal( { code: modalConstants.modalCodeMapDetail, params: {} } )
      }

      if (!loggedUser)   
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else       
          return <a onClick={ () => viewLayerDetails() }>
                   <table>
                       <tr>
                         <td>
                           <FaMap  size='17' color='#AFCFEA' title='DETALHES' />
                         </td>                    
                         <td>
                           <FaInfo size='10' color='#AFCFEA' title='DETALHES' />
                         </td> 
                       </tr>
                     </table>                                           
                 </a> 
    }       



  
    // Pela lógica atual, o usuário só visualizará esse controle se estiver logado, SE possuir permissão para baixar layers em pelo menos um database, e, SE 
    // (
    //    a permissão de baixar coincide com a de visualizar camadas privadas
    //    OU
    //    se a permissão de baixar coincide com a de visualizar camadas públicas
    // )
    function drawButtonDownload(){     

      function possuiPermissaoParaDownload(){
               
        if ( loggedUser.dbs_perm_bai.length > 0 ) { // Se Possui permissão para Download de camadas em, pelo menos, um database...          
          
          let arrayDatabasesComPermissaoParaDownload                  = loggedUser.dbs_perm_bai.split('@')
          let arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas = loggedUser.dbs_perm_vpv.split('@')
          let arrayDatabasesComPermissaoParaVisualizarCamadasPublicas = loggedUser.dbs_perm_vis.split('@')
                    
          for (let i = 0; i < arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas.length; i++) // Se a permissão de adicionar coincide com a de visualizar camadas privadas
            if ( arrayDatabasesComPermissaoParaDownload.includes(arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas[i]) )
              return true
      
          for (let i = 0; i < arrayDatabasesComPermissaoParaVisualizarCamadasPublicas.length; i++) // Se a permissão de adicionar coincide com a de visualizar camadas públicas
            if ( arrayDatabasesComPermissaoParaDownload.includes(arrayDatabasesComPermissaoParaVisualizarCamadasPublicas[i]) )
              return true          
        } 
      
        return false
      }            


  
      function download(){
  
        if (selectedLayers.length < 1)
          alert('Nenhuma camada selecionada!')
        else if (selectedLayers.length > 1)
          alert('Para fazer o download selecione apenas uma camada!')
        else {

          // Capturando idBase de availableLayers
          // ----------------------------------------------
          let idBase
          let layers = availableLayers.layers
          for ( let i = 0; i < layers.length; i++ )
            if (  'm'.concat(layers[i].id_camada) == selectedIds[0] ) {
              idBase = layers[i].id_base_de_dados
              break
            }
          // ----------------------------------------------          

          let arrayDatabasesComPermissaoParaDownload = loggedUser.dbs_perm_bai.split('@')

          if ( !arrayDatabasesComPermissaoParaDownload.includes( idBase.toString() ) )  // Verificando se o usuário tem permissão para editar
          
            // Explicando as permissões que ele possui
            // --------------------------------------------------------------------------------
            axios.get(env.GEOTABREST_DATABASES_POR_PERFIL_E_ACAO + '/' + loggedUser.id_perfil + '/' + lib.ACAO_BAIXAR_CAMADAS)
            .then(res => {                      //  /databases__por_perfil_e_acao/:id_perfil/:id_acao    
              let msg = 'Seu usuário não possui permissão para baixar camadas desse database! Possui permissão para baixar do(s) seguinte(s) database(s): \r\n \r\n'
              
              const databases = res.data.data
              
              databases.forEach(database => {
                msg += ' - ' + database.base_desc + '\r\n'          
              });  
        
              msg += '\r\n Se um dos databases citados não aparece na tela, lhe deram permissão para download, e esqueceram de dar permissão para visualizar!' 
        
              alert(msg)        
            })
            .catch(function (error) {
              console.log(error);
            })     
            // --------------------------------------------------------------------------------                
          else if (window.confirm( `Fazer download da camada "${selectedLayers[0]}"?`)) {  
            let url_to_download = env.GEOSERVER_SERVICE + `/geoserver/geotab/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=geotab%3A${selectedLayers[0]}&maxFeatures=5000&outputFormat=SHAPE-ZIP`  
            window.open(url_to_download, '_blank');      
          }                 
        }
      }    
  
      if (!loggedUser)   
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else            
          if (!possuiPermissaoParaDownload())
            return null
          else          
            return <a onClick={() => download() }>
                     <table>
                       <tr>
                         <td>
                           <FaMap  size='17' color={lib.EMBRAPA_AZUL_COLOR} title='DOWNLOAD' />
                         </td>                    
                         <td>
                           <ImArrowDown size='10' color={lib.EMBRAPA_AZUL_COLOR} title='DOWNLOAD' />
                         </td> 
                       </tr>
                     </table>                                               
                   </a>         
    }


  

    // Pela lógica atual, o usuário só visualizará esse controle se estiver logado, SE possuir permissão para adicionar layers em pelo menos um database, e, SE 
    // (
    //    a permissão de adicionar coincide com a de visualizar camadas privadas
    //    OU
    //    se a permissão de adicionar coincide com a de visualizar camadas públicas
    // )
    function drawButtonLayerAdd(){ // Visibilidade em função do perfil

      function possuiPermissaoParaAdicionarCamadas(){
         
        if ( loggedUser.dbs_perm_inc.length > 0 ) { // Se Possui permissão para adicionar camadas em, pelo menos, um database...          
          
          let arrayDatabasesComPermissaoParaIncluir = loggedUser.dbs_perm_inc.split('@')
          let arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas = loggedUser.dbs_perm_vpv.split('@')
          let arrayDatabasesComPermissaoParaVisualizarCamadasPublicas = loggedUser.dbs_perm_vis.split('@')
                    
          for (let i = 0; i < arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas.length; i++) // Se a permissão de adicionar coincide com a de visualizar camadas privadas
            if ( arrayDatabasesComPermissaoParaIncluir.includes(arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas[i]) )
              return true

          for (let i = 0; i < arrayDatabasesComPermissaoParaVisualizarCamadasPublicas.length; i++) // Se a permissão de adicionar coincide com a de visualizar camadas públicas
            if ( arrayDatabasesComPermissaoParaIncluir.includes(arrayDatabasesComPermissaoParaVisualizarCamadasPublicas[i]) )
              return true          
        } 

        return false
      }            


      if (!loggedUser)   
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else            
          if (!possuiPermissaoParaAdicionarCamadas())
            return null
          else  
            return <a onClick={() => {setModal( { code: modalConstants.modalCodeUpload } )}}>
                     <table>
                       <tr>
                         <td>
                           <FaMap  size='17' color='#378254' title='INCLUIR' />
                         </td>                    
                         <td>
                           <FaPlus size='10' color='#378254' title='INCLUIR' />
                         </td> 
                       </tr>
                     </table>                              
                   </a>         
    }
  


    // Pela lógica atual, o usuário só visualizará esse controle se estiver logado, SE possuir permissão para alterar layers em pelo menos um database, e, SE 
    // (
    //    a permissão de alterar coincide com a de visualizar camadas privadas
    //    OU
    //    se a permissão de alterar coincide com a de visualizar camadas públicas
    // )
    function drawButtonLayerEdit(){ 

      function possuiPermissaoParaAlterarCamadas(){
               
        if ( loggedUser.dbs_perm_alt.length > 0 ) { // Se Possui permissão para adicionar camadas em, pelo menos, um database...          
          
          let arrayDatabasesComPermissaoParaAlterar                   = loggedUser.dbs_perm_alt.split('@')
          let arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas = loggedUser.dbs_perm_vpv.split('@')
          let arrayDatabasesComPermissaoParaVisualizarCamadasPublicas = loggedUser.dbs_perm_vis.split('@')
                    
          for (let i = 0; i < arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas.length; i++) // Se a permissão de adicionar coincide com a de visualizar camadas privadas
            if ( arrayDatabasesComPermissaoParaAlterar.includes(arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas[i]) )
              return true
      
          for (let i = 0; i < arrayDatabasesComPermissaoParaVisualizarCamadasPublicas.length; i++) // Se a permissão de adicionar coincide com a de visualizar camadas públicas
            if ( arrayDatabasesComPermissaoParaAlterar.includes(arrayDatabasesComPermissaoParaVisualizarCamadasPublicas[i]) )
              return true          
        } 
      
        return false
      }            
      
  
      function alterLayer(){    
        if (selectedLayers.length < 1)
          alert('Nenhuma camada selecionada!')
        else if (selectedLayers.length > 1)
          alert('Para alterar, selecione apenas uma camada!')
        else {
        
          // Capturando idBase de availableLayers
          // ----------------------------------------------
          let idBase
          let layers = availableLayers.layers
          for ( let i = 0; i < layers.length; i++ )
            if (  'm'.concat(layers[i].id_camada) == selectedIds[0] ) {
              idBase = layers[i].id_base_de_dados
              break
            }
          // ----------------------------------------------

          let arrayDatabasesComPermissaoParaAlterar = loggedUser.dbs_perm_alt.split('@')

          if ( !arrayDatabasesComPermissaoParaAlterar.includes( idBase.toString() ) )  // Verificando se o usuário tem permissão para editar
          
            // Explicando as permissões que ele possui
            // --------------------------------------------------------------------------------
            axios.get(env.GEOTABREST_DATABASES_POR_PERFIL_E_ACAO + '/' + loggedUser.id_perfil + '/' + lib.ACAO_ALTERAR_CAMADAS)
            .then(res => {                      //  /databases__por_perfil_e_acao/:id_perfil/:id_acao    
              let msg = 'Seu usuário não possui permissão para alterar esse database! Possui permissão para alterar o(s) seguinte(s) database(s): \r\n \r\n'
              
              const databases = res.data.data
              
              databases.forEach(database => {
                msg += ' - ' + database.base_desc + '\r\n'          
              });  
        
              msg += '\r\n Se um dos databases citados não aparece na tela, lhe deram permissão para alterar, e esqueceram de dar permissão para visualizar!' 
        
              alert(msg)        
            })
            .catch(function (error) {
              console.log(error);
            })          
            // --------------------------------------------------------------------------------
          else          
            setModal( { code: modalConstants.modalCodeEditMap } )  // Liberando para edição

        }
      }
  

      if (!loggedUser)   
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else            
          if (!possuiPermissaoParaAlterarCamadas())
            return null
          else        
            return <a onClick={() => alterLayer() }>    
                     <table>
                       <tr>
                         <td>
                           <FaMap  size='17' color='#FED876' title='ALTERAR' />
                         </td>                    
                         <td>
                           <FaPen size='10' color='#FED876' title='ALTERAR' />
                         </td> 
                       </tr>
                     </table>          
                   </a> 
    }  
  




    // Pela lógica atual, o usuário só visualizará esse controle se estiver logado, SE possuir permissão para excluir layers em pelo menos um database, e, SE 
    // (
    //    a permissão de excluir coincide com a de visualizar camadas privadas
    //    OU
    //    se a permissão de excluir coincide com a de visualizar camadas públicas
    // )
    function drawButtonLayerRemove(){ 
  
      function possuiPermissaoParaRemover(){
               
        if ( loggedUser.dbs_perm_exc.length > 0 ) { // Se Possui permissão para Excluir em, pelo menos, um database...          
          
          let arrayDatabasesComPermissaoParaExcluir                   = loggedUser.dbs_perm_exc.split('@')
          let arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas = loggedUser.dbs_perm_vpv.split('@')
          let arrayDatabasesComPermissaoParaVisualizarCamadasPublicas = loggedUser.dbs_perm_vis.split('@')
                    
          for (let i = 0; i < arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas.length; i++) // Se a permissão de adicionar coincide com a de visualizar camadas privadas
            if ( arrayDatabasesComPermissaoParaExcluir.includes(arrayDatabasesComPermissaoParaVisualizarCamadasPrivadas[i]) )
              return true
      
          for (let i = 0; i < arrayDatabasesComPermissaoParaVisualizarCamadasPublicas.length; i++) // Se a permissão de adicionar coincide com a de visualizar camadas públicas
            if ( arrayDatabasesComPermissaoParaExcluir.includes(arrayDatabasesComPermissaoParaVisualizarCamadasPublicas[i]) )
              return true          
        } 
      
        return false
      } 


  
      function removeLayer(){
  
        if (selectedLayers.length < 1)
          alert('Nenhuma camada selecionada!')
        else if (selectedLayers.length > 1)
          alert('Para excluir, selecione apenas uma camada!')
        else {

          // Capturando idBase de availableLayers
          // ----------------------------------------------
          let idBase
          let layers = availableLayers.layers
          for ( let i = 0; i < layers.length; i++ )
            if (  'm'.concat(layers[i].id_camada) == selectedIds[0] ) {
              idBase = layers[i].id_base_de_dados
              break
            }
          // ----------------------------------------------       

          let arrayDatabasesComPermissaoParaExcluir = loggedUser.dbs_perm_exc.split('@')           

          if ( !arrayDatabasesComPermissaoParaExcluir.includes( idBase.toString() ) )  // Verificando se o usuário tem permissão para excluir
                                  
            axios.get(env.GEOTABREST_DATABASES_POR_PERFIL_E_ACAO + '/' + loggedUser.id_perfil + '/' + lib.ACAO_EXCLUIR_CAMADAS)    // Explicando as permissões que ele possui
            .then(res => {                      //  /databases__por_perfil_e_acao/:id_perfil/:id_acao    
              let msg = 'Seu usuário não possui permissão para excluir camadas desse database! Possui permissão para excluir do(s) seguinte(s) database(s): \r\n \r\n'
              
              const databases = res.data.data
              
              databases.forEach(database => {
                msg += ' - ' + database.base_desc + '\r\n'          
              });  
        
              msg += '\r\n Se um dos databases citados não aparece na tela, lhe deram permissão para excluir, e esqueceram de dar permissão para visualizar!' 
        
              alert(msg)        
            })
            .catch(function (error) {
              console.log(error);
            })     
          

          else if (window.confirm( `Remover camada "${selectedLayers[0]}"?`)) {  // PONTO DE INÍCIO DA EXCLUSÃO
  
            axios.delete(env.GEOTABREST_REMOVE_LAYER_FROM_GEOSERVER + '/' + selectedLayers[0])
            .then((response) => {          
              // alert('GEOTABREST_REMOVE_LAYER_FROM_GEOSERVER ==>  ok')
    
    
              axios.delete(env.GEOTABREST_REMOVE_LAYER_STYLE_FROM_GEOSERVER + '/' + selectedLayers[0])
              .then((response) => {          
                // alert('GEOTABREST_REMOVE_LAYER_STYLE_FROM_GEOSERVER ==>  ok')
    
    
                axios.delete(env.GEOTABREST_REMOVE_LAYER_FROM_GEOTC + '/' + selectedLayers[0])
                .then((response) => {          
                  // alert('GEOTABREST_REMOVE_LAYER_FROM_GEOTC ==>  ok')
                  
                  setSelectedLayers([])              
                  setFlagUpdateTree( !flagUpdateTree ) // Ordenando nova rederização da TreeView              
                  updateTreeView()
                  alert('Camada Removida!')
    
                })
                .catch(error => 
                { 
                  let msg = `Erro apagando layer '${selectedLayers[0]}' do database GeoTC : "${error.message}"`
                  console.error(msg)
                  alert(msg)
                });                         
    
        
              })
              .catch(error => 
              { 
                let msg = `Erro apagando Style da layer '${selectedLayers[0]}' do GeoServer : "${error.message}"`
                console.error(msg)
                alert(msg)
              });                  
    
      
            })
            .catch(error => 
            { 
              let msg = `Erro apagando layer '${selectedLayers[0]}' do GeoServer : "${error.message}"`
              console.error(msg)
              alert(msg)
            })                        
           
          } // else if (window.confirm( `Remover camada "${selectedLayers[0]}"?`)) {          

        }

      }    


      if (!loggedUser)   
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else            
          if (!possuiPermissaoParaRemover())
            return null
          else   
            return <a onClick={ () => removeLayer() }>
      
                     <table>
                       <tr>
                         <td>
                           <FaMap  size='17' color='#C99' title='REMOVER' />
                         </td>                    
                         <td>
                           <FaMinus size='10' color='#C99' title='REMOVER' />
                         </td> 
                       </tr>
                     </table>        
                                    
                   </a>                   
    }


    function drawButtonEquipe(){
      if (!loggedUser)   
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else 
          return <RiGroup2Fill color={lib.EMBRAPA_VERDE_COLOR} size='20px' title='EQUIPE' onClick={ () => {   setModal( { code: modalConstants.modalCodeEquipe } )   } } ></RiGroup2Fill>
    }    



    function drawButtonHelp(){
      if (!loggedUser)   
        return null
      else
        if (loggedUser.id_usuario == -1)  
          return null
        else 
          return <MdHelp color={lib.EMBRAPA_VERDE_COLOR} size='20px' title='AJUDA' onClick={ () => {   setModal( { code: modalConstants.modalCodeHBoasVindas } )   } } ></MdHelp>
    }    
         
    //  ------------------------------------------ BUTTONS - TÉRMINO ------------------------------------------
  
    if ( (props.idUsuario === -1)  ) 
      return null    
    else 
      return <table style={{width:'100%'}}>
               <tbody>

                 {/* <tr>
                   <td>
                     {drawButtonConfig()}
                   </td>

                   {
                     loggedUser.id_perfil === 1 ?
                     <td width='55px'/>
                     :
                     <td width='75px'/>
                   }                    */}


                 <tr>
                   <td width='10px'/>
                   <td>
                     {drawButtonConfig()}
                   </td>

                   {
                     loggedUser.id_perfil === 1 ?
                     <td width='45px'/>
                     :
                     <td width='65px'/>
                   }     


                   <td>
                     {drawButtonExpand()}
                   </td>                                
                   <td width='10px'/>
                   <td>
                     {drawButtonCollapse()}
                   </td>               


                   <td width='57px'/>


                   <td>
                     {drawButtonEquipe()}
                   </td>                           
                   <td width='10px'/>
                   <td>
                     {drawButtonHelp()}
                   </td>                           


                   <td width='65px'/>    


                   <td>
                     {drawButtonClose()}
                   </td>                   
                   <td width='10px'/>
                   <td>
                     {drawButtonInfo()}
                   </td>                   
                   <td width='10px'/>
                   <td>
                     {drawButtonDownload()}
                   </td>                   



                   <td width='50px'/>                         



                   <td>
                     {drawButtonLayerAdd()}
                   </td>                   
                   <td width='10px'/>
                   <td>
                     {drawButtonLayerEdit()}
                   </td>                   
                   <td width='10px'/>
                   <td>
                     {drawButtonLayerRemove()}
                   </td>                
                 </tr>
  
               </tbody>
             </table>                                    
    
  }


  // ------------------------------------------------------------------------------------------------------------------

  // O controle de login abaixo é uma versão apropriada para a barra de botões
  function DrawLoginControlInLine(){
    if (!loggedUser)   // Pela lógica atual, basta estar logado para visualizar esse controle
      return null
    else
      if (loggedUser.id_usuario == -1)  
        return null
      else {            

        const result = isDesktopOrLaptop || isBigScreen ? 
               <table>
                 <tbody>
                   <tr height='1px'>
                     <td align='right' style={lib.LOGIN_TITLE}>
                       Usuário:
                     </td>             
                     <td align='left' style={lib.LOGIN_VALUE}>
                       {loggedUser.tx_nome}
                     </td>
  
                     <td width='15px'/>
  
                     <td align='right' style={lib.LOGIN_TITLE}>
                       Perfil:
                     </td>             
                     <td align='left' style={lib.LOGIN_VALUE}>
                       {loggedUser.desc_perfil}
                     </td>
  
                     <td width='15px'/>
  
                     <td>                     
                       <CrudBtVermelhoPequeno onClick={ () => { doLogoff();  setLoggedUser(defaultLoggedUser); setModal( modalObjectDefault );  } }> Sair </CrudBtVermelhoPequeno>
                     </td>                           
                   </tr>
                 </tbody>
               </table>                   
               :
               <table>
                 <tbody>
                   <tr height='1px'>
                     <td align='left' style={lib.LOGIN_VALUE}>
                       {loggedUser.tx_nome}
                     </td>
  
                     <td width='15px'/>
  
                     <td>                     
                       <CrudBtVermelhoPequeno onClick={ () => { doLogoff();  setLoggedUser(defaultLoggedUser); setModal( modalObjectDefault );  } }> Sair </CrudBtVermelhoPequeno>
                     </td>                           
                   </tr>
                 </tbody>
               </table>                                  


        return result
      }
  }      


  function DrawLoginControl(){  
    if (!loggedUser)   // Pela lógica atual, basta estar logado para visualizar esse controle
      return null
    else
      if (loggedUser.id_usuario == -1)  
        return null
      else {                  
        const result = isDesktopOrLaptop || isBigScreen ? 
               <table style={lib.DASHED_TABLE}>
                 <tbody>
                   <tr height='1px'>
                     <td align='right' style={lib.LOGIN_TITLE}>
                       Usuário:
                     </td>             
                     <td align='left' style={lib.LOGIN_VALUE}>
                       {loggedUser.tx_nome}
                     </td>
                     <td rowSpan='2'>
                       <CrudBtVermelho onClick={ () => { doLogoff();  setLoggedUser(defaultLoggedUser); setModal( modalObjectDefault );  } }> Sair </CrudBtVermelho>
                     </td>                                                
                    </tr>

                    <tr>                       
                     <td align='right' style={lib.LOGIN_TITLE}>
                       Perfil:
                     </td>             
                     <td align='left' style={lib.LOGIN_VALUE}>
                       {loggedUser.desc_perfil}
                     </td>
                   </tr>

                 </tbody>
               </table>                   
        : 
               <table style={lib.DASHED_TABLE}>
                 <tbody>
                   <tr height='1px'>         
                     <td align='left' style={lib.LOGIN_VALUE}>
                       {loggedUser.tx_nome}
                     </td>
                     <td>
                       <CrudBtVermelho onClick={ () => { doLogoff();  setLoggedUser(defaultLoggedUser); setModal( modalObjectDefault );  } }> Sair </CrudBtVermelho>
                     </td>                                                
                    </tr>         
                 </tbody>
               </table>                      
            
        return result
    }
  }      



  // ------------------------------------------------------------------------------------------------------------------


  const legendaGraficoCabecalho = {fontSize:'9px', fontWeight: 'bold'}

  const header_projdesc =
    <p style={{color:lib.EMBRAPA_AZUL_COLOR, fontSize:10, fontWeight: 'bold'}}> 
      Organização e disponibilização da informação geoespacial 
      <br></br>
      sobre recursos naturais e aspectos socioeconômicos 
      <br></br>
      da área de atuação da Embrapa Tabuleiros Costeiros.
    </p>


  const header_geotc =     
    <ul style={isDesktopOrLaptop || isBigScreen ? ulStyle : {width:'100%', textAlign:'center'} }>
      <li style={liStyle}>            
      
        <table style={{width:'100%' }}>
          <tr>
            <td>
              <li style={liStyle}>
                <img style={imgStyle} src={require('./../img/geotc.png')} />            
              </li>               
            </td>
            <td>
              { isDesktopOrLaptop || isBigScreen ? header_projdesc : null}
            </td>
          </tr>
        </table>
  
      </li>
    </ul>                            

  const header_pizza =                 
  <ul style={ulStyle}>
    <li style={liStyle}>            
      <table>
        <tbody>
          <tr>            
            <td>
  
              <table width='100%' border='0' cellspacing='0' cellpadding='0' >
                
                <tr>  
  
                  <td align='right'>
                    <table width='100%' cellspacing='0' cellpadding='0' border='0' >
                      <tr>
                        <td height='15px' align='right'>
                          <p style={{color:'#96a5cd', ...legendaGraficoCabecalho}} > 160 municípios na BAHIA </p>                                      
                        </td>
                      </tr>
                      <tr>
                        <td height='20px' align='right'>
                          <p style={{color:'#d3803f', ...legendaGraficoCabecalho}} > 63 municípios em SERGIPE </p>                                      
                        </td>
                      </tr>           
                      <tr>
                        <td height='15px' align='right'>
                          <p style={{color:'#4d94ab', ...legendaGraficoCabecalho}} > 73 municípios em ALAGOAS </p>                                      
                        </td>
                      </tr>  
                    </table>                                    
                  </td>
  
                  <td>
                    <img style={{marginTop: '-3px'}} src={require('./../img/area_atuacao.png')} alt="Embrapa" height='50px' />
                  </td>
  
                  <td align='left'>
                    <table width='100%' border='0' cellspacing='0' cellpadding='0' border='0' >
                      <tr>
                        <td height='10px' align='left'>
                          <p style={{color:'#4c6fa4', ...legendaGraficoCabecalho}} > 68 municípios na CEARÁ </p>                                      
                        </td>
                      </tr>
                      <tr>
                        <td height='15px' align='left'>
                          <p style={{color:'#a43f40', ...legendaGraficoCabecalho}} > 61 municípios no RIO GRANDE DO NORTE </p>                                      
                        </td>
                      </tr>  
                      <tr>
                        <td></td>
                      </tr>  
                      <tr>
                        <td height='10px' align='left'>                         
                          <p style={{color:'#70528c', ...legendaGraficoCabecalho}} > 79 municípios em PERNAMBUCO </p>                                      
                        </td>
                      </tr>                                                                                                      
                    </table>                                    
                  </td>
  
                </tr>                            
              </table>  
  
            </td>                                                
          </tr>                
        </tbody>
      </table>              
    </li>
  </ul>  
    

  const header_emb =
    <ul style={ulStyle}>
      <li style={liStyle}>            
        <table>
          <tbody>
            <tr>            
              <td>
                <li style={liStyle}>
                  <img style={{marginTop: '-3px'}} src={require('./../img/emb_menor.png')} alt="Embrapa"  />
                </li>                                                                
              </td>                                                
            </tr>                
          </tbody>
        </table>              
      </li>
    </ul>              

  return (
    <table width='100%' border='0' cellspacing='0' cellpadding='0' >
      <tr>
        <td>

          <nav style={navStyleTopBar}>
            
            <ul style={ulStyle}>

              { header_geotc }

              { isDesktopOrLaptop || isBigScreen ? header_pizza : null}

              { isDesktopOrLaptop || isBigScreen ? header_emb : null}
                          
            </ul>
            
          </nav>

        </td>
      </tr>


      <tr>
        <td>

          <nav style={navStyleBottomBar}>          
            <ul style={ulStyle}>
            
              <ul style={ulStyle}>
                <li style={liStyle}>            
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <ButtonBar/>            
                        </td>
                      </tr>                
                    </tbody>
                  </table>              
                </li>
              </ul>
            

              <ul style={ulStyle}>
                <li style={liStyle}>            
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          {DrawLoginControlInLine()}                          
                        </td>
                      </tr>               
                    </tbody>
                  </table>              
                </li>
              </ul>            


            </ul>          
          </nav>
                      
        </td>
      </tr>


    </table>


  )

}