import React, { useState, useContext, useEffect } from 'react';
import Nav from './components/nav'
import TreeViewMap from './components/treeViewMap'
import LoggedUserContext from './context/LoggedUserContext'
import 'semantic-ui-css/semantic.min.css'
import useModal from 'react-hooks-use-modal';
import ModalContext, { ModalContent } from './context/ModalContext'
import * as modalConstants from './context/ModalContext'
import SelectedLayersContext from './context/SelectedLayersContext'
import Legends from './components/legends'
import MapViewer from './components/mapViewer' // Desse jeito encontramos o erro: "ReferenceError: window is not defined"


function MapTree(props) {  

  const { modal, setModal } = useContext(ModalContext)
  
  const divTreeStyle={
    topPadding: '30px',
    overflowX: 'auto',
    overflowY: 'auto',
    border:'0px solid red', // old ==> border:'1px solid red', // PARA DEBUG
    // whiteSpace: 'nowrap', // Se inserido, não teremos quebra de linha nas categorias na árvore
    width:'200px',
    float: 'left',
    height:'calc(100vh - 91px)', // Precisa estar sincronizado com height de divLegendStyle
    position:'relative',
    display: props.idUsuario === -1 ? 'none' : 'inline-block'
  };  

  if ( (props.idUsuario === -1)  ) { // Não logado ou todos os visualizadores desabilitados
    
    if ( 
         (modal.code !== modalConstants.modalCodeLogin) 
         &&       
         (modal.code !== modalConstants.modalCodeUsuarioRegister) 
         && 
         (modal.code !== modalConstants.modalCodeUsuarioRecovery) 
         && 
         (modal.code !== modalConstants.modalCodeAvisoPrivacidade)          
         && 
         (modal.code !== modalConstants.modalCodeTermoUso)             
       )
        setModal( { code: modalConstants.modalCodeLogin } )

    return null
  }


  return <td width='200px' style={{backgroundColor:'white'}} >
           <div style={divTreeStyle}>             
             <table width='0%' border='0' cellspacing='0' cellpadding='0' height='7px'/> {/* Linha apenas para determinar espaço vertical */}
             <TreeViewMap id='fake' />
           </div>             
          </td>                            
}


function MapLegend(props) {    
  const { selectedLayers } = useContext(SelectedLayersContext) 

  const divLegendStyle={
    overflowX: 'auto',
    overflowY: 'auto',
    border:'0px solid red', // old ==> border:'1px solid red', // PARA DEBUG
    whiteSpace: 'nowrap',
    width:'200px',
    float: 'right',
    height:'calc(100vh - 91px)', // Precisa estar sincronizado com height de divTreeStyle
    position:'relative',
    display: 'block',
  };  

  if ( (props.idUsuario === -1) || (selectedLayers.length == 0)  ) {
    return null
  }
  else {
      return <td width='200px' style={{backgroundColor:'white'}} >
               <div style={divLegendStyle}>     
                 <Legends/>
               </div>             
             </td>      
  }

}


function Home(props) {

  const { loggedUser } = useContext(LoggedUserContext) 

  const { modal, setModal } = useContext(ModalContext)   

  // Para evitar loop no useEffect abaixo... só dá refresh se, anteriormente, um modal tiver sido aberto!
  const [newModal, setNewModal] = useState(false); 

  // OBS: Com preventScroll igual a true, fiquei sem conseguir inserir espaços em branco nos "input text"
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: false
  });

  useEffect( () => {
    if (loggedUser.id_usuario !== -1)

      if ( modal.code == 0 ) {
        if (isOpen)
          close()

        if (newModal){
          setNewModal(false)
          window.location.reload()        
        }
        
      }
      else {
        setNewModal(true)
        open()
      }
  }, 
  [ modal ] )

  // Forçando modal nas telas de Login, Registro e Recuperações
  if ( (   
          ( modal.code === modalConstants.modalCodeLogin )   ||   
          ( modal.code === modalConstants.modalCodeUsuarioRegister )   ||
          ( modal.code === modalConstants.modalCodeUsuarioRecovery ) ||
          ( modal.code === modalConstants.modalCodeAvisoPrivacidade ) ||
          ( modal.code === modalConstants.modalCodeTermoUso )
       )
       && 
       ( !isOpen ) 
     )
    open()

  
  return <div>    
           <Nav/>
       
           <table width='100%' border='0' cellspacing='0' cellpadding='0' >
           
             <tbody>
               <tr>
                 <MapTree idUsuario={loggedUser.id_usuario} /> 
  
                 <td>    
                   <MapViewer />
                 </td>
  
                 <MapLegend idUsuario={loggedUser.id_usuario} />                
               </tr>
             </tbody>
           </table>
                
           <Modal>             
             <ModalContent code={modal.code} params={modal.params} />          
           </Modal>              
       
         </div>
}

export default Home