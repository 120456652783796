import { createContext } from 'react'

// dbs_perm_vpv: Bases nas quais o usuário pode visualizar camadas PRIVADAS
// dbs_perm_vis: Bases nas quais o usuário pode visualizar camadas PÚBLICAS
// dbs_perm_bai: Bases nas quais o usuário pode baixar camadas
// dbs_perm_inc: Bases nas quais o usuário tem permissão de incluir camadas
// dbs_perm_alt: Bases nas quais o usuário tem permissão de alterar camadas
// dbs_perm_exc: Bases nas quais o usuário tem permissão de excluir camadas
// dbs_perm_est: Bases para as quais o usuário terá permissão de consultar estatísticas

// OBS: Essa lógica de pesquisar permissões nas strings só funcionará enquanto 
// o número de bases for menor ou igual a 9: 
// Considere que uma pesquisa por "1" encontraria "1" e qualquer outro número 
// que também possua "1" ("10", "11", "12", ...) etc

export const defaultLoggedUser =  {
                                    id_usuario:-1,
                                    tx_nome:'',
                                    tx_email:'',
                                    id_perfil:-1,
                                    desc_perfil:'',
                                    dbs_perm_vpv: '',
                                    dbs_perm_vis: '',
                                    dbs_perm_bai: '',
                                    dbs_perm_inc: '',
                                    dbs_perm_alt: '',
                                    dbs_perm_exc: '',
                                    dbs_perm_est: ''
                                  }
                                  
const LoggedUserContext = createContext( 
  {
    loggedUser:defaultLoggedUser, 
    setLoggedUser: () => {}
  }
                                 ) 

export default LoggedUserContext