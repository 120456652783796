import React, { useState, useContext, useEffect } from "react";
import SelectedIdsContext, {defaultSelectedIdsContext} from "./SelectedIdsContext";
import SelectedLayersContext from "./SelectedLayersContext";
import axios from "axios"; // ====> new
import * as env from '../.env.js'

export const selectedIdsKeyForLocalStorage = '@geotab/selectedIds'

export const useSelectedIdsWithLocalStorage = localStorageKey => {

  const { selectedLayers } = useContext(SelectedLayersContext)
  
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [selectedIds, setSelectedIds] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultSelectedIdsContext )

  async function UpdateSelectedIds() {

    function concat_array_items(array, chr){
      let answer = []
      for (let i = 0; i < array.length; i++)
        answer.push( chr.concat(array[i]) )

      return answer
    }

    if (selectedLayers.length == 0){      
      setSelectedIds(defaultSelectedIdsContext)
      localStorage.setItem( localStorageKey, JSON.stringify( defaultSelectedIdsContext ) )
    }
    else{
      const separator = '@'

      // Montando parametro para a função
      // ---------------------------------------------------------------------------------------
      let selectedLayersParameter = ''
      for (let i = 0; i < selectedLayers.length; i++)
        if (selectedLayersParameter == '')
          selectedLayersParameter = selectedLayers[i]
        else
          selectedLayersParameter = selectedLayersParameter + separator + selectedLayers[i]
      // ---------------------------------------------------------------------------------------        
  
      let url = env.GEOTABREST_GET_LAYERS_CODES + '/' + selectedLayersParameter
      axios.get(url)
      .then(res => {
        const ids_in_str = res.data.ids
        const ids_in_array = ids_in_str.split(separator)
        const ids_for_treeview = concat_array_items(ids_in_array, 'm')
  
        setSelectedIds(ids_for_treeview)
        localStorage.setItem( localStorageKey, JSON.stringify( ids_for_treeview ) )            
      })    
      .catch(function (error) {
        console.log(error);
      })          

    }       
    
  }  

  useEffect( () => // Atualizando selectedIds em função de selectedLayers
              {
                UpdateSelectedIds()
              }, 
              [selectedLayers]
           )

  return [selectedIds, setSelectedIds]
}

const SelectedIdsProvider = ({ children }) => {  
  const [selectedIds, setSelectedIds] = useSelectedIdsWithLocalStorage(selectedIdsKeyForLocalStorage);

  return (
    <SelectedIdsContext.Provider value={{ selectedIds, setSelectedIds }}>
      {children}
    </SelectedIdsContext.Provider>
  )
}

export default SelectedIdsProvider;