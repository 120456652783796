import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figTelaBemVindo from './img/tela_bemvindo.png'
import figRecSenha from './img/recuperacao_senha.png'
import figMsgModoRecuperacao from './img/msg_modo_recuperacao.png'
import figAcionandoRecupSenha from './img/acionando_recuperacao_senha.png'
import figTrocandoSenha from './img/trocando_senha.png'
import figMsgSenhaRecuperada from './img/msg_senha_recuperada.png'


const Page = () => {

    return <html>
             <body>
                 <div style={estilos.tituloPagina}>Recuperação de Senha</div>
                                           
                 <figure>
                     <img style={estilos.imgStyle} src={figTelaBemVindo} />
                     <figcaption style={estilos.figcaption}>Figura 1 - Tela Login/Registro/Recuperação</figcaption>
                 </figure>
         
                 <br/>
                 <p>
                     Clique no botão "Recuperar", e o sistema exibirá a mensagem vista na Figura 2.
                 </p>
                 <br/>
         
                 <figure>
                     <img style={estilos.imgStyle} src={figRecSenha} />                     
                     <figcaption style={estilos.figcaption}>Figura 2 - Recuperação de Senha</figcaption>
                 </figure>        
         
                 <br/>
                 <p>
                     Na tela vista na Figura 2, preencha o nome e o e-mail do usuário a ser recuperado, 
                     e clique no botão "Recuperar". Você visualizará a mensagem vista na Figura 3
                 </p>
                 <br/>
         
                 <figure>
                     <img style={estilos.imgStyle} src={figMsgModoRecuperacao} />
                     <figcaption style={estilos.figcaption}>Figura 3 - Mensagem modo de Recuperação</figcaption>
                 </figure>                
         
                 <br/>
                 <p>
                     Quando o usuário clicar no botão "OK", da caixa de diálogo vista na Figura 3, 
                     será automáticamente redirecionado para a caixa de diálogo vista na Figura 4.
                 </p>
                 <br/>
         
                 <figure>
                     <img style={estilos.imgStyle} src={figAcionandoRecupSenha} />                    
                     <figcaption style={estilos.figcaption}>Figura 4 - Acionando a Recuperação de Senha</figcaption>
                 </figure>                
                     
                 <br/>
                 <p>
                     Perceba, na Figura 4, que, em função de ter dado início a um processo de recuperação de senha, 
                     não é necessário informar a senha: digite apenas o nome do seu usuário e clique no botão “Entrar”. 
                     Depois deste clique, o usuário será redirecionado para a tela vista na Figura 5.
                 </p>
                 <br/>        
         
                 <figure>
                     <img style={estilos.imgStyle} src={figTrocandoSenha} />                                                              
                     <figcaption style={estilos.figcaption}>Figura 5 - Trocando Senha</figcaption>
                 </figure>                        
                 
                 <br/>
                 <p>
                      Na tela vista na Figura 5, informe a nova senha e clique no botão “Recuperar”. Feito isso, 
                      o usuário visualizará a tela vista na Figura 6. E, nesta, quando ele clicar no botão “OK” 
                      será redirecionado para a tela vista na Figura 1.
                 </p>
                 <br/>
         
                 <figure>
                     <img style={estilos.imgStyle} src={figMsgSenhaRecuperada} />                                          
                     <figcaption style={estilos.figcaption}>Figura 6 - Senha recuperada</figcaption>
                 </figure>                
                         
                 <br/>
                 <p>
                      Estando novamente na tela vista na Figura 1, basta inserir as novas credenciais, 
                      em sintonia com as alterações realizadas.
                 </p>
         
             </body>
         </html>
}

const HCRecuperacaoSenha = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HCRecuperacaoSenha