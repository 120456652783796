import { createContext } from 'react'

export const defaultExpandedIds = []
                    
const ExpandedIdsContext = createContext( 
  {
    expandedIds:defaultExpandedIds, 
    setExpandedIds: () => {}
  }
                                        ) 

export default ExpandedIdsContext