import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figMenuAdm from './img/menuadm.png'
import figCRUDPermissao1 from './img/crud_permissoes1.png'
import figCRUDPermissao2 from './img/crud_permissoes2.png'


const Page = () => {

    return <html>    
               <body>
                   <div style={estilos.tituloPagina}>Gerenciamento de Permissão</div>
               
                   <br/>
                   <p>
                       Para controlar as permissões dos usuários é necessário ter um perfil de gestor.
                       Considere o menu visto na Figura 1.
                   </p>
                   <br/>
                              
                   <figure>
                     <img style={estilos.imgStyle} src={figMenuAdm} />
                     <figcaption style={estilos.figcaption}>Figura 1 - Menu de Administração</figcaption>
                   </figure>
                                    
                   <br/>
                   <p>
                       Neste menu, clique na opção <i>Permissões</i>,
                       e o sistema exibirá a tela vista na Figura 2
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figCRUDPermissao1} />
                       <figcaption style={estilos.figcaption}>Figura 2 - Seleção do perfil a ser gerenciado</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Na tela em questão ( Figura 2 ) é necessário selecionar um perfil, pois, as permissões
                       sempre estarão atreladas a um perfil.        
                       <br/>
                       Cada usuário só poderá estar associado a um perfil.
                       E, qualquer usuário que pertença a determinado perfil, sempre terá, exatamente,
                       as mesmas permissões associadas ao seu perfil.
                   </p>
                   <br/>
               
                   <figure>
                       <img style={estilos.imgStyle} src={figCRUDPermissao2} />
                       <figcaption style={estilos.figcaption}>Figura 3 - Gerenciamento as permissões de um Perfil</figcaption>
                   </figure>
               
                   <br/>
                   <p>
                       Perceba todas as observações na parte inferior da caixa de diálogo vista na Figura 3.
                   </p>
                   <br/>
               </body>
               
           </html>
           
}

const HGerenciamentoPermissao = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HGerenciamentoPermissao