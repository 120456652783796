import React, { useState, useEffect } from "react";
import axios from "axios";
import { CrudTable } from './lib/lib'
import * as lib from './lib/lib'
import * as modalConstants from './context/ModalContext'
import * as env from './.env.js'
import Chart from 'chart.js';

export default function StatsCamadasPorBase(props) {  

  const [labels, setLabels] = useState([])

  const [data, setData] = useState([])

  const [loadingData, setLoadingData] = useState(true)

  
  useEffect(() => {    // Carregando dados do SGBD para o state do componente
    axios.get(env.GEOTABREST_STAT_CAMADAS_POR_BASE)
    .then(res => {        

      let data = res.data.data
      let labelsArray = []
      let dataArray = []

      for (let i = 0; i < data.length; i++) {
        labelsArray.push(data[i].base)
        dataArray.push(data[i].qtd)
      }

      setLabels(labelsArray)
      setData(dataArray)
      setLoadingData(false)
    })    
    .catch(function (error) {
      console.log(error);
    })      
    
  }, []); 


  let pieChart  
  useEffect(() => {    // Montando o gráfico, após o término da carga dos dados do SGBD
    if (!loadingData)  
      pieChart = new Chart(document.getElementById("stat_camadas_por_base"), {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: "Camadas por Base",
            backgroundColor: ["#3e95cd", "#8e5ea2","#3cba9f","#e8c3b9","#c45850", "#FFF"],
            data: data
          }]
        },
        options: {
          title: {
            display: false,
            text: 'Quantidade de Camadas por Database'
          },
        },

      })    

  }, [loadingData]);


  const debugTable = lib.debug( 
    {
       debug: false,
       loadingData
     }
  )  
  
  if (loadingData) 
    return <p> Carregando... </p>
  else
    return <CrudTable border='1' title='QUANTIDADE DE CAMADAS POR BASE DE DADOS' width='100%' modalBtClose={modalConstants.modalCodeStats} debugTable={debugTable} >
             <tr style={{height:'10px'}}></tr>         
             <tr width='100%'>
               <td align='center'>
                 <canvas id="stat_camadas_por_base" width="600" height="400"></canvas>                               
               </td>
             </tr>                          
         </CrudTable>                                                                      
}