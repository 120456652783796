import React, { useState, useEffect } from "react";
import axios from "axios";
import { CrudTable } from './lib/lib'
import * as lib from './lib/lib'
import * as modalConstants from './context/ModalContext'
import * as env from './.env.js'
import Chart from 'chart.js';

export default function StatsUsuariosPorAcesso(props) {  

  const [labels, setLabels] = useState([])

  const [data, setData] = useState([])

  const [loadingData, setLoadingData] = useState(true)

  
  useEffect(() => {    // Carregando dados do SGBD para o state do componente
    axios.get(env.GEOTABREST_STAT_ACESSOS_POR_USUARIO)
    .then(res => {        

      let data = res.data.data
      let labelsArray = []
      let dataArray = []

      for (let i = 0; i < data.length; i++) {
        labelsArray.push(data[i].usuario)
        dataArray.push(data[i].acessos)
      }

      setLabels(labelsArray)
      setData(dataArray)
      setLoadingData(false)
    })    
    .catch(function (error) {
      console.log(error);
    })      
    
  }, []); 


  let pieChart  
  useEffect(() => {    // Montando o gráfico, após o término da carga dos dados do SGBD
    if (!loadingData)  
      pieChart = new Chart(document.getElementById("stat_acessos_por_usuario"), {
        type: 'horizontalBar',
        data: {
          labels: labels,
          datasets: [{
            label: "",
            backgroundColor: ["#3e95cd", "#8e5ea2","#3cba9f","#e8c3b9","#c45850", "#FFF", '#CCC', "#faa"],
            data: data
          }]
        },
        options: {
          legend: {
            labels: {
                fontSize: 0 // Remove uma legenda indesejada que aparecia no topo do gráfico
            }
          },          
          title: {
            display: false,
            text: 'Quantidade de Acessos por Usuário'
          },

          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
                maxRotation: 90,
                minRotation: 80
              },
                gridLines: {
                offsetGridLines: false // à rajouter
              }
            }
            ],
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }          


        },

      })    

  }, [loadingData]);


  const debugTable = lib.debug( 
    {
       debug: false,
       loadingData
     }
  )  
  
  if (loadingData) 
    return <p> Carregando... </p>
  else
    return <CrudTable border='1' title='QUANTIDADE DE ACESSOS POR USUÁRIO' width='100%' modalBtClose={modalConstants.modalCodeStats} debugTable={debugTable} >
             <tr style={{height:'10px'}}> 
               <td style={{textAlign:'center', color:'red', fontSize:'10px'}}>
                 OBS: Contagem de acessos à partir do dia 13/10/2021 (Data de início da contagem)
               </td>
             </tr>                            
             <tr width='100%'>
               <td align='center'>                 
                 <div style={{width:'1000px', height:'600px', overflowY:'scroll'}}>      
                     <canvas id="stat_acessos_por_usuario" width='800px' height='1000px'></canvas>                               
                 </div>
               </td>
             </tr>                          
         </CrudTable>   
}