import React, { useContext } from "react";
import Table from './../components/table'
import * as env from './../.env.js'
import {  CrudTable } from './../lib/lib'

export default function UsuarioIndex(props) {
  
  const columns = [
    {
      left: true,
      name: 'Perfil',
      selector: 'desc_perfil',
      width: '250px',
      sortable: true,      
    },
    {
      left: true,
      name: 'Nome',
      selector: 'tx_nome',
      width: '100px',
      sortable: true,
    }, 
    {
      left: true,
      name: 'E-Mail',
      selector: 'tx_email',
      width: '170px',
      sortable: true,
    },     
    {
      center: true,
      name: 'Recuperar',
      selector: 'bl_recuperar_senha',
      width: '70px',      
      sortable: false,      
      blToggleRest: env.GEOTABREST_USUARIO__TOGGLE__BL_RECUPERAR_SENHA,      
    },         
  ];    

  return <CrudTable title='USUÁRIO'>
           <tbody>
             <tr align='center' height='40'>             
               <td colSpan='2'>
                 <Table 
                   rest={env.GEOTABREST_CRUD_USUARIO} 
                   table='usuario'
                   pk='id_usuario'
                   columns={columns}                    
                   controlInsert={true} 
                   controlEdit={true} 
                   controlDelete={true} 
                 />
               </td>
             </tr>                                                               
           </tbody>
         </CrudTable>
}