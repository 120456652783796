/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring';
import SelectedLayersContext from "../context/SelectedLayersContext";
import ExpandedIdsContext from "../context/ExpandedIdsContext";
import SelectedAncestorNodeContext from "../context/SelectedAncestorNodeContext";
import AvailableLayersContext from "../context/AvailableLayersContext";
import TreeViewContext from '../context/TreeViewContext'
import FlagUpdateTreeContext from '../context/DebugContext'
import * as lib from '../lib/lib';
import { FaMap, FaRegMap, FaDatabase, FaListUl } from 'react-icons/fa'


function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  label: {
    fontSize:10,    
    fontStyle:'bold',
    fontWeight: 'inherit',
    color: lib.EMBRAPA_AZUL_COLOR,    
  },    
  group: {        
    marginLeft: 5,
    paddingLeft: 5,
    paddingTop: 5,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.8)}`,
  },
}))(props => <TreeItem {...props} TransitionComponent={TransitionComponent} />);


const useStyles = makeStyles({
  root: {    
    flexGrow: 1,
    maxWidth: 400,
  },
});


export default function TreeViewMap(props) {

  const iconSize = 15
  const iconDatabase = <FaDatabase size={iconSize} color='#385b9b' />
  const iconCategory = <FaListUl size={iconSize} color='orange' />
  const iconPublicMap = <FaMap size={iconSize} color='#ccc' />
  const iconSelectedPublicMap = <FaMap size={iconSize} color='#378254' />
  const iconPrivateMap = <FaRegMap size={iconSize} color='#ccc' />
  const iconSelectedPrivateMap = <FaRegMap size={iconSize} color='#378254' />  

  
  const classes = useStyles();

  // const { modal } = useContext(ModalContext)

  const { treeView } = useContext(TreeViewContext)

  useEffect(() => {
    setInternalFlagToUpdate( !internalFlagToUpdate )
  }, [treeView] );    

  const { selectedLayers, setSelectedLayers } = useContext(SelectedLayersContext)  

  const { selectedAncestorNode, setSelectedAncestorNode } = useContext(SelectedAncestorNodeContext)

  const { expandedIds } = useContext(ExpandedIdsContext)

  const [ nodes, setNodes ] = useState(null)

  const { flagUpdateTree } = useContext(FlagUpdateTreeContext)

  useEffect(() => {
    setInternalFlagToUpdate( !internalFlagToUpdate )
  }, [flagUpdateTree] );    

  const { availableLayers } = useContext(AvailableLayersContext)

  useEffect(() => {
    setInternalFlagToUpdate( !internalFlagToUpdate )
  }, [availableLayers] );      


  
  // Alternância entre true e false para notificar a necessidade de renderização
  const [ internalFlagToUpdate, setInternalFlagToUpdate ] = useState(true)

  useEffect(() => {

    // Return roots
    function roots(data){
      let res = []

      for (let pos = 0; pos < data.length; pos++){
        let node = data[pos]
        if (node.id_pai === null){
          res.push(node)
        }        
      }

      return res
    }    

    // Return children from parent_node 
    function children(data, parent_node){
      let res = []

      for (let pos = 0; pos < data.length; pos++){
        let node = data[pos]
        if (node.id_pai == parent_node.id){
          res.push(node)
        }        
      }

      return res
    }

    function updateFromClick(tipo, id, layer){

      // const { selectedAncestorNode, setSelectedAncestorNode } = useContext(SelectedAncestorNodeContext)
      if (tipo != 'mapa'){ // ==> tipo = base OU tipo = camada

        // ATUALIZANDO SELEÇÃO NÓS ANCESTRAIS DO MAPA (Tipo base OU tipo camada)
        let newContentForSelectedAncestorNode = [ ...selectedAncestorNode ]
        
        if (newContentForSelectedAncestorNode.indexOf(id) === -1) { // Não está selecionado: selecionar
          
          newContentForSelectedAncestorNode.push(id)
          setSelectedAncestorNode(newContentForSelectedAncestorNode)        
        }
        else{ // Está selecionado: remover
  
          const index = newContentForSelectedAncestorNode.indexOf(id)
          if (index > -1) {
            newContentForSelectedAncestorNode.splice(index, 1)
            setSelectedAncestorNode(newContentForSelectedAncestorNode)
          }                
        }        

      }
      else { //  tipo ==> mapa

        // ATUALIZANDO LAYERS... QUE, AUTOMATICAMENTE, ATUALIZAM IDs
        let newContentForSelectedLayers = [ ...selectedLayers ]
        
        if (newContentForSelectedLayers.indexOf(layer) === -1) { // Não está selecionado: selecionar
          
          newContentForSelectedLayers.push(layer)
          setSelectedLayers(newContentForSelectedLayers)        
        }
        else{ // Está selecionado: remover
  
          const index = newContentForSelectedLayers.indexOf(layer)
          if (index > -1) {
            newContentForSelectedLayers.splice(index, 1)
            setSelectedLayers(newContentForSelectedLayers)
          }                
        }

      } // else -- if (tipo != 'mapa'){


      setInternalFlagToUpdate( !internalFlagToUpdate )
    }


    function printNode(data, node){         

      if (node === null)
        return null
      else {

        // Definindo ícon do nó
        // ---------------------------------------------------------------------
        let icon
        if (node.tipo == 'base')
          icon = iconDatabase
        else 
          if (node.tipo == 'camada')
            icon = iconCategory
          else {
            let selected = selectedLayers.indexOf(node.layer) !== -1
            if (node.camada_privada){
              if (selected)
                icon = iconSelectedPrivateMap
              else
                icon = iconPrivateMap                
            }            
            else { // if (node.camada_privada){
              if (selected)
                icon = iconSelectedPublicMap
              else
                icon = iconPublicMap
            }
          }
        // ---------------------------------------------------------------------          

        let childList = children(data, node)

        return <StyledTreeItem  
                                icon={icon}
                                key={node.id} 
                                id={node.id} 
                                nodeId={node.id} 
                                // label={node.id + ' - ' + node.desc} // PARA DEBUG
                                label={node.desc}
                                onClick={ () => updateFromClick(node.tipo, node.id, node.layer) }
               >              
                 {  childList.length === 0 ? null : childList.map( (child) => printNode(data, child) ) }
               </StyledTreeItem>     
              
        }
    }              
    
      


    // Print all
    function print(data){
      let res = []
      let rts = roots(data)

      for (let pos = 0; pos < rts.length; pos++){
        let root_node = rts[pos]
        res.push( printNode(data, root_node) )
      }

      return res
    }
      
    
    setNodes(print(treeView))       
    
  }, [internalFlagToUpdate] );  


  const treeViewLegend = {fontSize:'9px', color:'#385b9b', paddingLeft: 5, align: 'left'}

  return <table width='100%' border='0' cellspacing='0' cellpadding='0' >
           
           <tbody>
             <tr>                   
               <td>    

                 <table width='100%' border='0' cellspacing='0' cellpadding='0' style={lib.LEGEND_TABLE} >
                   <tbody>

                     <tr>                   
                       <td colspan='4' align='center'>    
                         <p style={{fontWeight:'bold', ...treeViewLegend}} > LEGENDA: </p>                   
                       </td>                    
                     </tr>

                     <tr>                   
                       <td align='right'>    
                         {iconDatabase}
                       </td>
                       <td align='left'>    
                         <p style={treeViewLegend} > Database </p>                         
                       </td>         
                       <td align='right'>    
                         {iconCategory}
                       </td>
                       <td align='left'>    
                         <p style={treeViewLegend} > Categoria </p>
                       </td>                                              
                     </tr>                     

                     <tr>                   
                       <td colspan='4' align='left'>    
                         <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                           <tr>
                             <td align='right'>
                               {iconPublicMap}
                             </td>
                             <td align='left'>
                               <p style={treeViewLegend} > Camada pública </p>                         
                             </td>
                           </tr>                           
                           <tr>
                             <td align='right'>
                               {iconSelectedPublicMap}
                             </td>
                             <td>
                               <p style={treeViewLegend} > Camada pública selecionada </p>                         
                             </td>
                           </tr>  
                           <tr>
                             <td align='right'>
                               {iconPrivateMap}
                             </td>
                             <td>
                               <p style={treeViewLegend} > Camada privada </p>                         
                             </td>
                           </tr>         
                           <tr>
                             <td align='right'>
                               {iconSelectedPrivateMap}
                             </td>
                             <td>
                               <p style={treeViewLegend} > Camada privada selecionada </p>                         
                             </td>
                           </tr>                                                                                          
                         </table>                        
                       </td>                                                  
                     </tr>       

                   </tbody>        
                 </table>    
           
               </td>                    
             </tr>

             <tr> <td height='15px' /> </tr>             

             <tr>                   
               <td>    
                 <TreeView className={classes.root}  expanded={expandedIds} >
                  {nodes}                        
                </TreeView>           
               </td>                    
             </tr>             
           </tbody>

         </table>    
}