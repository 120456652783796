import React, { useState, useEffect } from "react";
import ModalContext, {modalObjectDefault} from "./ModalContext";

export const modalKeyForLocalStorage = '@geotc/modal'

export const useModalWithLocalStorage = localStorageKey => {
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [modal, setModal] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || modalObjectDefault )

  useEffect( () => // Salva na localStorage apenas quando modal for alterado!
              {
                // if (modal.params == null)
                //   modal.params = { fake:'fake' }

                localStorage.setItem(localStorageKey, JSON.stringify( modal ) )
              }, 
              [modal]
           )

  return [modal, setModal]
}

const ModalProvider = ({ children }) => {  
  const [modal, setModal] = useModalWithLocalStorage(modalKeyForLocalStorage);

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider;