import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { CrudCombo, CrudSection, CrudTable, CrudBtVerde, CrudLabelRequired } from '../lib/lib'
import { BsToggleOn, BsToggleOff } from 'react-icons/bs';
import { FaDatabase } from 'react-icons/fa'
import * as lib from './../lib/lib'
import * as modalConstants from '../context/ModalContext'
import * as env from '../.env.js'
import '../lib/react-dual-listbox.css'


export default function PermissaoIndex(props) {
  
  // ---------------------------------------------------------------------------    

  const [perms, setPerms] = useState([])

  const [loadingPerms, setLoadingPerms] = useState(true)
  
  useEffect(() => {    

    if (loadingPerms) {

      axios.get(env.GEOTABREST_VWPERMISSAO)
      .then(res => {        
        setPerms(res.data.data)          
        setLoadingPerms(false)
      })    
      .catch(function (error) {
        console.log(error);
      })      

    }
    
  }, [loadingPerms]); 

  // ---------------------------------------------------------------------------


  const [idPerfil, setIdPerfil] = useState(-1)

  useEffect(() => {    
    setLoadingPerms(true)     
  }, [idPerfil]); // Carrega todas as permissões, sempre que o perfil muda


  // ---------------------------------------------------------------------------


  const [jsxPerm, setJsxPerm] = useState(null); 

  useEffect(() => {

    function getJSXPerms(idPerfil, idBase){

      function toggle(idPerfil, idBase, idAcao){
        if ( idPerfil == 1 )
          alert('O "Administrador Geral" sempre terá todas as permissões!')
        else{
          axios.put( env.GEOTABREST_PERMISSAO__TOGGLE + '/' + idPerfil  + '/' + idBase  + '/' + idAcao )
          .then(res => {        
            setLoadingPerms(true) // Ordenando a captura de todas as permissões novamente
          })    
          .catch(function (error) {
            console.log(error);
          })          
        }        
      }      

      const toggleSize = 30
      const th_style = {'color': lib.EMBRAPA_AZUL_COLOR, 'fontSize': '15px', 'fontWeight': 'bold' }
      const td_style = {'color': lib.EMBRAPA_VERDE_COLOR, 'fontSize': '14px', 'fontWeight': 'bold' }

      // Capturando permissões específicas das permissões gerais ( perms FROM ==>   const [perms, setPerms] = useState([])  )
      let perms_filtered = []
      let base_title
      for (let i = 0; i < perms.length; i++)
        if (perms[i].perfil_id == idPerfil && perms[i].base_id == idBase){
          perms_filtered.push( { acao_id: perms[i].acao_id, acao_desc: perms[i].acao_desc, perms: perms[i].perm } )
          base_title = perms[i].base_desc
        }
  
  
      let jsx_acoes_filtradas = perms_filtered.map( (acao_filtrada, i ) => {                                      
        return <tr key={i}>
                 <td>
                   <font style={{fontSize:10, fontWeight:'bold', color: lib.EMBRAPA_AZUL_COLOR}}> 
                     { acao_filtrada.acao_desc }
                   </font>
                 </td>
                 <td align='center'>
                   <div onClick={ ()=>{ toggle(idPerfil, idBase, acao_filtrada.acao_id ) } } align='center' > 
                   { 
                     acao_filtrada.perms == true ? 
                     <BsToggleOn  title='SIM. CLIQUE PARA ALTERAR' size={toggleSize} color={lib.TOGGLE_COLOR} />
                     : 
                     <BsToggleOff title='NÃO. CLIQUE PARA ALTERAR' size={toggleSize} color={lib.TOGGLE_COLOR} />
                   }
                   </div>
                 </td>                 
               </tr>
       })            

       return <table style={lib.DASHED_TABLE}> 
                <tbody> 
                  <tr> 
                     <td style={th_style} align='center' colspan='2'> <FaDatabase size={15} color='#385b9b'/> {base_title} </td> 
                  </tr>  
                  <tr> 
                    <td style={td_style}>Ação</td> <td style={td_style}>Permissão</td> 
                  </tr>                   
                  {jsx_acoes_filtradas} 
                </tbody> 
              </table>
    }
     
      if (idPerfil == -1) 
        setJsxPerm(null)
      else{

        let newJsxPerm = <table width='100%' style={{borderSpacing: '11px', borderCollapse: 'separate'}}>
                           <tr>
                             <td>
                               {getJSXPerms(idPerfil, 1)}
                             </td>
                             <td>
                               {getJSXPerms(idPerfil, 2)}
                             </td>
                             <td>
                               {getJSXPerms(idPerfil, 3)}
                             </td>                                                          
                           </tr>  
                           <tr>
                             <td>
                               {getJSXPerms(idPerfil, 4)}
                             </td>
                             <td>
                               {getJSXPerms(idPerfil, 5)}
                             </td>
                             <td>
                               {getJSXPerms(idPerfil, 6)}
                             </td>                                                          
                           </tr>                                      
                         </table>


        setJsxPerm( newJsxPerm )
      }
    
  }, [idPerfil, loadingPerms]);   


  // ---------------------------------------------------------------------------

  
  const debugTable = lib.debug( 
                                  {
                                     debug: false,
                                     idPerfil: idPerfil,
                                     loadingPerms: loadingPerms,
                                     perms_length: perms.length
                                   }
                                )                                                
  
  return    <CrudTable border='1' title='PERMISSÕES' width='100%' showRequired='true'  modalBtClose={modalConstants.modalCodeNone}    debugTable={debugTable}>

               <tr width='100%'>
                 <td align='center'>
                   <table><tr> <td><CrudLabelRequired label='Perfil:'/></td>< td><CrudCombo rest={env.GEOTABREST_CRUD_PERFIL} pk='id_perfil' desc='tx_descricao' item={idPerfil} setItem={setIdPerfil} /></td> </tr></table>                                      
                 </td>
               </tr>           

               <tr>
                 <td align='center'>
                   {jsxPerm}
                 </td>
               </tr>         
               
               {
                 idPerfil == -1 ?
                 null
                 : 
                 <tr>
                   <td align='center'>
                     <table width='100%'>
                       <tr>
                         <td align='left'>
                           <p style={lib.OBSERVATION_STYLE} > OBSERVAÇÕES: </p>
                         </td>
                       </tr>
                       <tr>
                         <td>
                         <p style={lib.OBSERVATION_STYLE} > A) As permissões de visualização (tanto de camadas privadas quanto de camadas públicas) possuem precedência sobre todas as outras; </p>
                         </td>
                       </tr>                        
                       <tr>
                         <td>
                         <p style={lib.OBSERVATION_STYLE} > B) Se uma permissão de Baixar, Incluir, Alterar ou Excluir for atribuída a um database SEM nenhuma permissão de visualização (de camada pública ou privada) não funcionará; </p>
                         </td>
                       </tr>                     
                       <tr>
                         <td>
                         <p style={lib.OBSERVATION_STYLE} > C) Algumas alterações de permissão (como a de visualização de camadas) precisam de logout para funcionar em algumas telas; </p>
                         </td>
                       </tr>                                         
                     </table>
                     
                   </td>
                 </tr>                        
               }

           </CrudTable>                                                                      
}