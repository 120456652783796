export const HOST                            = 'geoserver.cpatc.embrapa.br' // Não sei se será útil aqui (talvez precise manter um arquivo .env com esse conteúdo)
export const DANGEROUSLY_DISABLE_HOST_CHECK  = 'true' // Não sei se será útil aqui (talvez precise manter um arquivo .env com esse conteúdo)


export const ENV        =  'pro'
export const APP_TITLE  =  'G E O T C'


export const GEOSERVER_PROTOCOL  =  'https://'
export const GEOSERVER_SERVER    =  'geoserver.cpatc.embrapa.br'
export const GEOSERVER_SERVICE   =  GEOSERVER_PROTOCOL + GEOSERVER_SERVER
export const GEOSERVER_WMS       =  GEOSERVER_SERVICE  + '/geoserver/geotab/wms'


export const FILEUPLOADREST_PROTOCOL                  =  'https://'
export const FILEUPLOADREST_SERVER                    =  'geoserver.cpatc.embrapa.br'
export const FILEUPLOADREST_PORT                      =  3333
export const FILEUPLOADREST_SERVICE                   =  FILEUPLOADREST_PROTOCOL + FILEUPLOADREST_SERVER + ':' + FILEUPLOADREST_PORT
export const FILEUPLOADREST_WITHOUT_USER              =  FILEUPLOADREST_SERVICE + '/upload'
export const GEOSERVERINSERT_WITHOUT_USER_DB_SUBTYPE  =  FILEUPLOADREST_SERVICE + '/insert'


export const GEOTABREST_PROTOCOL                              =  'https://'
export const GEOTABREST_SERVER                                =  'geoserver.cpatc.embrapa.br'
export const GEOTABREST_PORT                                  =  8888
export const GEOTABREST_SERVICE                               =  GEOTABREST_PROTOCOL + GEOTABREST_SERVER + ':' + GEOTABREST_PORT
export const GEOTABREST_INSERT_KEYWORD                        =  GEOTABREST_SERVICE + '/insertKeyWord'
export const GEOTABREST_PRJTEXT                               =  GEOTABREST_SERVICE + '/prjtext'
export const GEOTABREST_EPSG_FROM_HISTORY                     =  GEOTABREST_SERVICE + '/epsgFromHistory'
export const GEOTABREST_EPSG_FROM_INTERNET                    =  GEOTABREST_SERVICE + '/epsgFromInternet'
export const GEOTABREST_EPSGS_PERMITIDOS                      =  GEOTABREST_SERVICE + '/epsgsPermitidos'
export const GEOTABREST_EPSG_PERMITIDO                        =  GEOTABREST_SERVICE + '/epsgPermitido'
export const GEOTABREST_SLDS_PERMITIDOS                       =  GEOTABREST_SERVICE + '/sldsPermitidos'
export const GEOTABREST_LOGIN_REC                             =  GEOTABREST_SERVICE + '/loginrec'   // /loginrec/:tx_nome
export const GEOTABREST_LOGIN                                 =  GEOTABREST_SERVICE + '/login'
export const GEOTABREST_CATEGORIASCAMADAS                     =  GEOTABREST_SERVICE + '/categoriascamadas'
export const GEOTABREST_PERMISSAOEMBASE__PERFIL__ACAO         =  GEOTABREST_SERVICE + '/bases_nas_quais_o_perfil_pode_executar_a_acao'
export const GEOTABREST_GET_CAMADA_EXISTE                     =  GEOTABREST_SERVICE + '/nome_de_camada_existe'
export const GEOTABREST_INSERT_LAYER_GEOSERVER                =  GEOTABREST_SERVICE + '/insertLayerIntoGeoserver'
export const GEOTABREST_INSERT_LAYER_GEOTAB                   =  GEOTABREST_SERVICE + '/insertLayerIntoGEOTAB'
export const GEOTABREST_REMOVE_LAYER                          =  GEOTABREST_SERVICE + '/removeLayer' //  /removeLayer/:layer_name
export const GEOTABREST_REMOVE_LAYER_FROM_GEOSERVER           =  GEOTABREST_SERVICE + '/removeLayerFromGeoServer'      //  /removeLayerFromGeoServer/:layer_name
export const GEOTABREST_REMOVE_LAYER_STYLE_FROM_GEOSERVER     =  GEOTABREST_SERVICE + '/removeLayerStyleFromGeoServer' //  /removeLayerStyleFromGeoServer/:layer_name
export const GEOTABREST_REMOVE_LAYER_FROM_GEOTC               =  GEOTABREST_SERVICE + '/removeLayerFromDatabaseGeotab' //  /removeLayerFromDatabaseGeotab/:layer_name
export const GEOTABREST_UPDATE_LAYER_GEOTAB                   =  GEOTABREST_SERVICE + '/updateLayerBounds'
export const GEOTABREST_UPDATE_LAYER_ON_GEOTC                 =  GEOTABREST_SERVICE + '/updateLayerOnGeoTC'
export const GEOTABREST_INSERT_HISTORICOPRJ                   =  GEOTABREST_SERVICE + '/historico_prj'
export const GEOTABREST_GET_LAYER                             =  GEOTABREST_SERVICE + '/getLayer'
export const GEOTABREST_GET_LAYER_FULL                        =  GEOTABREST_SERVICE + '/getLayerFull'  // /getLayerFull/:id
export const GEOTABREST_GET_LAYERS_FROM_KINDS                 =  GEOTABREST_SERVICE + '/getLayersFromKinds'
export const GEOTABREST_GET_LAYERS_FROM_CODES                 =  GEOTABREST_SERVICE + '/getLayersWithCodes'
export const GEOTABREST_GET_LAYER_CODE                        =  GEOTABREST_SERVICE + '/layerCode'
export const GEOTABREST_GET_LAYERS_CODES                      =  GEOTABREST_SERVICE + '/layerCodes'
export const GEOTABREST_INSERT_GRADUACOES_DO_USUARIO          =  GEOTABREST_SERVICE + '/insertGraduacoesDoUsuario'
export const GEOTABREST_INSERT_AREAS_DE_INTERESSE_DO_USUARIO  =  GEOTABREST_SERVICE + '/insertAreasDeInteresseDoUsuario'
export const GEOTABREST_GET_CAMADAS_DA_CATEGORIA              =  GEOTABREST_SERVICE + '/camadasdacategoria' //  '/camadasdacategoria/:categoria'
export const GEOTABREST_GET_CAMADAS                           =  GEOTABREST_SERVICE + '/camadas' //  '/camadas/:perfil'
export const GEOTABREST_TREEVIEWDATA                          =  GEOTABREST_SERVICE + '/treeviewdata'
export const GEOTABREST_ADD_STYLE                             =  GEOTABREST_SERVICE + '/addStyle' // { id_usuario, layer_name, sld_content_type } = request.body
export const GEOTABREST_LINK_STYLE                            =  GEOTABREST_SERVICE + '/linkStyleToLayer' // /linkStyleToLayer/:layer_name
export const GEOTABREST_GET_FEATURE_INFO                      =  GEOTABREST_SERVICE + '/getFeatureInfo'
export const GEOTABREST_CRUD_PERFIL                           =  GEOTABREST_SERVICE + '/perfil'
export const GEOTABREST_CRUD_USUARIO                          =  GEOTABREST_SERVICE + '/usuario'
export const GEOTABREST_USUARIO_ASK_RECOVERY                  =  GEOTABREST_SERVICE + '/usuarioAskRecovery'  // sudo curl -X PUT -H "Content-Type: application/json"  -d '{"tx_nome":"admsolo", "tx_email": "admsolo@teste.com"}' desenv.cpatc.embrapa.br:8888/usuarioAskRecovery
export const GEOTABREST_USUARIO_RECOVERY                      =  GEOTABREST_SERVICE + '/usuarioRecovery'  // sudo curl -X PUT -H "Content-Type: application/json"  -d '{"id_usuario":"73", "tx_password": "nova"}' desenv.cpatc.embrapa.br:8888/usuarioRecovery
export const GEOTABREST_USUARIO__TOGGLE__BL_RECUPERAR_SENHA   =  GEOTABREST_SERVICE + '/usuario__toggle__bl_recuperar_senha' // /usuario__toggle__bl_recuperar_senha/:id
export const GEOTABREST_CRUD_GRADUACAO                        =  GEOTABREST_SERVICE + '/graduacao'
export const GEOTABREST_CRUD_CNPQ_GRANDE_AREA                 =  GEOTABREST_SERVICE + '/cnpq_grande_area'
export const GEOTABREST_CRUD_AREAS_DE_ATUACAO                 =  GEOTABREST_SERVICE + '/areas_de_atuacao'
export const GEOTABREST_AREAS_DE_ATUACAO_NAO_SELECIONADAS     =  GEOTABREST_SERVICE + '/areas_de_atuacao_nao_selecionadas_pelo_usuario' //  /areas_de_atuacao_nao_selecionadas_pelo_usuario/:id_usuario
export const GEOTABREST_CRUD_AREAS_DE_INTERESSE               =  GEOTABREST_SERVICE + '/areas_de_interesse'
export const GEOTABREST_AREAS_DE_INTERESSE_NAO_SELECIONADAS   =  GEOTABREST_SERVICE + '/areas_de_interesse_nao_selecionadas_pelo_usuario' //  /areas_de_interesse_nao_selecionadas_pelo_usuario/:id_usuario
export const GEOTABREST_CRUD_BASE_DE_DADOS                    =  GEOTABREST_SERVICE + '/base_de_dados'
export const GEOTABREST_BASE_DE_DADOS__ORDER                  =  GEOTABREST_SERVICE + '/base_de_dados__order'  // /base_de_dados__order/:in_order/:in_direction
export const GEOTABREST_BASE_DE_DADOS__POR_CAMADA             =  GEOTABREST_SERVICE + '/base_de_dados_por_camada' //  /base_de_dados_por_camada/:id_camada
export const GEOTABREST_CRUD_ACAO                             =  GEOTABREST_SERVICE + '/acao'
export const GEOTABREST_VWPERMISSAO                           =  GEOTABREST_SERVICE + '/vwpermissao'
export const GEOTABREST_PERMISSAO__TOGGLE                     =  GEOTABREST_SERVICE + '/permissao__toggle' // /permissao__toggle/:id_perfil/:id_base_de_dados/:id_acao
export const GEOTABREST_TABLE__ORDER                          =  GEOTABREST_SERVICE + '/table__order'  // /table__order/:table/:in_order/:in_direction
export const GEOTABREST_CRUD_CATEGORIA_CAMADA                 =  GEOTABREST_SERVICE + '/categoria_camada'
export const GEOTABREST_CATEGORIA_CAMADA_POR_DATABASE         =  GEOTABREST_SERVICE + '/categoria_camada_por_database'
export const GEOTABREST_CATEGORIA_CAMADA_POR_PAI              =  GEOTABREST_SERVICE + '/categoria_camada_por_pai'
export const GEOTABREST_CATEGORIA_CAMADA_POR_PAI_SEM_VISAO    =  GEOTABREST_SERVICE + '/categoria_camada_por_pai_sem_visao'
export const GEOTABREST_DATABASES_POR_PERFIL_E_ACAO           =  GEOTABREST_SERVICE + '/databases__por_perfil_e_acao'  // /databases__por_perfil_e_acao/:id_perfil/:id_acao'
export const GEOTABREST_CATEGORIA_CAMADA_SEM_RAIZES           =  GEOTABREST_SERVICE + '/categoria_camada_sem_raizes'
export const GEOTABREST_CATEGORIA_CAMADA_ORDER                =  GEOTABREST_SERVICE + '/categoria_camada__order' //  '/categoria_camada__order/:id_categoria_camada_pai/:in_order/:in_direction'
export const GEOTABREST_CAMADA_ORDER                          =  GEOTABREST_SERVICE + '/camada__order' //  '/camada__order/:id_base_de_dados/:id_categoria_camada/:in_order/:in_direction'
export const GEOTABREST_CRUD_CAMADA                           =  GEOTABREST_SERVICE + '/camada' //  '/camada/:id_base_de_dados/:id_categoria_camada'
export const GEOTABREST_STAT_CAMADAS_POR_BASE                 =  GEOTABREST_SERVICE + '/stat_camadas_por_database'
export const GEOTABREST_STAT_PERFIL_POR_USUARIO               =  GEOTABREST_SERVICE + '/stat_perfil_por_usuario'
export const GEOTABREST_STAT_CAMADA_POR_TIPO                  =  GEOTABREST_SERVICE + '/stat_camada_por_tipo'
export const GEOTABREST_STAT_ACESSOS_POR_USUARIO              =  GEOTABREST_SERVICE + '/stat_acessos_por_usuario'