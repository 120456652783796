import React, { useEffect, useState, useContext } from 'react';
import axios from "axios";
import { Square } from '@styled-icons/fa-solid/Square'
import { FaSquare, FaLongArrowAltRight, FaAngleLeft, FaAngleDoubleLeft, FaAngleRight, FaAngleDoubleRight, FaAngleDown, FaAngleUp, FaAngleDoubleUp, FaAngleDoubleDown } from 'react-icons/fa';
import { IoIosCloseCircle } from 'react-icons/io';
import styled from 'styled-components';
import validate from 'validate.js';
import { css } from 'glamor';
import ModalContext, { modalCodeNone } from './../context/ModalContext'
import * as modalConstants from './../context/ModalContext'
import 'bootstrap/dist/css/bootstrap.min.css';


// ###############################################################
// Relacionadas ao BootstrapTable -- INÍCIO
// ###############################################################


// Altura do menu principal, e também do rodapé, presente em todas as páginas)
// Esse dado é importante para não ter conteúdo oculto atrás deles
export const HEADER_FOOTER_HEIGHT = 60;

export const BUTTON_SIZE = 30

// export const EMBRAPA_VERDE_COLOR = '#cfc'

export const EMBRAPA_VERDE_SUAVE = '#77BD8B'

// Verde capturado do logotipo da Embrapa


export const EMBRAPA_VERDE_COLOR = '#378254'
// export const EMBRAPA_VERDE_COLOR = '#8CBA51'
// export const EMBRAPA_VERDE_COLOR = '#378254'


// Azul capturado do logotipo da Embrapa
export const EMBRAPA_AZUL_COLOR = '#385b9b';

export const EMBRAPA_AZUL_CLARO = '#AFCFEA';

export const ERROR_COLOR = '#f00'

export const ERROR_COLOR_SUAVE = '#C99'



// export const CINZA_PADRAO_COLOR = '#f8f9fa'

// export const TOGGLE_COLOR = '#DAE4E5'

// export const TOGGLE_COLOR = '#B7D4FF'

// export const TOGGLE_COLOR = '#BDCCFF'

export const TOGGLE_COLOR = '#AFCFEA'

export const ORDER_ICON_SIZE = 15

export const ORDER_COLOR = EMBRAPA_VERDE_COLOR

// export const TOGGLE_COLOR = '#DCE5E7'

export const CINZA_PADRAO_COLOR = '#CCC'

export const VERMELHO_ERRO_COLOR = '#C99'

export const REQUIRED_COLOR = '#C99'

export const BOTAO_FECHAR_COLOR = '#C99'

export const FUNDO_CABECALHO_COLOR = '#f8f9fa'

export const DARK_RED_COLOR = '#600';

export const FOOTER_STYLE = {fontSize:12,color:EMBRAPA_AZUL_COLOR,fontWeight: 'bold'}

export const MESSAGE_STYLE = {fontSize:10,color:'gray',fontWeight: 'bold'}

export const OBSERVATION_STYLE = {fontSize:10,color:ERROR_COLOR,fontWeight: 'bold'}

export const LOCALSTORAGE_SELECTED_LAYERS = '@GEOTAB/selected_layers'









export const BREADCRUMB_FONT_SIZE = '12px'

export const LEGEND_FONT_SIZE = '11px'



export const ERROR_FONT_SIZE = '12px'

export const ERROR_FONT_WEIGHT = 'bold'




export const GRID_WIDTH = 550

export const GRID_QTD_LINHAS_VALUE = 10

export const GRID_QTD_LINHAS_LABEL = '10'

export const GRID_COLUMN_NEW_WIDTH = '32px'

export const GRID_COLUMNS_EDT_DEL_WIDTH = '35px'

export const GRID_BUTTON_NEW_COLOR = 'lightgreen'

// export const GRID_BUTTON_EDT_COLOR = '#F7DC6F'

export const GRID_BUTTON_EDT_COLOR = '#CC9'

export const GRID_BUTTON_DEL_COLOR = 'pink'

export const GRID_BUTTON_SIZE = 15

export const GRID_PAGINATION_REPORT_FONT_SIZE = '12px'

export const GRID_PAGINATION_SIZE = 4

export const GRID_PAGINATION_START_INDEX = 1

export const GRID_PAGINATION_BUTTON_SIZE = 11



export const EDIT_FONT_SIZE = '13px'

export const EDIT_FONT_WEIGHT = 'normal'

export const EDIT_BUTTON_SAVE_SIZE = 30

export const EDIT_BUTTON_SAVE_COLOR = '#066'

export const IMG_REQUIRED_SIZE = 10

export const IMG_REQUIRED = <FaSquare color='red' size={IMG_REQUIRED_SIZE}/>

export const IMG_REQUIRED_EMPTY = <FaSquare color='white' size={IMG_REQUIRED_SIZE}/>








// ESTILOS GERAIS
// -----------------------------------------------------------------------------------------------

export const DEFAULT_FONT_SIZE = 10;

export const PAGINATION_CURRENT_LINK = {  
  backgroundColor: '#0074c2',
  display: 'inline-block',
  color: '#FFFFFF',
  'a:link': { color: '#FFFFFF' },
  'a:visited': { color: '#FFFFFF' },
  'a:active': { color: '#FFFFFF' }
}

// O código da variável abaixo (toastStyle) usa as blibiotecas: glamor e toast
// export const toastStyle = {
//   className: css({
//     backgroundColor: 'black'
//   }),
//   bodyClassName: css({
//     fontSize: '12px',
//     color: 'white',
//     backgroundColor: VERMELHO_ERRO_COLOR
//   }),
//   progressClassName: css({
//     background: 'repeating-radial-gradient(#C99 100%, #C99 100%)'
//   }),
//   position: toast.POSITION.TOP_CENTER
// }
// sobre progressClassName (acima): gradiente de cores iguais significa não ter gradiente

// EMBRAPA_VERDE_COLOR = '#378254'
// EMBRAPA_AZUL_COLOR = '#385b9b';
// VERMELHO_ERRO_COLOR = '#C99'

export const PAGINATION_PAGE_LINK = {  
  margin: '2px',
  display: 'inline-block',
  padding: '2px',
  WebkitBorderRadius: '20px',
  MozBorderRadius: '20px',
  borderRadius: '20px'
}

export const DEFAULT_FONT_SIZE_ERROR = 10;

export const MAP_TABLE = {paddingLeft:10, paddingRight:10, border:'1px solid ' + CINZA_PADRAO_COLOR, borderRadius:'15px', borderCollapse:'separate'}

export const TABLE_CONTAINER = {backgroundColor:'#DAE4E5', paddingLeft:5, paddingRight:5, paddingBottom:5, border:'1px solid ' + CINZA_PADRAO_COLOR, borderRadius:'15px', borderCollapse:'separate'}

export const MAP_TABLE_DASHED = {paddingLeft:20, paddingRight:20, border:'0.5px dashed ' + CINZA_PADRAO_COLOR, borderRadius:'15px', borderCollapse:'separate'}

export const LI_DASHED = {listStyleType: 'none', paddingBottom:0, paddingLeft:20, paddingRight:20, border:'0.5px dashed ' + CINZA_PADRAO_COLOR, borderRadius:'15px', borderCollapse:'separate'}

export const DASHED_TABLE = {paddingLeft:20, paddingRight:20, border:'2px dashed ' + CINZA_PADRAO_COLOR, borderRadius:'15px', borderCollapse:'separate'}

export const LEGEND_TABLE = {paddingLeft:20, paddingRight:20, border:'1px solid ' + CINZA_PADRAO_COLOR, borderRadius:'25px', borderCollapse:'separate'}

export const DASHED_TABLE_DEBUG = {paddingLeft:20, paddingRight:20, border:'2px dashed RED', borderRadius:'15px', borderCollapse:'separate'}

export const BUTTON = {background:EMBRAPA_VERDE_COLOR, borderRadius:15}

// -----------------------------------------------------------------------------------------------



// PERMISSÕES
// -----------------------------------------------------------------------------------------------

export const TIPO_CAMADA_PUBLICA = 1;
export const TIPO_CAMADA_PRIVADA = 2;

export const PERFIL_ADMIN       = 1;
export const PERFIL_DBADMIN     = 2;
export const PERFIL_COLABORADOR = 3;
export const PERFIL_PUBLICO     = 4;

export const BASE_SOLOSTAB   = 1;
export const BASE_HIDROTAB   = 2;
export const BASE_CLIMATAB   = 3;
export const BASE_AGROTAB    = 4;
export const BASE_ANALISETAB = 5;
export const BASE_COMUNTAB   = 6;

export const ACAO_VISUALIZAR_CAMADAS_PRIVADAS   = 0;
export const ACAO_VISUALIZAR_CAMADAS_PUBLICAS   = 1;
export const ACAO_BAIXAR_CAMADAS                = 2;
export const ACAO_INCLUIR_CAMADAS               = 3;
export const ACAO_ALTERAR_CAMADAS               = 4;
export const ACAO_EXCLUIR_CAMADAS               = 5;
export const ACAO_VISUALIZAR_ESTATISTICAS       = 6;


export const HEADER_DEFAULT = 0
export const HEADER_HOME    = 0
export const HEADER_VIEW    = 1
export const HEADER_UPLOAD  = 2
export const HEADER_STAT    = 3
export const HEADER_ADM     = 4
// -----------------------------------------------------------------------------------------------


export const CrudDiv = styled.div`  
  background: #f8f9fa;  
`;

// Deve estar sincronizado com CRUD_LABEL
export const CrudLabelStyle = styled.h1`
  font-size: 10px;
  text-align: right;
  color: #385b9b;   
`;

export const CrudSectionLabelStyleMain = styled.h1`
  font-size: 12px;
  text-align: center;
  color: #385b9b;   
`;

export const CrudSectionLabelStyleLeft = styled.h1`
  font-size: 10px;
  text-align: left;
  color: #385b9b;   
`;

export const CrudSectionLabelStyleRight = styled.h1`
  font-size: 10px;
  text-align: right;
  color: #385b9b;   
`;

export function CrudLabel(props){
  return <CrudLabelStyle>
            {props.label}
         </CrudLabelStyle>
}

// Parece que a própria documentação já relata que o size tem que ser configurado no próprio componente: Observe o componente abaixo (CrudLabelRequired)
// export const Required = styled(Square)`  
//   color: #C99;    
// `;

export const Req = styled(Square)`
    color: ${props => props.color || "#C99"};
`;

export function Required(props){
  return <Req size='10'/>
}

export function TransparentRequired(props){
  return <Req size='10' color='transparent'/>
}

// OBSERVAÇÃO: Para liberdade total com uso o Req,
// sem utilizar o Required ou o TransparentRequired. Exemplo:
// <Req color='green' size='20' />

export function CrudLabelRequired(props){
  return <CrudLabelStyle>
           <Required /> {props.label}
         </CrudLabelStyle>
}


export function CrudLine(){
  return <hr style={{ borderTop: '1px dotted gray'}} />
};


export function CrudSection( props ){
  return <div>           
           {/* <CrudLine/>            */}

           <p/>

             <center>
               <table width='100%'>
                 <tbody>
                   <tr>
                     <td>
                       <CrudSectionLabelStyleLeft>{props.left}</CrudSectionLabelStyleLeft>
                     </td>
                     <td>
                       <CrudSectionLabelStyleMain>{props.main}</CrudSectionLabelStyleMain>
                     </td>
                     <td>
                       <CrudSectionLabelStyleRight>{props.right}</CrudSectionLabelStyleRight>
                     </td>                                      
                   </tr>
                 </tbody>
               </table>               
             </center> 

             <div style={{fontSize: DEFAULT_FONT_SIZE}}>
               {props.children}                                 
             </div>             

           {/* <CrudLine/> */}
         </div> 
};

export const CrudInputText = styled.input`  
  font-size: 13px;
  color: black;
  background:white;    
  border: 0.5px solid #ccc;
  border-radius: 3px;
`;

export const CrudBtVerde = styled.button`  
  background: ${props => props.disabled ? "#f8f9fa" : "#378254"};
  color: ${props => props.disabled ? "#ccc" : "white"};
  font-size: 12px;  
  border: none;
  border-radius: 25px;
  height: 30px;  
  value: 'verde';
  padding: 2px 8px;    
`;

export const CrudBtAzul = styled.button`  
  background: ${props => props.disabled ? "#f8f9fa" : "#385b9b"};
  color: ${props => props.disabled ? "#ccc" : "white"};
  font-size: 12px;  
  border: none;
  border-radius: 25px;
  height: 30px;  
  value: 'verde';
  padding: 2px 8px;    
`;


export const CrudBtVermelho = styled.button`  
  background: ${props => props.disabled ? "#f8f9fa" : "#C99"};
  color: ${props => props.disabled ? "#ccc" : "white"};
  font-size: 12px;  
  border: none;
  border-radius: 25px;
  height: 30px;  
  value: 'verde';
  padding: 2px 8px;    
`;

export const CrudBtVermelhoPequeno = styled.button`  
  background: ${props => props.disabled ? "#f8f9fa" : "#C99"};
  color: ${props => props.disabled ? "#ccc" : "white"};
  font-size: 10px;  
  border: none;
  border-radius: 20px;
  height: 20px;  
  value: 'verde';
  padding: 2px 6px;    
`;


export const CrudBtCinza = styled.button`  
  background: ${props => props.disabled ? "#f8f9fa" : "#ccc"};
  color: ${props => props.disabled ? "#ccc" : "white"};
  font-size: 12px;  
  border: none;
  border-radius: 25px;
  height: 30px;  
  value: 'verde';
  padding: 2px 8px;    
`;



export const DualListBoxLanguage = {
  availableFilterHeader: 'Filtro disponível',
  availableHeader: 'Disponível',
  moveLeft: 'Mova para a esquerda',
  moveAllLeft: 'Move TODOS para a esquerda',
  moveRight: 'Mova para a direita',
  moveAllRight: 'Mova TODOS para a direita',
  moveDown: 'Mova para baixo',
  moveUp: 'Mova para cima',
  noAvailableOptions: 'Sem opções disponíveis',
  noSelectedOptions: 'Sem opções selecionadas',
  selectedFilterHeader: 'Filtro selecionado',
  selectedHeader: 'Selecionado',
}

export const DualListBoxIconsLeftRight={
  moveLeft: <FaAngleLeft  color='#385b9b'  size='17' />,
  moveAllLeft: <FaAngleDoubleLeft  color='#385b9b'  size='17' />,
  moveRight: <FaAngleRight  color='#385b9b'  size='17' />,
  moveAllRight: <FaAngleDoubleRight  color='#385b9b'  size='17' />,
  moveDown: <FaAngleDown  color='#385b9b'  size='17' />,
  moveUp: <FaAngleUp  color='#385b9b'  size='17' />,
}

// Alternativa para quando a tela for pequena e as caixas estiverem na vertical (ao invés da horizontal, que é o padrão)
export const DualListBoxIconsUpDown={
  moveLeft: <FaAngleUp  color='#385b9b'  size='17' />,
  moveAllLeft: <FaAngleDoubleUp  color='#385b9b'  size='17' />,
  moveRight: <FaAngleDown  color='#385b9b'  size='17' />,
  moveAllRight: <FaAngleDoubleDown  color='#3ReactDOM85b9b'  size='17' />,
  moveDown: <FaAngleDown  color='#385b9b'  size='17' />,
  moveUp: <FaAngleUp  color='#385b9b'  size='17' />,
}



export const LABEL = {fontSize:15,color:EMBRAPA_AZUL_COLOR,fontWeight: 'bold'}

export const CRUD_TABLE = {marginLeft:'auto', marginRight:'auto', paddingLeft:20, paddingRight:20, border:'1px dashed ' + EMBRAPA_VERDE_COLOR, borderRadius:'10px', borderCollapse:'separate'}



// Deve estar sincronizado com CrudTitle
export const CRUD_TITLE = {fontSize:15,color:EMBRAPA_AZUL_COLOR,fontWeight: 'bold'}

export const CRUD_FIXED_MESSAGE = {fontSize:10,color:'gray',fontWeight: 'bold'}

export const CRUD_TITLE_MENOR = {fontSize:12,color:EMBRAPA_AZUL_COLOR,fontWeight: 'bold'}

export const CRUD_TITLE_VERDE = {...CRUD_TITLE, color:EMBRAPA_VERDE_COLOR}

export const CRUD_TITLE_AZUL = {...CRUD_TITLE, color:EMBRAPA_AZUL_COLOR}



// Deve estar sincronizado com CrudLabel
export const CRUD_LABEL = {fontSize:10,color:EMBRAPA_AZUL_COLOR,fontWeight: 'bold'}

export const CRUD_LABEL_AZUL = {...CRUD_LABEL, color:EMBRAPA_AZUL_COLOR}

export const CRUD_LABEL_VERDE = {...CRUD_LABEL, color:EMBRAPA_VERDE_COLOR}



export const CRUD_TEXT = {fontSize:DEFAULT_FONT_SIZE,color:'black',fontWeight: 'normal'}

// -----------------------------------------------------------------------------------------------



// ESTILOS DA SEÇÃO DE LOGIN
// -----------------------------------------------------------------------------------------------

export const LOGIN_ICON_SIZE = 35;

export const LOGIN_TITLE_CINZA = {fontSize:DEFAULT_FONT_SIZE-1,color:CINZA_PADRAO_COLOR,fontWeight: 'bold'}

export const LOGIN_TITLE_VERMELHO = {fontSize:DEFAULT_FONT_SIZE-1,color:VERMELHO_ERRO_COLOR,fontWeight: 'bold'}

export const LOGIN_TITLE = {fontSize:DEFAULT_FONT_SIZE-1,color:EMBRAPA_AZUL_COLOR,fontWeight: 'bold'}

export const LOGIN_VALUE = {fontSize:DEFAULT_FONT_SIZE-1,color:EMBRAPA_VERDE_COLOR,fontWeight: 'normal'}



export const DESC_MAP_TITLE = {fontSize:DEFAULT_FONT_SIZE-2,color:EMBRAPA_AZUL_COLOR,fontWeight: 'bold'}

export const DESC_MAP_VALUE = {fontSize:DEFAULT_FONT_SIZE-2,color:EMBRAPA_VERDE_COLOR,fontWeight: 'normal'}

// -----------------------------------------------------------------------------------------------



// ESTILOS DA TELA DE UPLOAD
// -----------------------------------------------------------------------------------------------

export const UPLOAD_DEFAULT_FONT_SIZE = '10'

export const UPLOAD_MESSAGE = {fontSize:UPLOAD_DEFAULT_FONT_SIZE,color:EMBRAPA_AZUL_COLOR,fontWeight: 'normal'}

export const UPLOAD_PROGRESS_MESSAGE = {fontSize:UPLOAD_DEFAULT_FONT_SIZE,color:EMBRAPA_AZUL_COLOR,fontWeight: 'bold'}

export const UPLOADED_FILES_TITLE = {fontSize:12,color:EMBRAPA_VERDE_COLOR,fontWeight: 'bold'}

export const UPLOADED_FILES_DESC = {fontSize:10,color:EMBRAPA_VERDE_COLOR,fontWeight: 'normal'}

export const REJECTED_FILES_TITLE = {fontSize:12,color:VERMELHO_ERRO_COLOR,fontWeight: 'bold'}

export const REJECTED_FILES_DESC = {fontSize:10,color:VERMELHO_ERRO_COLOR,fontWeight: 'normal'}

export const LOADING_INTERFACE = {fontSize:12,color:VERMELHO_ERRO_COLOR,fontWeight: 'bold'}

export const LOGIN_FORM = {margin: '0 auto', maxWidth: '320px', color:EMBRAPA_AZUL_COLOR}

// -----------------------------------------------------------------------------------------------







//<span className='react-bootstrap-table-pagination-total' style={{fontSize: '12px', color:'darkblue'}} ></span>
const BOOTSTRAPTABLE_TOTAL_DESCRIPTION = (from, to, size) => (
  <span className='react-bootstrap-table-pagination-total' style={{fontSize: GRID_PAGINATION_REPORT_FONT_SIZE}} >     
       Registros { from } a { to } de { size }.     
  </span>
);

export const BOOTSTRAPTABLE_OPTIONS = {
  paginationSize: GRID_PAGINATION_SIZE,
  pageStartIndex: GRID_PAGINATION_START_INDEX,

  alwaysShowAllBtns: false, // Always show next and previous button
  withFirstAndLast: true, // Hide the going to First and Last page button

  // firstPageText: <FaFastBackward color='red' size={GRID_PAGINATION_BUTTON_SIZE}/>,
  // prePageText: <FaChevronCircleLeft color='blue' size={GRID_PAGINATION_BUTTON_SIZE}/>,  
  // nextPageText: GRID_PAGINATION_BUTTON_NEXT,  
  // lastPageText: <FaFastForward color='blue' size={GRID_PAGINATION_BUTTON_SIZE}/>,

  firstPageText: 'Primeiro',
  prePageText: 'Anterior',  
  nextPageText: 'Próximo',  
  lastPageText: 'Último',

  nextPageTitle: 'Próxima página',
  prePageTitle: 'Página anterior',
  firstPageTitle: 'Primeira página',
  lastPageTitle: 'Última página',

  hidePageListOnlyOnePage: true,
  showTotal: true,
  hideSizePerPage: true, // Ocultado, também, por não conseguir editar estilo
  paginationTotalRenderer: BOOTSTRAPTABLE_TOTAL_DESCRIPTION,
  sizePerPageList: [
                     //  {text: 'Tudo', value: products.length},
                     //  {text: '8',  value: 8}, 
                     {text: GRID_QTD_LINHAS_LABEL, value: GRID_QTD_LINHAS_VALUE}
                   ]  
};

// ###############################################################
// Relacionadas ao BootstrapTable -- TÉRMINO
// ###############################################################



///////////////////////////////////////////////////////////////////
// FUNÇÕES - INÍCIO
///////////////////////////////////////////////////////////////////


// Relacionadas a texto -- INÍCIO ----------------------------------------------

export function capitalize(s) {
  if (typeof s !== 'string') 
    return ''

  return s.charAt(0).toUpperCase() + s.slice(1)
}    


export function StringPossuiApenasLetrasNumerosESublinhado(str){  

  let caracteresPermitidos = 'ABCDEFGHIJKLMNOPQRSTUVWYXZ_abcdefghijklmnopqrstuvwyxz0123456789'
  for (let pos = 0; pos < str.length; pos ++)
    if ( caracteresPermitidos.indexOf( str[pos] ) === -1 )
       return false

  return true
}

  // Função que conta as ocorrências de uma substring dentro de uma string
  export function qtdSubstrOnStr(str, substr){
    let count = 0;
    let pos = str.indexOf(substr);
    
    while ( pos != -1 ) {
       count++;
       pos = str.indexOf( substr, pos + 1 );
    }
    
    return count
  }  

// Relacionadas a texto -- TÉRMINO ---------------------------------------------



// Relacionadas a arquivo -- INÍCIO ---------------------------------------------

// Função que retorna o nome de um arquivo sem sua extensão
export function fileNameWithoutExtension(fileName){  
  let posdot = fileName.indexOf('.');
  return fileName.substr(0, posdot)
}    

// Função que retorna a extensão de um arquivo
export function fileExtension(fileName){  
  let posdot = fileName.indexOf('.');
  return fileName.substr(posdot + 1)
}      

export function pretty_size(size_in_bytes){
  let unit  = ' Bytes'
  let value = size_in_bytes
  
  if (value > 1023) {
    value = value / 1024
    unit = ' KBytes'    
  }

  if (value > 1023) {
    value = value / 1024
    unit = ' Megabytes'    
  }

  if (value > 1023) {
    value = value / 1024
    unit = ' Gigabytes'    
  }  

  if (value > 1023) {
    value = value / 1024
    unit = ' Terabytes'    
  }    
  
  if (value > 1023) {
    value = value / 1024
    unit = ' Petabytes'    
  }    
  
  return value.toFixed(2) + unit
}

// Relacionadas a arquivo -- TÉRMINO ---------------------------------------------



// Relacionadas a vetor -- INÍCIO ---------------------------------------------


export function getElementOnArray(elem, array, array_field_to_search){
  if (array == null)
    return null

  if (array.length == 0)
    return null

  for (let i = 0; i <= array.length; i++)
    if (array[i][array_field_to_search] = elem)
      return array[i]

  return null              
}

// Relacionadas a vetor -- TÉRMINO ---------------------------------------------


// // Relacionadas a DATA -- INÍCIO ---------------------------------------------

export  function yyyymmdd( date = new Date() ) {    
  var mm = date.getMonth() + 1 // getMonth() is zero-based
  var dd = date.getDate()

  return [date.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('')
}


export function yyyymmddhhmmssms( date = new Date() ) {    
  var yyyy = date.getFullYear().toString();
  var MM   = String( date.getMonth() + 1    ).padStart(2, '0')
  var dd   = String( date.getDate()         ).padStart(2, '0')
  var hh   = String( date.getHours()        ).padStart(2, '0')
  var mm   = String( date.getMinutes()      ).padStart(2, '0')
  var ss   = String( date.getSeconds()      ).padStart(2, '0')
  var ms   = String( date.getMilliseconds() ).padStart(2, '0')

  return yyyy + MM + dd+  hh + mm + ss + ms;
};

// // Relacionadas a DATA -- TÉRMINO ----------------------------------------------




// Relacionadas ao VALIDATION.JS -- INÍCIO -------------------------------------


export const validations = {
  selected: {
    exclusion: {
      within: {"-1": "Não Selecionado"},
      message: "Necessário selecionar!"
    }
  },  
  required: {
    presence: {        
      message: '^Campo obrigatório!',
      allowEmpty: false // Para desconsiderar espaços e texto vazio
    },      
  },     
  validEmailRequired: {
    presence: {
      message: '^E-mail obrigatório!',
      allowEmpty: false // Para desconsiderar espaços e texto vazio
    },    
    email: {
      message: '^E-mail inválido!'
    }
  },      
  passReqMin6: {
    presence: {
      message: '^Password obrigatório!',
      allowEmpty: false // Para desconsiderar espaços e texto vazio
    },
    length: {
      minimum: 6,
      message: '^O password deve ter pelo menos seis caracteres'
    }
  },
  onlyLettersWithoutAccentsAndNumbers: {
    format: {
      pattern: '[a-z0-9]+',
      flags: 'i',
      message: '^Apenas letras sem acento e números são permitidos!'
    }
  },
  onlyLettersWithoutAccents: {
    format: {
      pattern: '[a-z]+',
      flags: 'i',
      message: '^Apenas letras sem acento são permitidas!'
    }
  },
  onlyLetters: {
    format: {
      pattern: '[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZÀÁÂÃÄÅÆÇÊÈÉÊËÌÍÎÏàáâãäåæçêèéêëìíîïÐÑÒÓÔÕÖØÙÚÛÜÝÞßðñòóôõöøùúûüýþÿ]+',
      flags: 'i',
      message: '^Apenas letras são permitidas!'
    }
  }
}



export function valid( value, setValidation, validation ) {  

  let validation_result = validate.single( value, validations[validation], {format: "flat"})  

  let msg = validation_result === undefined ? '' : validation_result[0]
  
  setValidation(msg)  

  return msg
}  


export function validKeyUpAlert( event, value, setValue, validation ) {  

  let validation_result = validate.single( value, validations[validation], {format: "flat"})  

  let msg = validation_result === undefined ? '' : validation_result[0]
  
  if (msg !== ''){
    event.preventDefault()
    
    setValue( value.substring(0,value.length - 1) )

    if (value !== '')
      alert(msg)
  }
     
  return msg
}  


// Verificar posteriormente se essa função realmente deve ficar nesse bloco
export function msg(msgs, error) {
        
  for (var i = 0; i < msgs.length; i++){
    let constraint = msgs[i].constraint
    // Se achou a constraint nos erros
    if (error.indexOf(constraint) !== -1) {
      return msgs[i].msg
    }
  }    

  return "Erro. Notifique o NTI."
}


// Relacionadas ao VALIDATION.JS -- TÉRMINO ------------------------------------




// Relacionadas ao bootstrap -- INÍCIO -------------------------------------

// Monta breadcrumbs através dos JSONs
// export function breadcrumbs(bcs) {    

//   const last_index = bcs.length - 1

//   const items = bcs.map( (bc, i) => {
//       if (i === last_index) {
//         return <Breadcrumb.Item active href={bc.route}> {bc.label} </Breadcrumb.Item>
//       }
//       else{
//         return <Breadcrumb.Item href={bc.route}> {bc.label} </Breadcrumb.Item>
//       }       
//     })

//   return <Breadcrumb> {items} </Breadcrumb>
// }  


// Relacionadas ao bootstrap -- TÉRMINO -------------------------------------




// Relacionadas a styled-components -- INÍCIO ----------------------------------

export const CrudTableStyle = styled.table`
  th {     
    padding-top: 10px;
    padding-bottom: 10px;    
    background: #f8f9fa;     
    color: #378254;  
    font-size: 12px;
    text-align: center;  
    font-weight: bold;  
    border-radius: 10px;        
  };
  td {     
    background: white; 
    color: black;  
    font-size: 10px; 
    font-weight: normal;  
  };  
  border-spacing: 20px 5px;  
  border: 1px solid #ccc;  
  background-color:white;  
  border-radius: 20px;
  border-collapse: separate;  
`;


export function CrudContainer(props){

  const { modal, setModal } = useContext(ModalContext)

  function beforeUnload(){
    // if ( ( modal.code == modalConstants.modalCodePasswordForget )  ||  ( modal.code == modalConstants.modalCodeUsuarioRegister )  ) {
    //   setModal( { code: modalConstants.modalObjectDefault } )        
    //   // window.location.reload()
    // }
  }

  function debug(debugTable) {
    if (debugTable == undefined || debugTable == null)
      return null
    else
      return  <tr>
                <td>
                  {debugTable}
                </td>
              </tr>              
  }  

  return <center>          
          {/* <table style={ TABLE_CONTAINER } onbeforeunload={ () => beforeUnload() } > */}
          <table style={ TABLE_CONTAINER } >
            <thead>
              <tr> 
                <td height='1px'> 
                   <CrudTitle title={props.title} showRequired={props.showRequired} hideBtClose={props.hideBtClose} modalBtClose={props.modalBtClose}  />
                </td>
              </tr>  
            </thead> 
            <tbody>
              <tr>
                <td>
                  {props.children}                  
                </td>
              </tr>  
            </tbody> 
            <tfoot>
              {debug(props.debugTable)}            
            </tfoot>
          </table>           
         </center>
}


export function CrudTable(props) {

  return <CrudContainer title={props.title} showRequired={props.showRequired} hideBtClose={props.hideBtClose} modalBtClose={props.modalBtClose}  debugTable={props.debugTable} >
           <CrudTableStyle>             
              {props.children}
           </CrudTableStyle>        
         </CrudContainer> 
}


export function CrudError(props) {
  return <div style={{'color':'red', 'fontSize':'8'}}> {props.msg} </div> 
}


// Deve estar sincronizado com CRUD_TITLE
export const CrudTitleStyle = styled.h1`
  font-size: 12px;
  text-align: center;  
  color: #378254;
  font-weight: bold;  
`;


export function CrudTitle(props){

  const { modal, setModal } = useContext(ModalContext)

  let { modalBtClose } = props

  if (!modalBtClose)
    modalBtClose = modalCodeNone

  // FUNCIONOU BEM, MENOS NA TELA DE UPLOAD! NÃO TIVE TEMPO DE ENTENDER E DEIXEI PARA OUTRO REALEASE  
  // useEffect(() => {   // Configurando FECHAR (nos formulários) com ESC      

  //   const close = (e) => {

  //     if (e.keyCode === 27)
  //       setModal( { code: modalBtClose } )
          
  //   }
    
  //   window.addEventListener('keydown', close)
  //   return () => window.removeEventListener('keydown', close)    

  // }, 
  // []); 



  // // Para setar o modal default (que leva ao login) quando o usuário fechar a aba do browser
  // window.addEventListener("beforeunload", (ev) => 
  // { // Isso só foi necessário na tela de password forget: 

  //   // AGORA....
  //   if ( ( modal.code == modalConstants.modalCodePasswordForget )  ||  ( modal.code == modalConstants.modalCodeUsuarioRegister )  )
  //     setModal( { code: modalConstants.modalObjectDefault } )    

  // })
    




  function required() {
    return <td style={{width: '1px', height:'1px', fontSize:10, color:EMBRAPA_AZUL_COLOR, textAlign:'left'}}>
             <table><tbody><tr><td><Required/></td><td>Obrigatório</td></tr></tbody></table>
           </td>
  }  

  function btClose() {
    return  <td style={{height:'1px', width:'1px', textAlign:'right'}}>
              <a href='' onClick={() => {setModal( { code: modalBtClose } ) }}>
                {/* <IoIosCloseCircle size='30' color={BOTAO_FECHAR_COLOR} title='FECHAR (Tecla ESC)' /> */}
                <IoIosCloseCircle size='30' color={BOTAO_FECHAR_COLOR} title='FECHAR' />
              </a>                  
            </td>                              
  }    

  return  <CrudTitleStyle>
            <table width='100%'>
              <tbody>
                <tr>
                  {props.showRequired == 'true' ? required() : ''}
  
                  <td style={{height:'1px', align:'center', width:'100%'}}>
                    {props.title}
                  </td>
  
                  {props.hideBtClose == 'true' ? '' : btClose()}
                </tr>              
              </tbody>
            </table>            
          </CrudTitleStyle> 
}

// Relacionadas a styled-components -- TÉRMINO ---------------------------------



// Relacionadas a debug -- TÉRMINO ---------------------------------------------

  // A primeira propriedade do objeto parametrizado deve ser {dubug=true ou false}
  export function debug(obj){

    function printObj(obj){

      let to = typeof obj
    
      switch (to) {
        case "undefined": return "undefined"          
          break;
      
        case "number": return obj
        break;

        case "string": return '"' + obj + '"'
        break;

        case "boolean": return obj == true ? 'true' : 'false'
        break;       
        
        case "object": {
          if (Array.isArray(obj)) {
            let res = '['
            obj.forEach(element => {
              res = res + element + ','              
            });

            res = res.substr(0, res.length - 1)
            res = res + ']'
            return res.length == 1 ? '' : res
          }            
          else
            return "objeto não mapeado"
        }
        break;               
        
        default: return "undefined"          
      }
    }

    if (!obj.debug)
      return null
    else {
      let listOfVars = Object.keys(obj).map(key => {
        return <tr key={key}> 
                 {key == 'debug' ? null : <td> <b>{key}</b> </td>  } 
                 {key == 'debug' ? null : <td> <FaLongArrowAltRight size='25' color='gray'/>  </td>  } 
                 {key == 'debug' ? null : <td> {printObj(obj[key])}   </td>  } 
               </tr>  
      })
  
      return <center>
               <div style={{'padding':'30px', fontSize:'11px'}}>
                 <font style={{color:'red'}} > <b>DEBUG</b> </font>
                 <table style={DASHED_TABLE_DEBUG} > 
                   <tbody>
                     {listOfVars} 
                   </tbody>
                 </table>
               </div>
             </center>
    }             
  }

// Relacionadas a debug -- TÉRMINO ---------------------------------------------



export function handleKeyDownToSubmitOnEnter(event, handle) {
  if (event.keyCode == 13) 
    return handle(event)
}



// export function CrudCombo(props){

//   async function getElements() {

//     try {
//       setLoading(true);

//       let options

//       await axios.get(rest)
//       .then(res => {          
//         console.log(res);          
        
//         let rows = res.data.data  
    
//         setLoading(false)
    
//         options = rows.map( (row, i ) => {                                      
//             return <option key={pk} value={row[pk]} > {row[desc]} </option>
//         })              

//         setOptions(options)
//       })
//       .catch(error => {
//         let msg = 'url: ' + rest + ' |||||  Erro: ' + error.message
//         console.log(msg)

//         setError(error);    
//         return null
//       })

//     } finally {
//       setLoading(false);
//     }    

//   }  

//   const { rest, pk, desc, item, setItem } = props

//   const [error, setError] = useState(null);

//   const [ options, setOptions ] = useState(null)

//   const [ loading, setLoading ] = useState(true)

//   useEffect(() => {    
//     getElements()    
//   }, []) // Executa apenas uma vez
  

//   function handleChange(event) {
//     setItem(event.target.value)    
//   }  
  
//   if (loading) 
//     return <div> <font style={LOADING_INTERFACE}> Carregando... </font></div>
//   else
//     return <select name={pk} id={pk} value={item} onChange={handleChange}>
//              <option key='-1' value='-1' > --SELECIONE-- </option>
//              {options}
//            </select>  
// }



export function CrudCombo(props){

  async function getElements() {

    try {
      setLoading(true);

      let options

      await axios.get(rest)
      .then(res => {          
        console.log(res);          
        
        let rows = res.data.data  
    
        setLoading(false)
    
        options = rows.map( (row, i ) => {       
          
          if (exclude) { // Se existem elementos para excluir, considere eles...
            if ( exclude.indexOf( row[pk] ) == -1 )
              return <option key={pk} value={row[pk]} > {row[desc]} </option>
          }
          else // ...caso contrário, inclua todos!
            return <option key={pk} value={row[pk]} > {row[desc]} </option>

        })              

        setOptions(options)
      })
      .catch(error => {
        let msg = 'url: ' + rest + ' |||||  Erro: ' + error.message
        console.log(msg)

        setError(error);    
        return null
      })

    } finally {
      setLoading(false);
    }    

  }  

  const { rest, pk, desc, item, setItem, exclude } = props

  const [error, setError] = useState(null);

  const [ options, setOptions ] = useState(null)

  const [ loading, setLoading ] = useState(true)

  useEffect(() => {    
    getElements()    
  }, [rest]) // Executa apenas uma vez
  

  function handleChange(event) {
    setItem(event.target.value)    
  }  
  
  if (loading) 
    return <div> <font style={LOADING_INTERFACE}> Carregando... </font></div>
  else
    return <select name={pk} id={pk} value={item} onChange={handleChange}>
             <option key='-1' value='-1' > --SELECIONE-- </option>
             {options}
           </select>  
}


///////////////////////////////////////////////////////////////////
// FUNÇÕES - TÉRMINO
///////////////////////////////////////////////////////////////////