import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figBtPlus from './img/bt_plus.png'
import figBtMinus from './img/bt_minus.png'
import figBtInsert from './img/bt_insert.png'
import figInsertPasso1 from './img/insert_shape_passo1.png'
import figInsertPasso2 from './img/insert_shape_passo2.png'
import figInsertPasso3 from './img/insert_shape_passo3.png'
import figInsertPasso4 from './img/insert_shape_passo4.png'
import figInsertPasso5 from './img/insert_shape_passo5.png'
import figInsertPasso6 from './img/insert_shape_passo6.png'

const Page = () => {

    return <html>
               <body>
                   <div style={estilos.tituloPagina}>Cadastro de <i>Shape</i></div>
                   
                   <br/>
                   <p>
                       O cadastro de <i>shapes</i> estará disponível apenas para usuários autorizados.
                       Usuários com esta permissão poderão visualizar o ícone de inserção de <i>shapes</i> ( <img src={figBtInsert} />). <br/>
                       Clicando neste ícone, será possível visualizar a tela vista na Figura 1.
                   </p>
                   <br/>        
           
                   <figure>
                       <img style={estilos.imgStyle} src={figInsertPasso1} />                       
                       <figcaption style={estilos.figcaption}>Figura 1 - Informando metadados e arrastando o <i>shape</i></figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Nessa caixa de diálogo ( Figura 1 ) o usuário precisa informar:
           
                       <br/>
                       <br/>
                       <b>a) A projeção do <i>shape</i></b>. E, caso a projeção seja informada equivocadamente, 
                       o <i>shape</i> poderá não ser exibido! 
                       <br/>
                       <b>b) O tipo do <i>shape</i></b>: será <u>público</u> ( que pode ser visualizado livremente por 
                       qualquer usuário, com qualquer perfil ) ou <u>privado</u> ( que só pode ser visualizado por 
                       usuários com perfil de <i>Administrador Geral</i> ou por usuários com perfil de 
                       <i>Administrador de Banco de Dados</i> ).
                       <br/>
                       <b>c) O título</b>: nome utilizado para identificar o <i>shape</i> na árvore de <i>shapes</i>.
                       <br/>
                       <b>d) Palavras-chave</b>: essas palavras serão utilizadas para categorizar o <i>shape</i> a ser
                       cadastrado. E possível fazer o cadastro digitando o texto e pressionando <i>enter</i>, 
                       ou clicando no ícone ( <img src={figBtPlus} />). Caso seja necessário remover uma palavra-chave 
                       cadastrada por engano, isso será possível clicando no ícone ( <img src={figBtMinus} />).
                       <br/>
                       <b>e) <i>SLD Content-type</i></b>: o GeoTC foi programado para ajustar as legendas dos <i>shapes</i> com base em 
                       arquivos SLD, respeitando as determinações da <i>OGC</i> ( <i>Open Geospatial Consortium</i> ). 
                       Durante o desenvolvimento deste <i>software</i> foram percebidas duas codificações distintas para 
                       o arquivo que determina as legendas. A opção que já vem selecionada por <i>default</i> atende a grande maioria dos casos.
                       É possível perceber essa diferença verificando o código embutido nos arquivos <i>SLD</i>.
                       A seleção da codificação correta é uma necessidade para o cadastro do <i>shape</i>.
                       <br/>
                       <b>f) Categoria da camada</b>: cada camada ( <i>shape</i> ) é agrupada com outras camadas afins em função 
                       de categorias definidas previamente.
                       <br/>
                       <b>g) Arquivos que representam o <i>shape</i></b>: no lado direito desta caixa de diálogo foi codificado um 
                       componente sensível ao arrasto de arquivos. Os arquivos que o usuário arrastar aqui serão carregados no servidor, 
                       desde que, estejam entre os tipos de arquivo para os quais o sistema foi programado para interpretar:
                       <ul>
                           <li>
                               .dbf
                           </li>
           
                           <li>
                               .prj
                           </li>
           
                           <li>
                               .sbn
                           </li>
                           
                           <li>
                               .sbx
                           </li>
                           
                           <li>
                               .shp
                           </li>
                           
                           <li>
                               .xml
                           </li>
                           
                           <li>
                               .shx
                           </li>
                           
                           <li>
                               .cpg
                           </li>
                           
                           <li>
                               .idx
                           </li>
                           
                           <li>
                               .fbn
                           </li>
                           
                           <li>
                               .fbx
                           </li>
                           
                           <li>
                               .ain
                           </li>
                           
                           <li>
                               .aih
                           </li>
                           
                           <li>
                               .ixs
                           </li>
                           
                           <li>
                               .mxs
                           </li>
                           
                           <li>
                               .atx
                           </li>
                           
                           <li>
                               .qpj
                           </li>
                           
                           <li>
                               .sld
                           </li>                                
                       </ul>
                                           
                       Se por acaso o usuário tentar fazer <i>upload</i> de um arquivo diferente, o sistema informará que isso não é possível.            
                       Adicionalmente, nem todos esses arquivos são obrigatórios para o cadastro de um <i>shape</i>, entretanto, alguns são.
                       O sistema informará, caso o usuário deixe de inserir um arquivo considerado imprescindível para a devida configuração do <i>shape</i>.
                   </p>
                   <br/>        
           
           
                   <figure>
                       <img style={estilos.imgStyle} src={figInsertPasso2} />                       
                       <figcaption style={estilos.figcaption}>Figura 2 - Aguardando carregamento do <i>shape</i> no servidor</figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Considerando os arquivos atendam a todas as exigências do sistema, tem início o processo de <i>upload</i>
                       dos arquivos para a memória <i>cache</i> do servidor. Isso precede o cadastro no <i>mapserver</i>.
                       O usuário contará com uma barra de progressão que identificará o percentual que já foi transferido, 
                       conforme pode ser visto na Figura 2.
                   </p>
                   <br/>        
           
                   <figure>                       
                       <img style={estilos.imgStyle} src={figInsertPasso3} />                      
                       <figcaption style={estilos.figcaption}>Figura 3 - Shape carregado no servidor, pronto para inserção</figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Somente após o devido fornecimento de todas as informações consideradas obrigatórias, e, também, após o
                       <i>upload</i> de arquivos válidos para a configuração de um <i>shape</i>, o sistema habilitará o botão
                       "Cadastrar Camada" (visto na Figura 3). Perceba que, na Figura 2, esse botão estava presente, porém, 
                       desabilitado, e com um rótulo diferente: alertando para a imcompletude dos passos!
                       <br/>
                       Quando o botão "Cadastrar Camada" estiver habilitado, o passo seguinte é clicar nele. 
                       Em função deste clique será possível perceber um cenário similar ao da Figura 4.
                   </p>
                   <br/>        
           
           
                   <figure>
                       <img style={estilos.imgStyle} src={figInsertPasso4} />      
                       <figcaption style={estilos.figcaption}>Figura 4 - Cadastrando dados no map server e no PostGis</figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Neste passo (visto na Figura 4) o sistema está configurando o <i>shape</i> no <i>MapServer</i>,
                       que utiliza uma instância do <i>PostGIS</i> como <i>database</i>.
                       Adicionalmente, também está configurando todos os metadados definidos na tela em uma base de dados 
                       do <i>SGBD PostgreSQL</i>.            
                   </p>
                   <br/>        
           
                   <figure>
                       <img style={estilos.imgStyle} src={figInsertPasso5} />                             
                       <figcaption style={estilos.figcaption}>Figura 5 - Percebendo conclusão da inclusão</figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Quando todo o processamento (que teve início na Figura 4) for concluído, o sistema limpará os dados
                       da tela de inclusão de camada, que já estará pronta para a inclusão seguinte (conforme Figura 5), 
                       caso seja o desejo do usuário.
                   </p>
                   <br/>
                   
                   
                   <figure>
                       <img style={estilos.imgStyle} src={figInsertPasso6} />            
                       <figcaption style={estilos.figcaption}>Figura 6 - Visualizando shape inserido</figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Para encerrar, o usuário pode visualizar o <i>shape</i> que acabou de inserir: procurando pelo 
                       título escolhido dentro da categoria adotada, conforme exemplo visto na Figura 6.
                   </p>
                   <br/>                

               </body>
           </html>           
}

const HCadastroShape = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HCadastroShape