import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figTelaBemVindo from './img/tela_bemvindo.png'

const Page = () => {

    return <html>
               <body>
                   <div style={estilos.tituloPagina}>Login</div>
                                                 
                   <figure>
                       <img style={estilos.imgStyle} src={figTelaBemVindo} />
                       <figcaption style={estilos.figcaption}>Figura 1 - Tela Login/Registro/Recuperação</figcaption>
                   </figure>
           
                   <br/>
                   <p>
                       Para realizar o login, basta informar as credenciais na tela vista na Figura 1, 
                       e clicar no botão "Entrar"
                   </p>                   
           
               </body>
           </html>
}

const HLogin = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HLogin