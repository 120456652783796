import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import SortIcon from "@material-ui/icons/ArrowDownward";
import CircularProgress from '@material-ui/core/CircularProgress';
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md'
import { FaPlusCircle, FaEdit } from 'react-icons/fa'
import ModalContext, {ModalCodeEdit} from './../context/ModalContext'
import { BsToggleOn, BsToggleOff } from 'react-icons/bs';
import { CrudTable } from '../lib/lib'
import Loader from 'react-loader-spinner'
import Message from './../messages'
import * as lib from './../lib/lib'


function Table(props) {  

  const { setModal } = useContext(ModalContext) 

  const { rest, rest_to_insert, rest_to_edit, rest_to_delete, table, pk, 
          columns, controlInsert, controlEdit, controlDelete, 
          controlDeleteExceptionValues, controlDeleteExceptionMessages } = props        
  
  let _rest_to_insert = rest_to_insert == undefined ? rest : rest_to_insert
  let _rest_to_edit   = rest_to_edit   == undefined ? rest : rest_to_edit
  let _rest_to_delete = rest_to_delete == undefined ? rest : rest_to_delete
  
  const modalCodeEdit = ModalCodeEdit(table)

  const paginationOptions = { rowsPerPageText: 'Registros por página', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }


  function getRowPKValue(row, pk){
    let answer = null
    for (var [key, value] of Object.entries(row)) 
      if (key == pk)
        return value

    return answer
  }

  function deleteRow(id){

    if ((controlDeleteExceptionValues) && (controlDeleteExceptionMessages)) {
      let posException = controlDeleteExceptionValues.indexOf(id)
      if ( posException != -1 ) {
        alert( controlDeleteExceptionMessages[posException] )
        return // Não executar a invocação do REST de deleção
      }            
    }

    axios.delete(_rest_to_delete + '/' + id)
    .then((response) => {                           
      alert('Registro excluído!')
      setLoading(true)
    })
    .catch(error => 
    {                         
      alert( Message( error.request.response ) )
    });   

  }  

  const columnInsert =     
  {
    name: <FaPlusCircle title='ADICIONAR' size='22' color={lib.EMBRAPA_VERDE_SUAVE} onClick={() => setModal( { code: modalCodeEdit, params: { table: table, rest: _rest_to_insert, acao:'novo' } } ) }/>,
    right: true,
  }

  const columnEdit = 
  {  // cell: (row, index, column, id)...: precisei apenas do row
  cell: (row) => <FaEdit title='ALTERAR' size='20' color='#EEDC7C' onClick={() => setModal( { code: modalCodeEdit, params: { table: table, rest: _rest_to_edit, acao:'editar', id: getRowPKValue(row, pk) } } ) }/>,
    ignoreRowClick: true,
    allowOverflow: true,
    width: '20px',
    button: true,
  }

  const columnDelete = 
  {  // cell: (row, index, column, id)...: precisei apenas do row
  cell: (row) => <MdDelete title='EXCLUIR' size='22' color='#C99' onClick={() => { if (window.confirm( `Excluir Registro?`)) { deleteRow( getRowPKValue(row, pk) ) } } }/>,
    ignoreRowClick: true,
    allowOverflow: true,
    width: '40px',
    button: true,
  }       

  function getData(){
    if (rest == null)
      setData( null )      
    else
      axios.get(rest)
      .then(res => {      
  
        setData( res.data.data )      
  
        setLoading(false)        
      })    
      .catch(function (error) {
        console.log(error);
      })      
  }

  const [ loading, setLoading ] = useState(true);

  useEffect(() => {    

    if (loading)
      getData()              

  }, [loading]); // Executa sempre que loading for alterado    
  
  const [data, setData] = useState([]);    

  useEffect(() => {    
    // -------------------------------------------------------------------
    // AJUSTANDO TODOS OS TOGGLES -- INÍCIO
    // -------------------------------------------------------------------
    function toggleBoolean(rest, id){
      axios.put(rest + '/' + id)
      .then(res => {        
        setLoading(true)
      })    
      .catch(function (error) {
        console.log(error);
      })        
    }

    const toggleSize = 30
    for (let i = 0; i < columns.length; i++) 
      if ( columns[i].blToggleRest )
        columns[i].cell = function(row) {  
                                           return <div onClick={()=>{toggleBoolean(columns[i].blToggleRest, row[pk])}} align='center' > 
                                                    { row[columns[i].selector] ? 
                                                      <BsToggleOn  title='SIM. CLIQUE PARA ALTERAR' size={toggleSize} color={lib.TOGGLE_COLOR} />
                                                      :
                                                      <BsToggleOff title='NÃO. CLIQUE PARA ALTERAR' size={toggleSize} color={lib.TOGGLE_COLOR}/>      
                                                    }
                                                  </div> 
                                        }
    // -------------------------------------------------------------------
    // AJUSTANDO TODOS OS TOGGLES -- TÉRMINO
    // -------------------------------------------------------------------                                        


    // -------------------------------------------------------------------
    // AJUSTANDO COLUNAS DO CRUD -- INÍCIO
    // -------------------------------------------------------------------    

    if (controlInsert == true)
      columns.push(columnInsert)   // Acrescentando coluna edit à propriedade column    
    
    if (controlEdit == true)
      columns.push(columnEdit)   // Acrescentando coluna edit à propriedade columns

    if (controlDelete == true)
      columns.push(columnDelete) // Acrescentando coluna delete à propriedade columns

    // -------------------------------------------------------------------
    // AJUSTANDO COLUNAS DO CRUD -- INÍCIO
    // -------------------------------------------------------------------          



    // -------------------------------------------------------------------
    // AJUSTANDO COLUNAS GENÉRICAS -- INÍCIO
    // -------------------------------------------------------------------

    function getRestWithFieldsFromRow(row, rest, fields){
      let res = rest
      for (let i = 0; i < fields.length; i++)
        if (row[ fields[i] ])
          res = res.replace(fields[i], row[fields[i]])

      return res
    }


    function execCustomREST(method, rest){
      axios({
        method: method,
        url: rest
      })
      .then(function (response) {        
        console.log(response);
        setLoading(true)
      })
      .catch(function (error) {        
        console.log(error);
      })      
    }
    


    for (let i = 0; i < columns.length; i++) 
      if ( columns[i].customREST ) {    // Se trata de um método customizado      

        columns[i].cell = function(row) {  
          return <div onClick={ 
                                () => { 
                                         let dynamic_rest = getRestWithFieldsFromRow(row, columns[i].customREST, columns[i].customRESTFields )
                                         execCustomREST( 
                                                          columns[i].customRESTMethod, 
                                                          dynamic_rest 
                                                       ) 
                                      } 
                              }  align='center' > 
                   { columns[i].customIcon }
                 </div> 
       }        

      }

    // -------------------------------------------------------------------
    // AJUSTANDO COLUNAS GENÉRICAS -- TÉRMINO
    // -------------------------------------------------------------------                                        


  }, []); // Carrega apenas uma vez (para acrescentar as colunas com ações)


  const customStyles = {
    table: {
      style: {
        ...lib.DASHED_TABLE
      },
    },    
    header: {
      style: {
        minHeight: '5px',
      },
    },        
    rows: {
      style: {
        minHeight: '30px', // override the row height
        // width: '800px', // Largura da tabela inteira
      }
    },
    headCells: {
      style: {
        color: lib.EMBRAPA_AZUL_COLOR,
        fontSize: '14px',
        fontWeight: 'bold',
        paddingLeft: '6px',
        paddingRight: '6px',        
      },
    },
    cells: {
      style: {
        paddingLeft: '6px',
        paddingRight: '6px',        
      },
    },
    pagination: {
      style: {
        align: 'center',
        fontSize: '13px',
        minHeight: '56px',
        borderTopStyle: 'solid',
        borderTopWidth: '0px',
      },
    }        
  };  

  if (loading)
    return <Loader
              type="Oval"
              color={lib.EMBRAPA_VERDE_COLOR}
              height={100}
              width={100}        
           />
  else
    if ( (data == null) || (data.length == 0) )
      return <center> <table style={lib.DASHED_TABLE}> <tr><td> <font style={lib.MESSAGE_STYLE} > Sem Registro(s)! </font> </td></tr> </table> </center>
    else
      return (
        <DataTable
          columns={columns}
          data={data}
          sortIcon={<SortIcon />}              
          customStyles={customStyles}
          pagination
          striped
          pointerOnHover
          highlightOnHover
          keyField
          paginationComponentOptions={paginationOptions}
          progressComponent={<CircularProgress />}
        />
    )
}

export default Table