import 'proj4leaflet/src/proj4leaflet.js';
import L from 'leaflet'
import axios from 'axios';

export const getFeatureInfo = (map, reject_layers = [], func_intercept_results) => {

    map.on('click', function (e) {

        // gera um array com as layers wms disponiveis no mapa
        let layers = getArrayLayers(map, reject_layers );

        // showResults(e.latlng, "Não existem informações para este ponto!", map);
        //let props = feature.properties;
        //converte o objeto para um array de propriedades
        //props = Object.entries(props);
        //showResults(e.latlng, props, map, true);

        // recursivo, dispara chamadas ao getfeatureinfo para todas as layers passadas como parâmetro
        // executa func_intercept_results antes de gerar o showGetFeatureInfo
        dispatchRequest(0, layers, map, e, [], (results) => {
            const new_results = func_intercept_results ? func_intercept_results(results) : results;
            showGetFeatureInfo(e.latlng, new_results, map, true);
        });

    });

}

const dispatchRequest = (index, layers, map, e, results, func_process_results) => {

    const layer = layers[index];
    if (!layer) {
        func_process_results(results);
        return undefined;
    }

    const url = getFeatureInfoUrl(map, layer, e.latlng, layer.wmsParams.layers, { 'info_format': 'application/json' });
    axios.get(url)
        .then((response) => {
            // verifica se a resposta contem dados
            if (response.data.features) // Linha adicional. RESOLVE: "Cannot read property 'length' of undefined"
              if (response.data.features.length !== 0) {
                  results.push({ layerName: layer.wmsParams.layers, features: response.data.features[0] });
              }
            index++;
            return dispatchRequest(index, layers, map, e, results, func_process_results);
        })
        .catch(error => { console.error("Erro ao tentar ler o getfeatureinfo ... ", error.message); alert(error.message);  } );
        // .catch(error => { console.error("Erro ao tentar ler o getfeatureinfo ... ", error.message); alert(error.message) } );

}

const showGetFeatureInfo = (latlng, content, map, is_feature = false) => {

    if( content && content.length === 0 ){
        return undefined;
    }

    const _BBOX = 'bbox';
    let resposta = '';

    if (is_feature) {
        content.map(result => {
            resposta += "<b> <font color='#385b9b' >" + result.layerName.toUpperCase() + "</font> </b>";
            resposta += "<div class='table-responsive'>" +
                "<table class='table table-sm'>" +
                "<thead>" +
                "<tr>" +
                "<th scope='col'> <font color='#378254'> Atributo </font> </th>" +
                "<th scope='col'> <font color='#378254'> Valor </font> </th>" +
                "</tr>" +
                "</thead>" +
                "<tbody>";
            const props = Object.entries(result.features.properties);    
            props.map(p => {
                let attr = p[0];
                //let value = format_number_with_points(p[1]);
                let value = p[1];
                if (attr !== _BBOX) {
                    resposta += "<tr>" +
                        "<th scope='row'>" + attr + "</th>" +
                        "<td>" + value + "</td>" +
                        "</tr>";
                }
                return p;
            });

            resposta += "</tbody>" +
                "</table>" +
                "</div>";

            return result;    
        });
    }

    resposta = resposta ? resposta : content;

    L.popup({ maxWidth: 280, maxHeight: 350 })
        .setLatLng(latlng)
        .setContent(resposta)
        .openOn(map);

}

/*const crs = new L.Proj.CRS('EPSG:4326',
    '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs',
    {
        transformation: new L.Transformation(1 / 360, 0.5, -1 / 360, 0.5),
        resolutions: [
            0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625,
            0.010986328125, 0.0054931640625, 0.00274658203125, 0.001373291015625
        ]
    });
    */



const getArrayLayers = (map, reject_layers) => {
    let arr = [];
    map.eachLayer((layer) => {
        if (layer instanceof L.TileLayer && layer.wmsParams && !reject_layers.includes(layer.wmsParams.layers)) {
            arr.push(layer);
        }
        return layer;
    });
    return arr;
}

function getFeatureInfoUrl(map, layer, latlng, str_layers, params) {

    let point = map.latLngToContainerPoint(latlng, map.getZoom());
    let size = map.getSize();
    //let bounds = map.getBounds();
    //let sw = bounds.getSouthWest();
    //let ne = bounds.getNorthEast();
    //sw = crs.projection._proj.forward([sw.lng, sw.lat]);
    //ne = crs.projection._proj.forward([ne.lng, ne.lat]);

    let defaultParams = {
        request: 'GetFeatureInfo',
        service: 'WMS', 
        // srs: 'EPSG:4326', //layer._crs.code,
        srs: 'EPSG:4674', //layer._crs.code,
        styles: layer.wmsParams.styles,
        version: layer._wmsVersion,
        format: layer.options.format,
        bbox: map.getBounds().toBBoxString(),//[sw.join(','), ne.join(',')].join(','),
        height: size.y,
        width: size.x,
        feature_count: 50,
        layers: str_layers,
        query_layers: str_layers,
        info_format: 'text/html',
        //buffer: 1 
    };

    params = L.Util.extend(defaultParams, params || {});

    if (layer.wmsParams.cql_filter) {
        params.cql_filter = layer.wmsParams.cql_filter;
    }
    if (layer.wmsParams.env) {
        params.env = layer.wmsParams.env;
    }
    if (layer.wmsParams.viewparams) {
        params.viewparams = layer.wmsParams.viewparams;
    }

    params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
    params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;

    return layer._url + L.Util.getParamString(params, layer._url, true);

}

