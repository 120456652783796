import React from 'react';
import Wrapper from './wrapper'
import * as estilos from './estilos'
import figLegenda from './img/legenda.png'
import figAtributos from './img/tabatr.png'

const Page = () => {

    return <html>
              <body>
                  <div style={estilos.tituloPagina}>Legenda e Tabela de Atributos</div>
                  
                  <br/>
                  <p>
                      As legendas dos <i>shapes</i> inseridos no GeoTC podem ser vistas no canto direito da tela, 
                      conforme exemplificado na Figura 1.
                  </p>
                  <br/>
          
                  <figure>
                      <img style={estilos.imgStyle} src={figLegenda} />                                          
                      <figcaption style={estilos.figcaption}>Figura 1 - Legenda</figcaption>
                  </figure>
          
                  <br/>
                  <p>
                      As tabelas de atributo dos <i>shapes</i> inseridos no GeoTC podem ser visualizadas no próprio mapa, 
                      basta, para isso, clicar com o botão esquerdo do mouse no ponto a ser consultado.
                      Caso a posição do mouse coincida com mais de um <i>shape</i>, todos serão descritos no <i>pop-up</i> que 
                      exibe os atributos, conforme exemplificado na Figura 2.
                  </p>
                  <br/>
          
                  <figure>
                      <img style={estilos.imgStyle} src={figAtributos} />                            
                      <figcaption style={estilos.figcaption}>Figura 2 - Tabela de Atributos</figcaption>
                  </figure>        
          
              </body>
          </html>
}

const HLegendaEAtributos = () =>  { return  <Wrapper> <Page/> </Wrapper>  }

export default HLegendaEAtributos