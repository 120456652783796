import React, { useState, useEffect } from 'react';
import MapSequenceContext, {defaultMapSequence} from './MapSequenceContext';

export const mapSequenceKeyForLocalStorage = '@geotab/mapSequence'

export const useMapSequenceWithLocalStorage = localStorageKey => {
  // Após um F5 tenta recuperar da localStorage, se não conseguir, usa valor default
  const [mapSequence, setMapSequence] = useState( JSON.parse( localStorage.getItem(localStorageKey) ) || defaultMapSequence )

  useEffect( () => // Salva na localStorage apenas quando mapSequence for alterado!
              {localStorage.setItem(localStorageKey, JSON.stringify( mapSequence ) )}, 
              [mapSequence]
           )

  return [mapSequence, setMapSequence]
}

const MapSequenceProvider = ({ children }) => {  
  const [mapSequence, setMapSequence] = useMapSequenceWithLocalStorage(mapSequenceKeyForLocalStorage);

  return (
    <MapSequenceContext.Provider value={{ mapSequence, setMapSequence }}>
      {children}
    </MapSequenceContext.Provider>
  )
}

export default MapSequenceProvider;