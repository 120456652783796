import React, { useState } from 'react';
import { CrudTable } from '../lib/lib'
import { modalCodeUsuarioRegister } from '../context/ModalContext'
import file from './../lgpd/TermoDeUso.pdf' 
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function PDFTermoUso(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }  

  return <CrudTable title={'TERMO DE USO'} style={{width:'100%'}} modalBtClose={modalCodeUsuarioRegister} >
               
           <tr>
             <td align='center'>

               <div style={{height:'500px', overflowY:'scroll'}}>   
                 <Document                                                             
                   file={file}
                   onLoadSuccess={onDocumentLoadSuccess}                  
                   onLoadError={console.error}
                 >
                   {
                     Array.from(new Array(numPages), (el, index) => (
                       <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                     ))
                   } 
                 </Document>
                              
               </div>
                
             </td>
           </tr>
  
         </CrudTable>
}