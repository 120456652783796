import React, { useContext } from 'react';
import * as estilos from './estilos'
import * as modalConstants from './../context/ModalContext'
import ModalContext from './../context/ModalContext'
import LoggedUserContext from './../context/LoggedUserContext'
import { CrudTable } from '../lib/lib'
import logoGeoTC from './img/geotc.png'
import logoEMB from './img/emb.png'
import { FaArrowCircleRight } from 'react-icons/fa'

export default function Wrapper(props) {

  const { modal, setModal } = useContext(ModalContext)
  const { loggedUser } = useContext(LoggedUserContext)

  const perm_alt  = loggedUser.dbs_perm_alt.length > 0
  const perm_bai  = loggedUser.dbs_perm_bai.length > 0
  const perm_est  = loggedUser.dbs_perm_est.length > 0
  const perm_exc  = loggedUser.dbs_perm_exc.length > 0
  const perm_inc  = loggedUser.dbs_perm_inc.length > 0
  const perm_vis  = loggedUser.dbs_perm_vis.length > 0
  const perm_vpv  = loggedUser.dbs_perm_vpv.length > 0
  const superuser = loggedUser.id_perfil === 1

  return  <CrudTable title={`MANUAL DO GEOTC`} style={{width:'100%'}} modalBtClose={modalConstants.modalCodeNone} >

            <tr>
              <td>
                <table style={{width:'100%', height:'1px'}}>
                  <tr>
                    <td style={{textAlign:'left'}}>
                      <img style={{textAlign:'left', alignContent:'left' }} src={logoGeoTC} />
                    </td>
                    <td>

                      <table> 
                        <tr>
                          <td style={ estilos.tituloPrincipal }>
                            <br/>
                            MANUAL                                                  
                          </td>
                        </tr>                        
                        <tr>
                          <td style={{...estilos.observacao, padding: '5px'} }>
                            OBS: Tópicos com base no perfil
                          </td>
                        </tr>
                      </table>
                      
                    </td>                          
                    <td style={{textAlign:'right'}}>
                      <img style={{textAlign:'right', alignContent:'right' }} src={logoEMB} />                              
                    </td>                            
                  </tr>
                </table>                                            
              </td>              
            </tr>    


            <tr>
              <td>            
       
                  <table style={{width:'100%'}} >
                    <tr>
                      <td style={{width:'170px'}}>
                        
                        <a 
                          hlink='' 
                          style={ modal.code == modalConstants.modalCodeHBoasVindas ? estilos.linkStyleActive : estilos.linkStyle } 
                          onClick={ () => { setModal( { code: modalConstants.modalCodeHBoasVindas } ) } }
                        > 
                          { modal.code == modalConstants.modalCodeHBoasVindas ? <FaArrowCircleRight/> : null } Apresentação 
                        </a>
                        <br/>

                        <a 
                          hlink='' 
                          disabled={true} 
                          style={ modal.code == modalConstants.modalCodeHCadastroUsuarioPublico ? estilos.linkStyleActive : estilos.linkStyle } 
                          onClick={ () => { setModal( { code: modalConstants.modalCodeHCadastroUsuarioPublico } ) } }
                        > 
                          { modal.code == modalConstants.modalCodeHCadastroUsuarioPublico ? <FaArrowCircleRight/> : null } Cadastro de usuário público 
                        </a>
                        <br/>

                        <a 
                          hlink='' 
                          style={ modal.code == modalConstants.modalCodeHCRecuperacaoSenha ? estilos.linkStyleActive : estilos.linkStyle } 
                          onClick={ () => { setModal( { code: modalConstants.modalCodeHCRecuperacaoSenha } ) } }
                        > 
                          { modal.code == modalConstants.modalCodeHCRecuperacaoSenha ? <FaArrowCircleRight/> : null } Recuperação de Senha 
                        </a>
                        <br/>

                        <a 
                          hlink='' 
                          style={ modal.code == modalConstants.modalCodeHLogin ? estilos.linkStyleActive : estilos.linkStyle } 
                          onClick={ () => { setModal( { code: modalConstants.modalCodeHLogin } ) } }
                        > 
                          { modal.code == modalConstants.modalCodeHLogin ? <FaArrowCircleRight/> : null } Login 
                        </a>
                        <br/>
                        
                        <a 
                          hlink='' 
                          style={ modal.code == modalConstants.modalCodeHNavegacao ? estilos.linkStyleActive : estilos.linkStyle } 
                          onClick={ () => { setModal( { code: modalConstants.modalCodeHNavegacao } ) } }
                        > 
                          { modal.code == modalConstants.modalCodeHNavegacao ? <FaArrowCircleRight/> : null } Navegação
                        </a>
                        <br/>                        

                        <a 
                          hlink='' 
                          style={ modal.code == modalConstants.modalCodeHLegendaEAtributos ? estilos.linkStyleActive : estilos.linkStyle } 
                          onClick={ () => { setModal( { code: modalConstants.modalCodeHLegendaEAtributos } ) } }
                        > 
                          { modal.code == modalConstants.modalCodeHLegendaEAtributos ? <FaArrowCircleRight/> : null } Legenda e tabela de atributos
                        </a>
                        <br/>                        
                        
                        { 
                          perm_bai ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHDownload ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHDownload } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHDownload ? <FaArrowCircleRight/> : null } Download 
                                     </a> 
                                   : 
                                     null 
                        }
                        { perm_bai ? <br/> : null }          



                        { 
                          perm_inc ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHCadastroShape ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHCadastroShape } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHCadastroShape ? <FaArrowCircleRight/> : null } Cadastro de Shape
                                     </a> 
                                   : 
                                     null 
                        }
                        { perm_inc ? <br/> : null }
                                                                                                                                
                        



                        { 
                          perm_alt ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHAlteracaoShape ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHAlteracaoShape } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHAlteracaoShape ? <FaArrowCircleRight/> : null } Alteração de Shape
                                     </a> 
                                   : 
                                     null 
                        }
                        { perm_alt ? <br/> : null }


                        { 
                          perm_exc ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHRemocaoShape ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHRemocaoShape } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHRemocaoShape ? <FaArrowCircleRight/> : null } Remoção de Shape
                                     </a> 
                                   : 
                                     null 
                        }
                        { perm_exc ? <br/> : null }


                        { 
                          superuser ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHGerenciamentoCategoria ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHGerenciamentoCategoria } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHGerenciamentoCategoria ? <FaArrowCircleRight/> : null } Gerenciamento de Categoria
                                     </a> 
                                   : 
                                     null 
                        }
                        { superuser ? <br/> : null }


                        { 
                          superuser ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHGerenciamentoPerfil ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHGerenciamentoPerfil } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHGerenciamentoPerfil ? <FaArrowCircleRight/> : null } Gerenciamento de Perfil
                                     </a> 
                                   : 
                                     null 
                        }
                        { superuser ? <br/> : null }


                        { 
                          superuser ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHGerenciamentoUsuario ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHGerenciamentoUsuario } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHGerenciamentoUsuario ? <FaArrowCircleRight/> : null } Gerenciamento de Usuário
                                     </a> 
                                   : 
                                     null 
                        }
                        { superuser ? <br/> : null }


                        { 
                          superuser ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHGerenciamentoPermissao ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHGerenciamentoPermissao } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHGerenciamentoPermissao ? <FaArrowCircleRight/> : null } Gerenciamento de Permissão
                                     </a> 
                                   : 
                                     null 
                        }
                        { superuser ? <br/> : null }


                        { 
                          superuser ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHGerenciamentoOrdem ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHGerenciamentoOrdem } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHGerenciamentoOrdem ? <FaArrowCircleRight/> : null } Gerenciamento de Ordem
                                     </a> 
                                   : 
                                     null 
                        }
                        { superuser ? <br/> : null }


                        { 
                          superuser ? 
                                     <a 
                                       hlink='' 
                                       style={ modal.code == modalConstants.modalCodeHEstatisticas ? estilos.linkStyleActive : estilos.linkStyle } 
                                       onClick={ () => { setModal( { code: modalConstants.modalCodeHEstatisticas } ) } }
                                     > 
                                       { modal.code == modalConstants.modalCodeHEstatisticas ? <FaArrowCircleRight/> : null } Estatísticas
                                     </a> 
                                   : 
                                     null 
                        }
                        { superuser ? <br/> : null }


                        <a 
                          hlink='' 
                          style={ modal.code == modalConstants.modalCodeHEquipe ? estilos.linkStyleActive : estilos.linkStyle } 
                          onClick={ () => { setModal( { code: modalConstants.modalCodeHEquipe } ) } }
                        > 
                          { modal.code == modalConstants.modalCodeHEquipe ? <FaArrowCircleRight/> : null } Equipe
                        </a>                         
                                                                                                                                                                                                 

                      </td>

                      <td style={{width:'800px'}}>
                        <div style={{height:'600px', width:'100%', paddingLeft:'5px'}}>   
                          {props.children}
                        </div>
                      </td>             

                    </tr>                  
                  </table>                            
                  
                
              </td>
           </tr>
       
          </CrudTable>
}