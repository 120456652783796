import React, { createContext } from 'react'
import Login from './../login'
import Upload from './../upload'
import EditMap from './../editMap'
import PerfilIndex from './../crud/perfilIndex'
import PerfilEdit from './../crud/perfilEdit'
import UsuarioRegister from '../crud/usuarioRegister'
import UsuarioIndex from './../crud/usuarioIndex'
import UsuarioEdit from './../crud/usuarioEdit'
import PermissaoIndex  from './../crud/permissaoIndex'
import OrdemIndex  from './../crud/ordemIndex'
import CategoriaIndex  from './../crud/categoriaIndex'
import CategoriaEdit  from './../crud/categoriaEdit'
import UsuarioRecovery  from './../crud/usuarioRecovery'
import MapDetail  from './../mapDetail'
import Stats  from './../stats'
import StatsCamadasPorBase  from './../stats_camadas_por_base'
import StatsUsuariosPorPerfil  from './../stats_usuarios_por_perfil'
import StatsCamadasPorTipo  from './../stats_camadas_por_tipo'
import StatsUsuariosPorAcesso  from './../stats_usuarios_por_acesso'
import PDFAvisoPrivacidade  from '../crud/pdfAvisoPrivacidade'
import PDFTermoUso  from '../crud/pdfTermoUso'
import HBoasVindas from '../help/boasVindas'
import HCadastroUsuarioPublico from './../help/cadastroUsuarioPublico'
import HCRecuperacaoSenha from './../help/recuperacaoSenha'
import HLogin from './../help/login'
import HNavegacao from './../help/navegacao'
import HLegendaEAtributos from './../help/legendaETabelaAtributos'
import HDownload from './../help/download'
import HCadastroShape from '../help/cadastroShape'
import HAlteracaoShape from '../help/alteracaoShape'
import HRemocaoShape from '../help/remocaoShape'
import HGerenciamentoCategoria from '../help/gerenciamentoCategoria'
import HGerenciamentoPerfil from '../help/gerenciamentoPerfil'
import HGerenciamentoUsuario from '../help/gerenciamentoUsuario'
import HGerenciamentoPermissao from '../help/gerenciamentoPermissao'
import HGerenciamentoOrdem from '../help/gerenciamentoOrdem'
import HEstatisticas from '../help/estatísticas'
import HEquipe from '../help/equipe'
import Equipe from './../equipe'


export const modalCodeNone = 0 // Nenhuma janela modal ativa
export const modalCodeLogin = 1
export const modalCodeUsuarioRegister = 2
export const modalCodeUpload = 3
export const modalCodeUsuarioRecovery = 4
export const modalCodeEditMap = 5
export const modalCodePerfilIndex = 6
export const modalCodePerfilEdit = 7
export const modalCodeUsuarioIndex = 8
export const modalCodeUsuarioEdit = 9
export const modalCodePermissaoIndex = 10
export const modalCodeOrdemIndex = 11
export const modalCodeCategoriaIndex = 12
export const modalCodeCategoriaEdit = 13
export const modalCodeMapDetail = 14
export const modalCodeStats = 15
export const modalCodeStatsCamadasPorBase = 16
export const modalCodeStatsUsuariosPorPerfil = 17
export const modalCodeStatsCamadasPorTipo = 18
export const modalCodeAvisoPrivacidade = 19
export const modalCodeTermoUso = 20
export const modalCodeHBoasVindas = 21
export const modalCodeHCadastroUsuarioPublico = 22
export const modalCodeHCRecuperacaoSenha = 23
export const modalCodeHLogin = 24
export const modalCodeHNavegacao = 25
export const modalCodeHLegendaEAtributos = 26
export const modalCodeHDownload = 27
export const modalCodeHCadastroShape = 28
export const modalCodeHAlteracaoShape = 29
export const modalCodeHRemocaoShape = 30
export const modalCodeHGerenciamentoCategoria = 31
export const modalCodeHGerenciamentoPerfil = 32
export const modalCodeHGerenciamentoUsuario = 33
export const modalCodeHGerenciamentoPermissao = 34
export const modalCodeHGerenciamentoOrdem = 35
export const modalCodeHEstatisticas = 36
export const modalCodeHEquipe = 37
export const modalCodeEquipe = 38
export const modalCodeStatsUsuariosPorAcesso = 39



export const modalObjectDefault = { code: modalCodeNone, params: null }

export function ModalContent(props){

  const { code, params } = props

  switch(code) {
    case modalCodeLogin: return <Login/> 
    case modalCodeUsuarioRegister: return <UsuarioRegister/>
    case modalCodeUpload: return <Upload/>
    case modalCodeUsuarioRecovery: return <UsuarioRecovery/>
    case modalCodeEditMap: return <EditMap/>
    case modalCodePerfilIndex: return <PerfilIndex params={params}/>
    case modalCodePerfilEdit: return <PerfilEdit params={params}/>
    case modalCodeUsuarioIndex: return <UsuarioIndex params={params}/>
    case modalCodeUsuarioEdit: return <UsuarioEdit params={params}/>
    case modalCodePermissaoIndex: return <PermissaoIndex params={params}/>
    case modalCodeOrdemIndex: return <OrdemIndex params={params}/>
    case modalCodeCategoriaIndex: return <CategoriaIndex params={params}/>
    case modalCodeCategoriaEdit: return <CategoriaEdit params={params}/>
    case modalCodeMapDetail: return <MapDetail params={params}/>
    case modalCodeStats: return <Stats params={params}/>
    case modalCodeStatsCamadasPorBase: return <StatsCamadasPorBase params={params}/>
    case modalCodeStatsUsuariosPorPerfil: return <StatsUsuariosPorPerfil params={params}/>
    case modalCodeStatsCamadasPorTipo: return <StatsCamadasPorTipo params={params}/>    
    case modalCodeAvisoPrivacidade: return <PDFAvisoPrivacidade params={params}/>    
    case modalCodeTermoUso: return <PDFTermoUso params={params}/>
    case modalCodeHBoasVindas: return <HBoasVindas params={params}/>
    case modalCodeHCadastroUsuarioPublico: return <HCadastroUsuarioPublico params={params}/>
    case modalCodeHCRecuperacaoSenha: return <HCRecuperacaoSenha params={params}/>    
    case modalCodeHLogin: return <HLogin params={params}/>    
    case modalCodeHNavegacao: return <HNavegacao params={params}/>    
    case modalCodeHLegendaEAtributos: return <HLegendaEAtributos params={params}/>      
    case modalCodeHDownload: return <HDownload params={params}/>      
    case modalCodeHCadastroShape: return <HCadastroShape params={params}/>      
    case modalCodeHAlteracaoShape: return <HAlteracaoShape params={params}/>   
    case modalCodeHRemocaoShape: return <HRemocaoShape params={params}/>       
    case modalCodeHGerenciamentoCategoria: return <HGerenciamentoCategoria params={params}/>      
    case modalCodeHGerenciamentoPerfil: return <HGerenciamentoPerfil params={params}/>         
    case modalCodeHGerenciamentoUsuario: return <HGerenciamentoUsuario params={params}/>           
    case modalCodeHGerenciamentoPermissao: return <HGerenciamentoPermissao params={params}/>   
    case modalCodeHGerenciamentoOrdem: return <HGerenciamentoOrdem params={params}/>   
    case modalCodeHEstatisticas: return <HEstatisticas params={params}/>   
    case modalCodeHEquipe: return <HEquipe params={params}/>   
    case modalCodeEquipe: return <Equipe params={params}/>   
    case modalCodeStatsUsuariosPorAcesso: return <StatsUsuariosPorAcesso params={params}/>               

    default: return null
  }
  
}


export function ModalCodeEdit(table){
  switch(table) {
    case 'perfil': return modalCodePerfilEdit
    case 'usuario': return modalCodeUsuarioEdit
    case 'categoria_camada': return modalCodeCategoriaEdit
    default: return -1
  }
}


export function ModalCodeIndex(table){
  switch(table) {
    case 'perfil': return modalCodePerfilIndex
    case 'usuario': return modalCodeUsuarioIndex
    case 'permissao': return modalCodePermissaoIndex
    case 'categoria_camada': return modalCodeCategoriaIndex
    default: return -1
  }
}

const ModalContext = createContext({modal:modalObjectDefault, setModal: () => {}}) 

export default ModalContext