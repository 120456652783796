import React, {useState, useEffect, useContext, useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components';
import request from 'superagent';
import axios from 'axios'
import { Line } from 'rc-progress';
import { FaInfoCircle, FaPlus, FaMinus } from 'react-icons/fa'
import { CrudTable, Required, CrudBtVerde } from './lib/lib'
import * as lib from './lib/lib';
import * as env from './.env.js'
import DebugContext from './context/DebugContext'
import LoggedUserContext from './context/LoggedUserContext'
import TreeViewCategory from './components/treeViewCategory'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const allowed_files = '.dbf,.prj,.sbn,.sbx,.shp,.xml,.shx,.cpg,.idx,.fbn,.fbx,.ain,.aih,.ixs,.mxs,.atx,.qpj,.sld'

const getColor = (props) => {
  if (props.isDragAccept) {
      return lib.EMBRAPA_VERDE_COLOR;
  }
  if (props.isDragReject) {
      return lib.EMBRAPA_VERDE_COLOR;
  }
  if (props.isDragActive) {
      return lib.DARK_RED_COLOR;
  }
  return '#eee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 100px;
  padding: 20px;
  border-width: 5px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #ffffff;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;


function Upload() {  

  const { debug } = useContext(DebugContext)
  
  const [percentUpload, setPercentUpload] = useState(0);  

  const { loggedUser } = useContext(LoggedUserContext) // Capturando do contexto

  const [validFileNames, setValidFileNames] = useState(false);   

  const [error, setError] = useState([]);


  const [epsgs, setEpsgs] = useState([]);

  useEffect(() => {
    const url = env.GEOTABREST_EPSGS_PERMITIDOS

    axios.get(url)
      .then(res => {
        console.log('Epsgs retornados: ' + res.data.epsgs);        
        setEpsgs( res.data.epsgs )        
      })
      .catch(error => {
        console.log('url: ' + url + ' |||||  Erro: ' + error)
      })
  }, []);        

  const [epsg, setEpsg] = useState(-1);   



  const [slds, setSlds] = useState([]);

  useEffect(() => {
    const url = env.GEOTABREST_SLDS_PERMITIDOS

    axios.get(url)
      .then(res => {
        console.log('Slds retornados: ' + res.data.slds);        
        setSlds( res.data.slds )        
      })
      .catch(error => {
        console.log('url: ' + url + ' |||||  Erro: ' + error)
      })
  }, []);        

  const [sld, setSld] = useState('application/vnd.ogc.se+xml'); 


 
  const [title, setTitle] = useState('');   

  const [keyword, setKeyword] = useState('');   

  const [keywordError, setKeywordError] = useState('');   

  const [keywords, setKeywords] = useState([]);   

  const [keywordErrors, setKeywordErrors] = useState('');   

  const [layerType, setLayerType] = useState(''); 

  const [layerName, setLayerName] = useState(''); 

  const [layerCategory, setLayerCategory] = useState(-1); // Não selecionado  

  const [idBase, setIdBase] = useState(-1); // Setado em função de layerCategoria

  const [submissionType, setSubmissionType] = useState(-1); 

  const [fileTransferComplete, setFileTransferComplete] = useState(false); 

  const [fileInsertOnGeoserverComplete, setFileInsertOnGeoserverComplete] = useState(false); 

  const [fileInsertOnGEOTABComplete, setFileInsertOnGEOTABComplete] = useState(false); 

  const [isInsertingLayer, setIsInsertingLayer] = useState(false);   



  function restoreInicialState() {
    setEpsg(-1)
    // setEpsgDesc('') // Deu not found aqui (percebido no debug... próximo ponto a investigar!!!)
    // setEpsgOrigin('h')
    // setEpsgFound(false)
    // setEpsgAllowed(false)
    setPercentUpload(0);    
    setValidFileNames(false)
    setLayerType('')
    setLayerName('')
    setTitle('')
    setKeywords([])
    setLayerCategory(-1)
    setIdBase(-1)
    setError([])
    setSubmissionType(-1)
    setFileTransferComplete(false)
    setFileInsertOnGeoserverComplete(false)
    setFileInsertOnGEOTABComplete(false)
    setIsInsertingLayer(false)
  }    

  function addError(e) {
    let newList = error

    let achou = false
    for (let i = 0; i < newList.length; i++)
      if (newList[i] == e)
        achou = true

    if (!achou)    
      newList.push(e)

    setError(newList)
  }


  const onDrop = useCallback(acceptedFiles => {
    

    setError([])

    let valid_file_name = true    

    // Verificando se todos os nomes de arquivo possuem apenas um ponto ('.')
    let fileName = undefined
    for (let i = 0; i <= acceptedFiles.length -1; i++){
      fileName = acceptedFiles[i].name

      if (lib.qtdSubstrOnStr(fileName, '.') > 1) {
        valid_file_name = false
        addError('Apenas um ponto é permitido no nome de cada arquivo')
      }      
    }

    // Verificando (quando existir mais de um arquivo) se todos possuem o mesmo nome, diferindo apenas pela extensão
    fileName = undefined
    for (let i = 0; i <= acceptedFiles.length -1; i++){
      
      if ( 
            (fileName != undefined) 
            && 
            (lib.fileNameWithoutExtension(fileName) != lib.fileNameWithoutExtension(acceptedFiles[i].name)) 
         ) {
          valid_file_name = false
          addError('Todos os arquivos devem possuir o mesmo nome, diferindo apenas pela extensão!')
         }

         fileName = acceptedFiles[i].name
    }
    


    // Verificando o TIPO DA SUBMISSÃO
    if(acceptedFiles.length == 1) {

      if ( lib.fileExtension(acceptedFiles[0].name).toUpperCase() == "ZIP" )
        setSubmissionType(2) 
      else {
        valid_file_name = false
        addError('Quando a submissão for de um único arquivo, este deve ser ZIP!')
      }

    }
    else { 

      if (acceptedFiles.length > 1 ) {

        // Verificando a existência do SHP
        // ---------------------------------------------------------------------
        let shp_extension_exists = false
        for (let i = 0; i <= acceptedFiles.length -1; i++)
          if ( lib.fileExtension( acceptedFiles[i].name ).toUpperCase() == "SHP" )
            shp_extension_exists = true
             
        if (!shp_extension_exists) {
          valid_file_name = false
          addError('Arquivo shp pendente!')
        }        
        else 
          setSubmissionType(1)
        // ---------------------------------------------------------------------



        // Garantindo que a extensão do shape esteja totalmente em lowercase (shp)
        // ---------------------------------------------------------------------
        let shp_extension_in_lowercase = false
        for (let i = 0; i <= acceptedFiles.length -1; i++)
          if ( lib.fileExtension( acceptedFiles[i].name ) == "shp" )
             shp_extension_in_lowercase = true
             
        if (!shp_extension_in_lowercase) {
          valid_file_name = false
          addError('A extensão do arquivo de projeção precisa ter todos os caracteres minúsculos: "shp"!')
        }        
        else 
          setSubmissionType(1)
        // ---------------------------------------------------------------------                



        // Verificando a existência do SLD
        // ---------------------------------------------------------------------
        let sld_extension_exists = false
        for (let i = 0; i <= acceptedFiles.length -1; i++)
          if ( lib.fileExtension( acceptedFiles[i].name ).toUpperCase() == "SLD" )
            sld_extension_exists = true
             
        if (!sld_extension_exists) {
          valid_file_name = false
          addError('Arquivo sld pendente!')
        }        
        else 
          setSubmissionType(1)
        // ---------------------------------------------------------------------       
        
        

        // Garantindo que a extensão do arquivo de legendas esteja totalmente em lowercase (sld)
        // ---------------------------------------------------------------------
        let sld_extension_in_lowercase = false
        for (let i = 0; i <= acceptedFiles.length -1; i++)
          if ( lib.fileExtension( acceptedFiles[i].name ) == "sld" )
             sld_extension_in_lowercase = true
             
        if (!sld_extension_in_lowercase) {
          valid_file_name = false
          addError('A extensão do arquivo de estilos precisa ter todos os caracteres minúsculos: "sld"!')
        }        
        else 
          setSubmissionType(1)
        // ---------------------------------------------------------------------        



        // Verificando a existência do PRJ
        // ---------------------------------------------------------------------
        let prj_extension_exists = false
        for (let i = 0; i <= acceptedFiles.length -1; i++)
          if ( lib.fileExtension( acceptedFiles[i].name ).toUpperCase() == "PRJ" )
            prj_extension_exists = true
             
        if (!prj_extension_exists) {
          valid_file_name = false
          addError('Arquivo prj pendente!')
        }        
        else 
          setSubmissionType(1)
        // ---------------------------------------------------------------------        


        // Garantindo que a extensão da projeção esteja totalmente em lowercase (prj)
        // ---------------------------------------------------------------------
        let prj_extension_in_lowercase = false
        for (let i = 0; i <= acceptedFiles.length -1; i++)
          if ( lib.fileExtension( acceptedFiles[i].name ) == "prj" )
             prj_extension_in_lowercase = true
             
        if (!prj_extension_in_lowercase) {
          valid_file_name = false
          addError('A extensão do arquivo de projeção precisa ter todos os caracteres minúsculos: "prj"!')
        }        
        else 
          setSubmissionType(1)
        // ---------------------------------------------------------------------        

      }
      else{
        valid_file_name = false
        addError('Nenhum arquivo submetido foi aceito!!!') 
      }
    }


    let candidateLayerName
    if ( acceptedFiles.length == 0 ) {      
      return // Saí do procedimento... e a mensagem  'O sistema só aceita arquivos dos tipos...' será dada por outro trecho de código!
    }
    else
      candidateLayerName = lib.fileNameWithoutExtension(acceptedFiles[0].name)


    if (!lib.StringPossuiApenasLetrasNumerosESublinhado(candidateLayerName)){
      valid_file_name = false
      addError('Nome da camada inválido: não são permitidos acentos, espaços nem caracteres diferentes de letras, números e underline!')
    }

    if (valid_file_name) { // Se já aconteceu algum erro, não verificar unicidade do nome da camada

      // candidateLayerName = lib.fileNameWithoutExtension(acceptedFiles[0].name)
      let candidateLayerNameExists = false
  

      let address = env.GEOTABREST_GET_CAMADA_EXISTE + '/' + candidateLayerName
      
  
      axios.get( address )
      .then(res => {
        candidateLayerNameExists = res.data.existe                   
        if (candidateLayerNameExists) {
          valid_file_name = false
          addError('O GEOTC já possui uma camada cadastrada com o nome "' + candidateLayerName + '"! Altere o nome dos seus arquivos!') 
        }
        else{ // AQUI TODO O UPLOAD DEVE SER FEITO!!!!


          setValidFileNames(valid_file_name)
          // Nome da camada ( a ser guardado na tabela gtb.camada )
          setLayerName(candidateLayerName)
      
          let formData = new FormData()
      
          acceptedFiles.forEach(file => 
            formData.append("files", file)
          )    
          
          let address = env.FILEUPLOADREST_WITHOUT_USER + '/' + loggedUser.id_usuario
          // alert(address)
      
          const req = request.post(address)
      
          req.on('progress', event => {
            let percent = Math.floor(event.percent);
            if (percent >= 100) {
                setPercentUpload(100)
            } else {
                setPercentUpload(percent)
            }
          })
      
          req.send(formData);
          req.end((err, res) => {
            console.log('Successfully uploaded');
            setFileTransferComplete(true)

            // Transferência completa aqui... 
               
          });             

        }
      })    
      .catch(function (error) {
        console.log(error);
        valid_file_name = false
        addError('Erro inserindo camada:' + error.message) 
      })       

    } 

  }, [])


  const {
    acceptedFiles, rejectedFiles, 
    getRootProps, getInputProps, 
    isDragActive, isDragAccept, isDragReject
  } = useDropzone({onDrop, accept: allowed_files});


  let acceptedFilesItems
  if (acceptedFiles == null)
    acceptedFilesItems = null
  else
    acceptedFilesItems = acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} ({lib.pretty_size(file.size)})
      </li>
    ));

  let rejectedFilesItems
  if (rejectedFiles == null)
    rejectedFilesItems = null
  else
    rejectedFilesItems = rejectedFiles.map(file => (
      <li key={file.path}>
      {file.path} ({lib.pretty_size(file.size)})
      </li>
    ));  

  function accepted(){
    if (acceptedFilesItems === null)
      return null
    else
      if ( (acceptedFilesItems.length === 0)   ||   ( !validFileNames ) )
        return null
      else
        return <div>
                 <div style={lib.UPLOADED_FILES_TITLE}>Arquivos aceitos:</div>
                 <ul style={lib.UPLOADED_FILES_DESC}>
                   {acceptedFilesItems}
                 </ul>
               </div>             
  }

  function rejected(){
    if (rejectedFilesItems === null)
      return null
    else
      if ( (rejectedFilesItems.length === 0)   ||   ( !validFileNames ) )
        return null
      else
        return <div>      
                 <div style={lib.REJECTED_FILES_TITLE}> Arquivos rejeitados: </div>
                 <ul style={lib.REJECTED_FILES_DESC}>
                   {rejectedFilesItems}
                 </ul>
               </div>
  }


  function messageRejected(){
    if (rejectedFilesItems === null)
      return null
    else
      if (rejectedFilesItems.length === 0)
        return null
      else
        return <table>
                 <tbody>
                   <tr>
                     <td style={lib.REJECTED_FILES_DESC}>
                       <FaInfoCircle size='25'/> O sistema só aceita arquivos dos tipos {allowed_files}   
                     </td>
                   </tr>
                 </tbody>
               </table>      
  }  

  
  function messageError(){        
    if ( error.length === 0 )
      return null
    else {
      const errors = error.map(  (e, i) => <tr key={i}><td style={lib.REJECTED_FILES_DESC}> <FaInfoCircle size='25'/>  {e} </td></tr>  );     
      return <table> <tbody> {errors} </tbody> </table>      
    }
      
  }  

  

  function ProgressUpload(){
    if ( (acceptedFilesItems.length === 0)   ||   ( !validFileNames ) )
      return null
    else    
      return  <table width='100%'>
                <tbody>
                  <tr>
                    <td align='center'>
                      <Line percent={percentUpload} strokeWidth='1' strokeColor={lib.EMBRAPA_AZUL_COLOR} strokeLinecap='square' />
                    </td>
                  </tr>
                  <tr>
                    <td style={lib.UPLOAD_PROGRESS_MESSAGE} align='center'>
                      Progresso: {percentUpload}%
                    </td>
                  </tr>  
                </tbody>              
              </table>      
  }  


  const debugTable = lib.debug( 
    {
       debug: false,
       keyword: keyword,
       keywords: keywords,
       layerType: layerType,
       layerName: layerName,
       title: title,
       validFileNames: validFileNames,
       layerCategory: layerCategory,
       idBase: idBase,
       submissionType: submissionType,
       fileTransferComplete: fileTransferComplete,
       fileInsertOnGeoserverComplete: fileInsertOnGeoserverComplete,
       fileInsertOnGEOTABComplete: fileInsertOnGEOTABComplete,
       insertingLayer: isInsertingLayer,
       epsg: epsg,
       sld: sld
     }
  )      

  
  function VerticalSpaceForTable(colSpan, height){
    return  <tr> <td colSpan={colSpan} height={height}> </td> </tr>
  }

  function addKeyWord(){
    if (keyword === '')
      alert('Insira uma palavra-chave!')
    else
    {
      setKeywords( [...keywords, keyword ] )
      setKeyword('')      
    }
  }


  function handleKeyWordEnter(event) {
    if (event.charCode === 13)
      addKeyWord()
  }


  function DrawBasicInfo(){
    
    return <table style={lib.DASHED_TABLE}>

             <tbody>
               <tr>
                 <td colSpan='3' style={lib.CRUD_TITLE_MENOR}>
                   <center>Passo 1: Informações básicas</center>
                 </td>               
               </tr>

               {VerticalSpaceForTable(3,15)}
  
               <tr>
                 <td colSpan='3'>
                   <table>
                     <tbody>
                       <tr>
    
                         <td>                         
                           <label>
                             <b> <Required/> Projeção: </b>
                             <select value={epsg} onChange={ (event) => {setEpsg(event.target.value)}  } >
                               <option value='-1'>{'-- Selecione uma opção'}</option>
                               {
                                epsgs.map((epsg) => <option key={epsg.id_epsg} value={epsg.id_epsg}>{'EPSG:' + epsg.id_epsg + ' - ' + epsg.tx_descricao}</option> )
                               }
                             </select>
                           </label>
                         </td>
  
                       </tr>
                     </tbody>
                   </table>

                 </td>
               </tr>

               <tr>

                 <td>
                   <div>
                     <label style={lib.CRUD_TEXT}>
                       <b> <Required/> Tipo:</b>
                     </label>
                   </div>  
                 </td>


                 <td>
                   <div>
                     <label style={lib.CRUD_TEXT}>
                       <input type="radio" value="1" 
                          checked={layerType === "1"} 
                          onChange={ (e) => setLayerType(e.currentTarget.value) } />
                       Pública
                     </label>
                   </div>  
                 </td>

                 <td>
                   <div>
                     <label style={lib.CRUD_TEXT}>
                       <input type="radio" value="2" 
                          checked={layerType === "2"} 
                          onChange={ (e) => setLayerType(e.currentTarget.value) } />
                       Privada
                     </label>
                   </div>                 
                 </td>

               </tr>


               <tr>

                 <td colSpan='3'>

                   <table >
                    <tbody>         
                      <tr height='20'>
                        <td>
                          <b><Required/> Título:</b>
                        </td>
                        <td>
                          <input style={{...lib.CRUD_TEXT, width: '150px'}} type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                 </td>

               </tr>





               <tr>

                 <td colSpan='3'>


                   <table>
                     <tbody>                                            
          
                       <tr height='20'>

                         <td>
                           <b><Required/> Palavra-chave:</b>
                         </td>

                         <td>        
                           <table>
                             <tbody>
                               <tr>
                                 <td style={{verticalAlign:'center'}} > 
                                   <input style={{...lib.CRUD_TEXT, width: '70px'}} type="text" value={keyword}  onKeyPress={ e => handleKeyWordEnter(e) }  onKeyUp={e => lib.validKeyUpAlert(e, keyword, setKeyword, 'onlyLetters')} onChange={e => setKeyword(e.target.value)} />
                                 </td>
                                 <td style={{verticalAlign:'center'}} > 
                                   <FaPlus onClick={ () => { addKeyWord() } } color={lib.EMBRAPA_VERDE_COLOR} />
                                 </td>
                                 <td style={{verticalAlign:'center'}} > 
                                   {KeywordsGrid()}
                                 </td>                               
                               </tr>
                             </tbody>
                           </table>                                      
                         </td>

                       </tr>


                     </tbody>
                   </table>

                 </td>

               </tr>






               <tr>
                 <td colSpan='3'>
                   <table>
                     <tbody>
                       <tr>
    
                         <td>                         
                           <label>
                             <b> <Required/> SLD Content-type: </b>
                             <select value={sld} onChange={ (event) => {setSld(event.target.value)}  } >
                               <option value='-1'>{'-- Selecione uma opção'}</option>
                               {
                                slds.map((sld, i) => <option key={i} value={sld.tx_content_type}>{sld.tx_content_type}</option> )
                               }
                             </select>
                           </label>
                         </td>
            
                       </tr>
                     </tbody>
                   </table>

                 </td>
               </tr>




             </tbody>

           </table>
  }  


  function KeywordsGrid(){

    if (keywords.length === 0)
      return null      

    const kws = keywords.map((keyword_par, i) => 
       <tr key={i}> 
         <td style={{fontSize:10, color: lib.EMBRAPA_AZUL_COLOR, verticalAlign:'center'}}> 
           {keyword_par} 
         </td> 
         <td style={{verticalAlign:'center'}} > 
           <FaMinus  onClick={ () => { setKeywords( keywords.filter( kw => kw !== keyword_par ) ); setKeyword('') } }  color={lib.VERMELHO_ERRO_COLOR} /> 
         </td> 
       </tr> 
    )

    return <table style={lib.MAP_TABLE_DASHED} >
             <tbody>
               {kws}
             </tbody>
           </table>
  }

 


  function DrawLayerCategory(){
    return <table style={lib.DASHED_TABLE}>
             <tbody>
               <tr>
                 <td style={lib.CRUD_TITLE_MENOR}>
                   <center> Passo 2: Categoria da Camada </center>
                 </td>               
               </tr>

               {VerticalSpaceForTable(3,15)}
  
               <tr>
                 <table>
                   <tbody>
                     <tr>
                       <td width='20'>
                         <Required/>                      
                       </td>
                       <td>
                         <TreeViewCategory layerCategory={layerCategory} setLayerCategory={setLayerCategory}  setIdBase={setIdBase} />
                      </td>
                     </tr>
                   </tbody>
                 </table>

               </tr>
             </tbody>
           </table>
  }    

  

  function DrawDropArea(){
    return <Container setFileTransferComplete={setFileTransferComplete} {...getRootProps({isDragActive, isDragAccept, isDragReject})}>

             <table>
               <tbody>
                 <tr>
                   <td>
                     <Required/>
                   </td>
                   <td>    
                   <input {...getInputProps()} />            
                            {               
                              isDragActive ?
                              <center> <p style={lib.CRUD_TITLE_MENOR}>Solte os arquivos aqui ...</p> </center> : 
                              <center> <p style={lib.CRUD_TITLE_MENOR}>Passo 3: Clique, ou arraste e solte os arquivos, aqui...</p> </center>
                            }                          
                        
                            <table>
                              <tbody>
                                <tr>
                                  <td valign='top'>
                                    {accepted()}
                                  </td>
                                  <td valign='top'>
                                    {rejected()}
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan='2' valign='bottom' align='center'>
                                    {messageRejected()}
                                  </td>
                                </tr>   
                                <tr>
                                  <td colSpan='2' valign='bottom' align='center'>
                                    {messageError()}
                                  </td>
                                </tr>  
                                
                              </tbody>
                            </table>      
               
                            {ProgressUpload()}                          
               
                            {/* {ProgressInsert()} */}
                                  
                   </td>    
                 </tr>
               </tbody>
             </table>
                          
           </Container>
  }

  const ESPACO = 7

  function invalidScreen(){
    return  (
            (epsg === -1)
            ||      
            layerType === '') 
            ||
            (title === '') 
            ||            
            (layerName === '') 
            ||
            (keywords.length === 0)  
            ||
            (layerCategory === -1)  
            ||
            (fileTransferComplete === false)
  }



  async function handleSubmit() {       


    async function handleLinkStyle(layer_name){

      const url = env.GEOTABREST_LINK_STYLE + '/' + layerName
          
      await axios.post(url)
      .then(res => {
        console.log(res)
        console.log(res.data)
        restoreInicialState() // ÚLTIMO PASSO
        // alert(res.data.stdout)
      })
      .catch(error => {
        console.log('url: ' + url + ' |||||  Erro: ' + error.response)
      })
  
    }
  
  
    async function handleAddStyle(id_usuario, layer_name, sld_content_type){
    
      const url = env.GEOTABREST_ADD_STYLE 
  
      const styleconfig = {
        id_usuario: id_usuario, 
        layer_name: layer_name, 
        sld_content_type: sld_content_type
      }
  
      await axios.post(url, styleconfig)
      .then(res => {
        console.log(res)
        console.log(res.data)
        // alert(res.data.stdout)
        handleLinkStyle(layer_name)
      })
      .catch(error => {
        console.log('url: ' + url + ' |||||  Erro: ' + error.response)
      })
  
    }
  
  
  

    async function updateLayer(){

      const url = env.GEOTABREST_UPDATE_LAYER_GEOTAB + '/' + layerName

      await axios.put(url)
        .then(res => {
          console.log(res);          
          handleAddStyle( loggedUser.id_usuario, layerName, sld)          
        })
        .catch(error => {
          console.log('url: ' + url + ' |||||  Erro: ' + error)
        })                
    }


    async function insertOnGEOTAB(){

      const url = env.GEOTABREST_INSERT_LAYER_GEOTAB

      let palavras_chave = ''
      for (let pos = 0; pos < keywords.length; pos ++)
        palavras_chave += keywords[pos] + '@||@' 

      palavras_chave = palavras_chave.substr(0, palavras_chave.length - 4) // Removendo separador do final
      

      const layer = {
        id_usuario: loggedUser.id_usuario,
        id_base_de_dados: idBase,
        id_tipo_camada: layerType,
        id_categoria_camada: layerCategory,
        id_epsg: epsg,
        tx_nome: layerName,
        tx_titulo: title,
        tx_palavras_chave: palavras_chave
    }

      await axios.post(url, layer)
        .then(res => {
          console.log(res);
          console.log(res.data);                                       
          setFileInsertOnGEOTABComplete(true)                
          // alert('Camada inserida no GEOTAB');         
          updateLayer()
        })
        .catch(error => {
          console.log('url: ' + url + ' |||||  Erro: ' + error.response)
        })                    

    }    


    async function insertOnGeoServer(){
      let address = env.GEOTABREST_INSERT_LAYER_GEOSERVER + '/' + loggedUser.id_usuario + '/' + idBase + '/' + submissionType + '/' + layerName

      await axios.post(address)
      .then(res => {
        console.log(res);
        console.log(res.data);                       
        setFileInsertOnGeoserverComplete(true)                       
        console.log('Camada inserida no GEOSERVER');
        // alert('Camada inserida no GeoServer');
        insertOnGEOTAB();
      })
      .catch(error => {
        console.log('url: ' + address + ' |||||  Erro: ' + error.message)
      })                        
    }    


    let arrayDatabasesComPermissaoParaIncluir = loggedUser.dbs_perm_inc.split('@')
    if ( !arrayDatabasesComPermissaoParaIncluir.includes( idBase.toString() ) )            
      axios.get(env.GEOTABREST_DATABASES_POR_PERFIL_E_ACAO + '/' + loggedUser.id_perfil + '/' + lib.ACAO_INCLUIR_CAMADAS)
      .then(res => {                      //  /databases__por_perfil_e_acao/:id_perfil/:id_acao    
        let msg = 'Seu usuário não possui permissão para incluir nesse database! Possui permissão para incluir no(s) seguinte(s) database(s): \r\n \r\n'
        
        const databases = res.data.data
        
        databases.forEach(database => {
          msg += ' - ' + database.base_desc + '\r\n'          
        });  

        msg += '\r\n Se um dos databases citados não aparece na tela, lhe deram permissão de inclusão, e esqueceram de dar permissão de visualização' 

        alert(msg)        
      })
      .catch(function (error) {
        console.log(error);
      })                   
    else { 
       // ----------- TRECHO DE CÓDIGO QUE REALIZA A INSERÇÃO ---- INÍCIO      
      setIsInsertingLayer(true)    

      await insertOnGeoServer() // ==> Quando concluir chama insertOnGEOTAB() ==> Quando concluir chama updateLayer() ==> Quando concluir chama handleAddStyle() ==> Quando concluir chama handleLinkStyle()
  
      setIsInsertingLayer(false)      
      // ----------- TRECHO DE CÓDIGO QUE REALIZA A INSERÇÃO ---- TÉRMINO
    }
    
  }
  

  let separador_vertical = <tr style={{height:'10px'}}> <td></td> </tr>

  return (
        
     <CrudTable title='INCLUIR CAMADA' showRequired='true'  debugTable={debugTable}>
        <tbody>
          <tr>
  
            <td>
  
              <table style={{height:'100%',width:'100%'}} >
                <tbody>
                  <tr>
                    <td align='center'>
                      {DrawBasicInfo()}
                    </td>
                  </tr>
                </tbody>
              </table>
  
  
            </td>
  
  
            <td>
  
              <table style={{height:'100%',width:'100%'}} >              
                <tbody>
                  <tr>
                    <td align='center'>
                      {DrawLayerCategory()}
                    </td>
                  </tr>
                </tbody>
              </table>
  
            </td>
  
            <td>
              
              <table style={{height:'100%',width:'100%'}} >              
                <tbody>
                  <tr>
                    <td align='center'>
                      {DrawDropArea()}                          
                    </td>
                  </tr>                                          
                </tbody>
              </table>
  
            </td>          
  
          </tr>
          
          <tr> <td colSpan='3' height={ESPACO}/> </tr>          
  
          <tr>
            <td colSpan='3' align='center'>
              {
                isInsertingLayer?  <Loader
                                      type="Oval"
                                      color={lib.EMBRAPA_VERDE_COLOR}
                                      height={100}
                                      width={100}        
                                   />
                                   : null                            
              }       
            </td>                                 
          </tr>
  
          <tr>
            <td colSpan='3' align='center'>
    
              <CrudBtVerde                   
                type="submit"
                disabled={invalidScreen()} 
                onClick={ () => handleSubmit() } > 
                    Cadastrar Camada {invalidScreen()? '(Conclua os passos acima)':''}
              </CrudBtVerde>
            
            </td>                                 
          </tr>
        </tbody>
      </CrudTable>      
  )
}

export default Upload